import { jsxs as b, jsx as h } from "react/jsx-runtime";
import { Avatar as p } from "./Avatars.js";
import { useEffect as q } from "react";
import { p as M, s as O, h as D, M as v, r as w, b as S } from "../../chunks/ChatbotContext.js";
import { useChatbotContext as L } from "./hooks/index.js";
import { Welcome as j } from "./Welcome.js";
const f = ["ariaDescribedBy", "ariaLabel", "ariaLabelledBy"], x = {
  ancestors: {
    tbody: ["table"],
    td: ["table"],
    th: ["table"],
    thead: ["table"],
    tfoot: ["table"],
    tr: ["table"]
  },
  attributes: {
    a: [
      ...f,
      // Note: these 3 are used by GFM footnotes, they do work on all links.
      "dataFootnoteBackref",
      "dataFootnoteRef",
      ["className", "data-footnote-backref"],
      "href"
    ],
    blockquote: ["cite"],
    // Note: this class is not normally allowed by GH, when manually writing
    // `code` as HTML in markdown, they adds it some other way.
    // We can’t do that, so we have to allow it.
    code: [["className", /^language-./]],
    del: ["cite"],
    div: ["itemScope", "itemType"],
    dl: [...f],
    // Note: this is used by GFM footnotes.
    h2: [["className", "sr-only"]],
    img: [...f, "longDesc", "src"],
    // Note: `input` is not normally allowed by GH, when manually writing
    // it in markdown, they add it from tasklists some other way.
    // We can’t do that, so we have to allow it.
    input: [
      ["disabled", !0],
      ["type", "checkbox"]
    ],
    ins: ["cite"],
    // Note: this class is not normally allowed by GH, when manually writing
    // `li` as HTML in markdown, they adds it some other way.
    // We can’t do that, so we have to allow it.
    li: [["className", "task-list-item"]],
    // Note: this class is not normally allowed by GH, when manually writing
    // `ol` as HTML in markdown, they adds it some other way.
    // We can’t do that, so we have to allow it.
    ol: [...f, ["className", "contains-task-list"]],
    q: ["cite"],
    section: ["dataFootnotes", ["className", "footnotes"]],
    source: ["srcSet"],
    summary: [...f],
    table: [...f],
    // Note: this class is not normally allowed by GH, when manually writing
    // `ol` as HTML in markdown, they adds it some other way.
    // We can’t do that, so we have to allow it.
    ul: [...f, ["className", "contains-task-list"]],
    "*": [
      "abbr",
      "accept",
      "acceptCharset",
      "accessKey",
      "action",
      "align",
      "alt",
      "axis",
      "border",
      "cellPadding",
      "cellSpacing",
      "char",
      "charOff",
      "charSet",
      "checked",
      "clear",
      "colSpan",
      "color",
      "cols",
      "compact",
      "coords",
      "dateTime",
      "dir",
      // Note: `disabled` is technically allowed on all elements by GH.
      // But it is useless on everything except `input`.
      // Because `input`s are normally not allowed, but we allow them for
      // checkboxes due to tasklists, we allow `disabled` only there.
      "encType",
      "frame",
      "hSpace",
      "headers",
      "height",
      "hrefLang",
      "htmlFor",
      "id",
      "isMap",
      "itemProp",
      "label",
      "lang",
      "maxLength",
      "media",
      "method",
      "multiple",
      "name",
      "noHref",
      "noShade",
      "noWrap",
      "open",
      "prompt",
      "readOnly",
      "rev",
      "rowSpan",
      "rows",
      "rules",
      "scope",
      "selected",
      "shape",
      "size",
      "span",
      "start",
      "summary",
      "tabIndex",
      "title",
      "useMap",
      "vAlign",
      "value",
      "width"
    ]
  },
  clobber: ["ariaDescribedBy", "ariaLabelledBy", "id", "name"],
  clobberPrefix: "user-content-",
  protocols: {
    cite: ["http", "https"],
    href: ["http", "https", "irc", "ircs", "mailto", "xmpp"],
    longDesc: ["http", "https"],
    src: ["http", "https"]
  },
  required: {
    input: { disabled: !0, type: "checkbox" }
  },
  strip: ["script"],
  tagNames: [
    "a",
    "b",
    "blockquote",
    "br",
    "code",
    "dd",
    "del",
    "details",
    "div",
    "dl",
    "dt",
    "em",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "hr",
    "i",
    "img",
    // Note: `input` is not normally allowed by GH, when manually writing
    // it in markdown, they add it from tasklists some other way.
    // We can’t do that, so we have to allow it.
    "input",
    "ins",
    "kbd",
    "li",
    "ol",
    "p",
    "picture",
    "pre",
    "q",
    "rp",
    "rt",
    "ruby",
    "s",
    "samp",
    "section",
    "source",
    "span",
    "strike",
    "strong",
    "sub",
    "summary",
    "sup",
    "table",
    "tbody",
    "td",
    "tfoot",
    "th",
    "thead",
    "tr",
    "tt",
    "ul",
    "var"
  ]
}, m = {}.hasOwnProperty;
function B(c, t) {
  let e = { type: "root", children: [] };
  const r = {
    schema: t ? { ...x, ...t } : x,
    stack: []
  }, n = P(r, c);
  return n && (Array.isArray(n) ? n.length === 1 ? e = n[0] : e.children = n : e = n), e;
}
function P(c, t) {
  if (t && typeof t == "object") {
    const e = (
      /** @type {Record<string, Readonly<unknown>>} */
      t
    );
    switch (typeof e.type == "string" ? e.type : "") {
      case "comment":
        return T(c, e);
      case "doctype":
        return U(c, e);
      case "element":
        return _(c, e);
      case "root":
        return $(c, e);
      case "text":
        return F(c, e);
    }
  }
}
function T(c, t) {
  if (c.schema.allowComments) {
    const e = typeof t.value == "string" ? t.value : "", r = e.indexOf("-->"), i = { type: "comment", value: r < 0 ? e : e.slice(0, r) };
    return g(i, t), i;
  }
}
function U(c, t) {
  if (c.schema.allowDoctypes) {
    const e = { type: "doctype" };
    return g(e, t), e;
  }
}
function _(c, t) {
  const e = typeof t.tagName == "string" ? t.tagName : "";
  c.stack.push(e);
  const r = (
    /** @type {Array<ElementContent>} */
    C(c, t.children)
  ), n = z(c, t.properties);
  c.stack.pop();
  let i = !1;
  if (e && e !== "*" && (!c.schema.tagNames || c.schema.tagNames.includes(e)) && (i = !0, c.schema.ancestors && m.call(c.schema.ancestors, e))) {
    const a = c.schema.ancestors[e];
    let u = -1;
    for (i = !1; ++u < a.length; )
      c.stack.includes(a[u]) && (i = !0);
  }
  if (!i)
    return c.schema.strip && !c.schema.strip.includes(e) ? r : void 0;
  const o = {
    type: "element",
    tagName: e,
    properties: n,
    children: r
  };
  return g(o, t), o;
}
function $(c, t) {
  const r = { type: "root", children: (
    /** @type {Array<RootContent>} */
    C(c, t.children)
  ) };
  return g(r, t), r;
}
function F(c, t) {
  const r = { type: "text", value: typeof t.value == "string" ? t.value : "" };
  return g(r, t), r;
}
function C(c, t) {
  const e = [];
  if (Array.isArray(t)) {
    const r = (
      /** @type {Array<Readonly<unknown>>} */
      t
    );
    let n = -1;
    for (; ++n < r.length; ) {
      const i = P(c, r[n]);
      i && (Array.isArray(i) ? e.push(...i) : e.push(i));
    }
  }
  return e;
}
function z(c, t) {
  const e = c.stack[c.stack.length - 1], r = c.schema.attributes, n = c.schema.required, i = r && m.call(r, e) ? r[e] : void 0, o = r && m.call(r, "*") ? r["*"] : void 0, a = (
    /** @type {Readonly<Record<string, Readonly<unknown>>>} */
    t && typeof t == "object" ? t : {}
  ), u = {};
  let l;
  for (l in a)
    if (m.call(a, l)) {
      const s = a[l];
      let d = k(
        c,
        N(i, l),
        l,
        s
      );
      d == null && (d = k(c, N(o, l), l, s)), d != null && (u[l] = d);
    }
  if (n && m.call(n, e)) {
    const s = n[e];
    for (l in s)
      m.call(s, l) && !m.call(u, l) && (u[l] = s[l]);
  }
  return u;
}
function k(c, t, e, r) {
  return t ? Array.isArray(r) ? H(c, t, e, r) : A(c, t, e, r) : void 0;
}
function H(c, t, e, r) {
  let n = -1;
  const i = [];
  for (; ++n < r.length; ) {
    const o = A(c, t, e, r[n]);
    (typeof o == "number" || typeof o == "string") && i.push(o);
  }
  return i;
}
function A(c, t, e, r) {
  if (!(typeof r != "boolean" && typeof r != "number" && typeof r != "string") && R(c, e, r)) {
    if (typeof t == "object" && t.length > 1) {
      let n = !1, i = 0;
      for (; ++i < t.length; ) {
        const o = t[i];
        if (o && typeof o == "object" && "flags" in o) {
          if (o.test(String(r))) {
            n = !0;
            break;
          }
        } else if (o === r) {
          n = !0;
          break;
        }
      }
      if (!n)
        return;
    }
    return c.schema.clobber && c.schema.clobberPrefix && c.schema.clobber.includes(e) ? c.schema.clobberPrefix + r : r;
  }
}
function R(c, t, e) {
  const r = c.schema.protocols && m.call(c.schema.protocols, t) ? c.schema.protocols[t] : void 0;
  if (!r || r.length === 0)
    return !0;
  const n = String(e), i = n.indexOf(":"), o = n.indexOf("?"), a = n.indexOf("#"), u = n.indexOf("/");
  if (i < 0 || // If the first colon is after a `?`, `#`, or `/`, it’s not a protocol.
  u > -1 && i > u || o > -1 && i > o || a > -1 && i > a)
    return !0;
  let l = -1;
  for (; ++l < r.length; ) {
    const s = r[l];
    if (i === s.length && n.slice(0, s.length) === s)
      return !0;
  }
  return !1;
}
function g(c, t) {
  const e = M(
    // @ts-expect-error: looks like a node.
    t
  );
  t.data && (c.data = O(t.data)), e && (c.position = e);
}
function N(c, t) {
  let e, r = -1;
  if (c)
    for (; ++r < c.length; ) {
      const n = c[r], i = typeof n == "string" ? n : n[0];
      if (i === t)
        return n;
      i === "data*" && (e = n);
    }
  if (t.length > 4 && t.slice(0, 4).toLowerCase() === "data")
    return e;
}
function V(c) {
  return function(t) {
    return (
      /** @type {Root} */
      B(t, c)
    );
  };
}
const Y = ({ render: c, personas: t, conversationStarters: e }) => {
  const { conversations: r, isProcessing: n, agent: i } = L();
  return q(() => {
    const o = document.querySelector(".chatbot-body");
    o == null || o.scrollTo(0, o.scrollHeight);
  }, [r.length]), /* @__PURE__ */ b("div", { className: "chatbot-body", children: [
    /* @__PURE__ */ b("div", { className: "chatbot-message-list", children: [
      r.map(
        (o, a) => {
          const u = o.role, l = (t == null ? void 0 : t.find((s) => {
            var d, y;
            return ((d = s.name) == null ? void 0 : d.toString().toUpperCase()) == ((y = o.agent) == null ? void 0 : y.toUpperCase());
          })) ?? (t == null ? void 0 : t.find((s) => s.role === u));
          return c ? c(o) : /* @__PURE__ */ h(E, { ...o, persona: l }, a);
        }
      ),
      n && /* @__PURE__ */ h(W, { persona: t == null ? void 0 : t.find((o) => {
        var a;
        return ((a = o.name) == null ? void 0 : a.toString().toUpperCase()) === (i == null ? void 0 : i.toUpperCase());
      }), message: "", role: "assistant" })
    ] }),
    r.length === 0 && /* @__PURE__ */ h(j, { persona: t == null ? void 0 : t.find((o) => o.role === "assistant"), starters: e })
  ] });
};
function E({ message: c, role: t, persona: e, id: r, timestamp: n, agent: i }) {
  const o = {
    name: i ?? (e == null ? void 0 : e.name) ?? t,
    role: (e == null ? void 0 : e.role) ?? t,
    avatar: e == null ? void 0 : e.avatar,
    tagline: e == null ? void 0 : e.tagline,
    color: e == null ? void 0 : e.color
  }, a = D.unix(n).fromNow();
  return /* @__PURE__ */ b("div", { id: r, className: `chatbot-message ${t}`, title: `Received ${a}`, children: [
    t === "assistant" && /* @__PURE__ */ h(p, { ...o }),
    /* @__PURE__ */ h(v, { className: `message ${t}`, remarkPlugins: [w], rehypePlugins: [S, V], children: c.toString() }),
    t === "user" && /* @__PURE__ */ h(p, { ...o })
  ] });
}
function W({ role: c, persona: t }) {
  const e = {
    name: (t == null ? void 0 : t.name) ?? c,
    role: (t == null ? void 0 : t.role) ?? c,
    avatar: t == null ? void 0 : t.avatar,
    tagline: t == null ? void 0 : t.tagline,
    color: t == null ? void 0 : t.color
  }, r = '<div className="loader">';
  return /* @__PURE__ */ b("div", { id: "streaming", className: `chatbot-message ${c}`, children: [
    c === "assistant" && /* @__PURE__ */ h(p, { ...e }),
    /* @__PURE__ */ h(v, { className: `message ${c}`, remarkPlugins: [w], rehypePlugins: [S], children: r })
  ] });
}
export {
  Y as default
};
