import { jsx as r } from "react/jsx-runtime";
import { Checkbox as i } from "@mui/material";
import "../../../chunks/Chatbot.style.js";
import "react";
import "../FormControl/FormControl.js";
import { FormControlLabel as e } from "../FormControlLabel/FormControlLabel.js";
import "../../../chunks/index.esm.js";
import "../../../chunks/server.browser.js";
import '../../../assets/map.css';/* empty css                   */import "../Grid/Grid.js";
import "../Paper/Paper.js";
import "../Box/Box.js";
import "../Radio/Radio.js";
import "../RadioGroup/RadioGroup.js";
import "../Typography/Typography.js";
import "../Stepper/Stepper.js";
import "../Step/Step.js";
import "../StepLabel/StepLabel.js";
import "../../../chunks/style.module.js";
import "../../../chunks/DateTimeSelectorMenu.js";
import "moment-timezone";
import "../../../chunks/runtime.js";
import "../../../chunks/TableContext.js";
import "../../ReactTable/SearchFilter.js";
import "../../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../../chunks/transform.js";
import "../../molecules/NetworkMap/NetworkMapUtils.js";
import "../../organisms/RadarGraph/RadarGraph.js";
import "../../organisms/ScoreGauge/ScoreGauge.js";
import "../../organisms/SunburstGraph/SunburstGraph.js";
import "../../../chunks/ChatbotContext.js";
import "../../ReactMap/index.js";
import "../../ReactMap/GMapStatic.js";
function O({ label: m, sx: o, ...p }) {
  return /* @__PURE__ */ r(
    e,
    {
      control: /* @__PURE__ */ r(
        i,
        {
          sx: [...Array.isArray(o) ? o : [o], t.checkboxStyles],
          ...p
        }
      ),
      label: m,
      sx: t.labelStyles
    }
  );
}
const t = {
  checkboxStyles: {
    p: 0
  },
  labelStyles: {
    gap: "6px",
    marginRight: "0px"
  }
};
export {
  O as Checkbox
};
