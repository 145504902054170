import { jsx as i, jsxs as e } from "react/jsx-runtime";
import { FormControl as A, Select as S, MenuItem as s } from "@mui/material";
import { Grid as B } from "../Grid/Grid.js";
import { Typography as h } from "../Typography/Typography.js";
import { K as C } from "../../../chunks/KeyboardArrowDown.js";
import { styles as x } from "./Select.styles.js";
import "../../../chunks/Chatbot.style.js";
import "react";
import { Checkbox as E } from "../Checkbox/Checkbox.js";
import { Divider as j } from "../Divider/Divider.js";
import "../FormControl/FormControl.js";
import "../FormControlLabel/FormControlLabel.js";
import "../../../chunks/index.esm.js";
import "../../../chunks/server.browser.js";
import '../../../assets/map.css';/* empty css                   */import "../Paper/Paper.js";
import { Box as k } from "../Box/Box.js";
import "../Radio/Radio.js";
import "../RadioGroup/RadioGroup.js";
import "../Stepper/Stepper.js";
import "../Step/Step.js";
import "../StepLabel/StepLabel.js";
import "../../../chunks/style.module.js";
import "../../../chunks/DateTimeSelectorMenu.js";
import "moment-timezone";
import "../../../chunks/runtime.js";
import "../../../chunks/TableContext.js";
import "../../ReactTable/SearchFilter.js";
import "../../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../../chunks/transform.js";
import "../../molecules/NetworkMap/NetworkMapUtils.js";
import "../../organisms/RadarGraph/RadarGraph.js";
import "../../organisms/ScoreGauge/ScoreGauge.js";
import "../../organisms/SunburstGraph/SunburstGraph.js";
import "../../../chunks/ChatbotContext.js";
import "../../ReactMap/index.js";
import "../../ReactMap/GMapStatic.js";
function sr({
  id: a,
  DropdownIcon: l,
  options: p,
  placeholder: y,
  value: t,
  variant: b,
  sx: n,
  customFooter: d,
  emptyStateText: u = "No options available",
  ...g
}) {
  return /* @__PURE__ */ i(A, { variant: b, hiddenLabel: !0, children: /* @__PURE__ */ e(
    S,
    {
      id: a,
      labelId: `${a}-label`,
      displayEmpty: !0,
      IconComponent: (r) => l ? /* @__PURE__ */ i(l, { ...r }) : /* @__PURE__ */ i(C, { ...r }),
      sx: [x.common, ...Array.isArray(n) ? n : [n]],
      renderValue: (r) => {
        const c = Array.isArray(r);
        if (!r || c && r.length === 0)
          return y;
        if (c)
          return p.filter((m) => r.includes(m.value)).map((m) => m == null ? void 0 : m.label).join(", ");
        const o = p.find((f) => f.value === r);
        return /* @__PURE__ */ e(B, { container: !0, sx: x.selectedContainer, children: [
          o == null ? void 0 : o.icon,
          o == null ? void 0 : o.label
        ] });
      },
      value: t,
      ...g,
      children: [
        p.length === 0 ? /* @__PURE__ */ i(s, { disabled: !0, sx: { opacity: "1 !important" }, children: /* @__PURE__ */ i(h, { sx: { fontSize: "12px", fontWeight: 400, color: "#878787" }, children: u }) }) : p.map((r) => /* @__PURE__ */ e(
          s,
          {
            value: r.value,
            sx: { display: "flex", gap: 1 },
            disabled: r.disabled,
            children: [
              Array.isArray(t) && /* @__PURE__ */ i(E, { checked: t == null ? void 0 : t.includes(r.value) }),
              r.icon,
              /* @__PURE__ */ i(h, { sx: { fontSize: "12px", fontWeight: 400 }, children: r.label })
            ]
          },
          r.id
        )),
        d && /* @__PURE__ */ e(k, { children: [
          /* @__PURE__ */ i(j, { sx: { marginTop: "8px", marginBottom: "8px", borderColor: "#EBEBEB" } }),
          d
        ] })
      ]
    }
  ) });
}
export {
  sr as Select
};
