/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { authenticationService } from "./authentication-service";
import { System } from "../types/systemSetting";
import APIService from "./APIServices";
import { Service } from "~/types/Service";

const serviceAPI = new APIService<Service>("services");

export const systemService = {
  getSystemSettingsData,
  updateSystemSettingsData,
  getServices: serviceAPI.getAll,
};


type UpdateSystemSettingsData = {
  systemSettings: {
    name: string;
    value: any;
}[];
}

function updateSystemSettingsData(systemSettingsData : UpdateSystemSettingsData) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue!.token,
    },
    body: JSON.stringify(systemSettingsData),
  };

  console.log("Updating system setting");
  return fetch(`/api/v1/orch/system/settings/operations/update`, requestOptions);
}

// <NOT TO BE REFACTORED> This is used for fetching system settings data for the login page 
async function getSystemSettingsData() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue?.token,
    },
  };
  try {
    const response = await fetch(`/api/v1/orch/system/settings`, requestOptions);
    const text = await response.text();
    const data = text && JSON.parse(text).data;
    return data as System[];
  } catch (error) {
    console.log(error);
    throw error;
  }
}