/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { AlertProfile } from "~/types/AlertProfile";
import { alertService } from "~/services/alert-service";

type initState = {
  open: boolean;
  current: AlertProfile | null;
  data: AlertProfile[];
};

const initialState: initState = {
  open: false,
  current: null,
  data: [],
};

export const fetchAlertProfiles = createAsyncThunk("alertProfile/fetchAlertProfiles", async () => {
  const data = await alertService.getAlertProfiles();
  return data;
});
const alertProfileSlice = createSlice({
  name: "alertProfile",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setValues: (state, action: PayloadAction<AlertProfile | null>) => {
      state.current = action.payload;
    },
    updateData: (state, action: PayloadAction<AlertProfile>) => {
      const index = state.data.findIndex((item) => item.id === action.payload.id);
      state.data[index] = action.payload;
    },
    delete: (state, action: PayloadAction<string>) => {
      const index = state.data.findIndex((item) => item.id === action.payload);
      state.data = state.data.filter((_, i) => i !== index);
    },
    setData: (state, action: PayloadAction<AlertProfile[]>) => {
      state.data = action.payload;

      const current = action.payload.find((item) => item.id === state.current?.id);
      if (current) {
        state.current = current;
      }
    },
    addData: (state, action: PayloadAction<AlertProfile>) => {
      state.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAlertProfiles.fulfilled, (state, action) => {
      state.data = action.payload;

      // Sync current with latest data
      if (state.current && !state.open) {
        const current = action.payload.find((item) => item.id === state.current?.id);
        if (current) {
          state.current = current;
        }
      }
    });
  },
});

export const alertProfileActions = alertProfileSlice.actions;
export default alertProfileSlice.reducer;
export const alertProfileState = (state: RootState) => state.alertProfile.current;
export const alertProfileData = (state: RootState) => state.alertProfile.data;
export const alertProfileOpen = (state: RootState) => state.alertProfile.open;
