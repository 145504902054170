/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
//  @fileoverview Constants used in the application

import { Icons } from "~/components/GraphicalResources";

export const MAP_KEY = import.meta.env.H9_GOOGLE_MAP_API_KEY;

export enum IPSEC_MODE {
  PSK = "PSK",
  CERTIFICATE = "CERTIFICATE",
  DISABLED = "DISABLED",
}

export enum EDGE_TYPE {
  CARRIER_GATEWAY = "CARRIER_GATEWAY",
  PRIVATE_CELLULAR = "PRIVATE_CELLULAR",
}

export enum LOCATION_SYNC_MODE {
  MANUAL = "MANUAL",
  GNSS = "GNSS",
  PTP = "PTP",
}
export enum LOCATION_TYPE {
  GCP = "GCP",
  AWS = "AWS",
  AZURE = "AZURE",
  PRIVATE_CLOUD = "PRIVATE CLOUD",
}
export enum OBJ_STATUS {
  MARKED_FOR_DISCONNECT="MARKED_FOR_DISCONNECT",
  USED = "USED",
  RESET = "RESET",
  MARKED_FOR_DISABLE ="MARKED_FOR_DISABLE",
  DISABLED = "DISABLED",
  NOT_DEPLOYED = "NOT_DEPLOYED",
  DEPLOYING = "DEPLOYING",
  DEPLOYED = "DEPLOYED",
  FAILED = "FAILED",
  INACTIVE = "InActive",
  DELETING = "DELETING",
  UPDATING = "UPDATING",
  UPGRADING = "UPGRADING",
  REBOOTING = "REBOOTING",
  RESETTING = "RESETTING",
  AUTO_REGISTERING = "AUTO_REGISTERING",
  AUTO_CONFIGURING = "AUTO_CONFIGURING",
  CONFIGURING = "CONFIGURING",
  EXPIRED ="EXPIRED",
  REGISTERED = "REGISTERED",
  UPDATED = "UPDATED",
  AKNOWLEDGED = "AKNOWLEDGED",
  REJECTED = "REJECTED",

  MARKED_FOR_AUTO_CONFIG = "MARKED_FOR_AUTO_CONFIG",
  MARKED_FOR_UPDATE = "MARKED_FOR_UPDATE",
  MARKED_FOR_DELETE = "MARKED_FOR_DELETE",
  MARKED_FOR_UPGRADE = "MARKED_FOR_UPGRADE",
  IN_SERVICE = "IN_SERVICE",
  OUT_OF_SERVICE = "OUT_OF_SERVICE",

  //diagnostic specific states
  DEPLOYING_VM = "DEPLOYING_VM",
  DEPLOYED_VM = "DEPLOYED_VM",
  DELETING_VM = "DELETING_VM",


  // radio specific states
  CLEAR_SERIAL_NUMBER = "CLEAR_SERIAL_NUMBER",
  MARKED_FOR_REBOOT = "MARKED_FOR_REBOOT",
  WAITING = "WAITING",
  PROCESSING_WAITING = "PROCESSING_WAITING",
  REFRESH = "refresh",

  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  ASSIGNED = "ASSIGNED",
  UNASSIGNED = "UNASSIGNED",
  ASSIGNING = "ASSIGNING",
  UNASSIGNING = "UNASSIGNING",
  MARK_ASSIGNED = "MARK_ASSIGNED",
  MARK_UNASSIGNED = "MARK_UNASSIGNED",
  REVOKING = "REVOKING",
  RENEWING = "RENEWING",
  CREATING = "CREATING",
  // operator specific states
  WAIT_NOT_STARTED = "WAIT_NOT_STARTED",
  WAITING_IN_PROGRESS = "WAITING_IN_PROGRESS",
  FETCHING_RESULTS = "FETCHING_RESULTS",
  CANCELLED = "CANCELLED",
}

export enum UE_STATUS {
  UE_CONNECT = "UE_CONNECT",
  UE_DISCONNECT = "UE_DISCONNECT",
  UE_CONNECTED = "UE_CONNECTED",
  UE_DISCONNECTED = "UE_DISCONNECTED",
  UE_RUN_COMMAND = "UE_RUN_COMMAND",
  UE_CONNECTING = "UE_CONNECTING",
  UE_DISCONNECTING = "UE_DISCONNECTING",
  UE_RUNNING_COMMAND = "UE_RUNNING_COMMAND",
  UE_NOT_DEPLOYED = "UE_NOT_DEPLOYED",
  UE_DELETE = "UE_DELETE",
  UE_DELETING = "UE_DELETING",
}

export enum VIEW {
  DEFAULT_VIEW = "defaultView",
  TOP_N_VIEW = "topnView",
}

export enum CARRIER_MODE {
  SINGLE_CARRIER = "SINGLE_CARRIER",
  DUAL_CARRIER = "DUAL_CARRIER",
  CARRIER_AGGREGATION = "CARRIER_AGGREGATION",
}

export enum CPI_TYPE {
  THIRD_PARTY = "Thirdparty",
  OPERATOR = "Operator",
}

export enum FREQUENCY_SELECTION_LOGIC {
  PBF = "Power,Bandwidth,Frequency",
  PFB = "Power,Frequency,Bandwidth",
  BPF = "Bandwidth,Power,Frequency",
  BFP = "Bandwidth,Frequency,Power",
  FBP = "Frequency,Bandwidth,Power",
  FPB = "Frequency,Power,Bandwidth",
}

export enum COLOR {
  GREEN = "GREEN",
  YELLOW = "YELLOW",
  RED = "RED",
  ORANGE = "ORANGE",
  BLUE = "BLUE",
  GREY = "GREY",
  UNKNOWN = "UNKNOWN",
}
export const UNKNOWN = "UNKNOWN";

export const GOOD = "GOOD";
export const BAD = "BAD";
export const NA = "NA";
export const PARTIALLY_GOOD = "PARTIALLY_GOOD";
export const IDLE = "IDLE";
export const DEFAULT = "DEFAULT";
export const DISCONNECTED = "DISCONNECTED";
export const CONNECTED = "CONNECTED";
export const ACTIVE = "Active";
export const INACTIVE = "InActive";
export const NONE = "none";
export const OUTDOOR = "OUTDOOR";
export const INDOOR = "INDOOR";
export const AIRSPAN = "AIRSPAN";
export const HIGHWAY9 = "HIGHWAY9";
export const BAICELLS = "BAICELLS";
export const TOPKPI = "Top";
export const REVOKE_FAILED="REVOKE_FAILED";
export const RENEW_FAILED="RENEW_FAILED";
export const CERT_REQUEST_FAILED="CERT_REQUEST_FAILED";
export const DEFAULT_LOCATION = {
  latitude: 37.38732426213586,
  longitude: -121.96689239999999,
  name: "2350 Mission College Blvd #490, Santa Clara, CA 95054, USA"
};
export const GraphColors = ["#026950", "#AA4AAC", "#01BAEF", "#1AFFD5", "#2C8A55", "#7579f5", "#A8DC39", "#3DC7C0", "#CD44E3", "#00A9F2"];

export const ZoneColors = ["#2C8A55", "#7579f5", "#A8DC39", "#3DC7C0", "#CD44E3", "#00A9F2"];
export const NETWORK_EXTENSION = "NETWORK_EXTENSION";
export const GATEWAY = "GATEWAY";
export const GLOBAL_TCP_MSS = 1200;

export interface powerObj {
  lower: number;
  upper: number;
}
export const POWER_RANGE = {
  Indoor: { lower: 17, upper: 24 },
  Flex: { lower: 17, upper: 24 },
  Outdoor: { lower: 24, upper: 30 },
  Outdoor_5G: { lower: 0, upper: 37 },
};
export const AIRSPAN_POWER_RANGE = {
  Indoor: { lower: -9, upper: 26 },
  Flex: { lower: 0, upper: 37 },
  Outdoor: { lower: 0, upper: 37 },
};
export const DEFAULT_POWER_RANGE: powerObj = { lower: 17, upper: 30 };
// these values must be match with model of each radio/hardware
export const HARDWARE_MODEL_TYPES = {
  Indoor: "Indoor",
  Flex: "Flex",
  Outdoor: "Outdoor",
};

export const KPIGroupsIcons = ["metrics","availability","accessibility","retainibility","integrity",'utilization','mobility']
export const KPIChartTypes = [{name :'line',icon : Icons.templateLineChart}]

export enum WHITELIST_STATE {
    ADDED = "ADDED",
    DELETED = "DELETED",
    UPDATED = "UPDATED",
    NONE = "NONE"
}

export const MAP_MARKER_COLOR = "#7579F5";
export const STATUS_RED_COLOR = "#D32F2F";
export const STATUS_GREEN_COLOR = "#277A52";
export const STATUS_ORANGE_COLOR = "#ff9800";
export const STATUS_NA_COLOR = "#C5C8CE"

export const MAP_MARKERS_PROXIMITY_DELTA = 25; // markers that have distance less than 25 meters should be coalesced
export const HIGHWAY9_5G = "HIGHWAY9_5G"