/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "..";
import { mergeObjectsByEndTime } from "~/helpers/utils";
import { eventServices, subscriberService } from "~/services";
import { EventSummary, EventsSummaryQuery } from "~/types/event";
import { updateURL } from '~/helpers/history';
import { MetricsQuery } from "~/services/APIServices";
import { fillTimeSeriesData } from "~/views/subscribers/graphs/graphHelper";
import { Label } from "~/types/metrics";
import { metricHelper } from "~/helpers/metricHelper";
import { AppInsightsMetrics, AppInsightsMetricsMap, AppInsightsMetricsSumMap } from "~/views/ITDashboard/AppInsights/InsightsMetricList";
interface timelineData {
  timestamp: string;
  bytes: number;
  rate: number;
  event?: number;
}

interface SelectedPanelProps {
  type: string;
  nodeID: string;
}

export enum TabTypes {
  networkMap = 'networkMap',
  events = 'events',
  insights = 'insights',
  appInsights = 'appInsights',
}

type initState = {
  timelineData: timelineData[];
  selectedPanel: SelectedPanelProps;
  leafNodeID: string;
  selectedTimestamp: number;
  selectedEventTimestamp: number;
  loading: boolean;
  eventData: EventSummary[];
  vendorType: string;
  activeTab: TabTypes;
  lastUpdatedAt: number;
  appInsights: {
    data: Record<string, {
      key: string;
      labels: Label[];
      data: [number, any][];
    }[]>;
    loading: boolean;
    sumData: Record<string, {
      key: string;
      labels: Label[];
      data: any;
    }[]>;
  }
};



export const initialState: initState = {
  timelineData: [],
  selectedPanel: {
    type: "",
    nodeID: "",
  },
  leafNodeID: "",
  selectedTimestamp: 0,
  selectedEventTimestamp: 0,
  loading: true,
  eventData: [],
  vendorType: "",
  activeTab: TabTypes.networkMap,
  lastUpdatedAt: 0,
  appInsights: {
    sumData : {},
    data: {},
    loading: true,
  }
};

export const fetchEvents = createAsyncThunk(
  "itDashboard/fetchEvents",
  async ({ startTime, endTime, interval }: { startTime: number; endTime: number; interval: number }) => {
    const payload: EventsSummaryQuery = {
      category: "FAILURE",
      objectType: "ALL",
      interval: {
        startTime,
        endTime,
      },
      aggregationInterval: Math.round(interval),
    }
    const response = await eventServices.getEventSummary(payload);
    const mergedData = mergeObjectsByEndTime(response);
    return mergedData;
  }
);

export const fetchAppInsights = createAsyncThunk(
  "itDashboard/fetchAppInsights",
  async (tab : string, thunk) => {
    const state = thunk.getState() as RootState;
    const { startTime, endTime } = state.utility.time;
    // round the resolution to the nearest 60 seconds
    let resolution = (Math.max(metricHelper.getResolution(startTime, endTime, 200), 60))
    resolution = Math.round(resolution / 60) * 60;
    // We need to create a list of queries for each metric where labels are different for each metric
    // For example, for the first metric, the labels are [ 'application', 'application_category' ]
    // For the second metric, the labels are [ 'application', 'application_list' ]
    // For the third metric, the labels are [ 'application', 'application_category' ]
    // the the first and third metric have the same labels, so we can combine them into one query with the same labels and 2nd metric will be a separate query with different labels 
   
    const createQuery = (metric : AppInsightsMetrics) => ({
      metrics: metric.metricKeys instanceof Array ? metric.metricKeys : [metric.metricKeys],
      interval: { startTime, endTime },
      labels: metric.labels,
      resolution: resolution,
      ids: ["ALL"],
    } satisfies MetricsQuery);

    const queries = (AppInsightsMetricsMap[tab]).reduce((acc, metric, i) => {
      // If it's the first metric or the labels are different from the previous metric, create a new query
      if (i === 0 || metric.labels.join() !== acc[acc.length - 1].labels?.join()) {
        acc.push(createQuery(metric));
      } else {
        // If the labels are the same as the previous metric, add the metric to the previous query
        const metricKey = metric.metricKeys instanceof Array ? metric.metricKeys : [metric.metricKeys];
        
        // Use a Set to avoid duplicate metric keys
        const uniqueMetrics = new Set(acc[acc.length - 1].metrics);
        metricKey.forEach(key => uniqueMetrics.add(key));
        
        // Convert the Set back to an array
        acc[acc.length - 1].metrics = Array.from(uniqueMetrics);
      }
    
      return acc;
    }, [] as MetricsQuery[]);

    const promises = queries.map((query) => subscriberService.getMetrics(query));

    const response = (await Promise.all(promises)).flat();
    return {
      data: response,
      interval: { startTime, endTime },
      resolution
    }
  }
);

export const fetchAppInsightsSum = createAsyncThunk(
  "itDashboard/fetchAppInsightsSum", async (tab : string, thunk) => {
    const state = thunk.getState() as RootState;
    const { startTime, endTime } = state.utility.time;
    const resolution = endTime - startTime;
    // We need to create a list of queries for each metric where labels are different for each metric
    // For example, for the first metric, the labels are [ 'application', 'application_category' ]
    // For the second metric, the labels are [ 'application', 'application_list' ]
    // For the third metric, the labels are [ 'application', 'application_category' ]
    // the the first and third metric have the same labels, so we can combine them into one query with the same labels and 2nd metric will be a separate query with different labels 
    const createQuery = (metric : AppInsightsMetrics) => ({
      metrics: metric.metricKeys instanceof Array ? metric.metricKeys : [metric.metricKeys],
      interval: { startTime, endTime },
      labels: metric.labels,
      resolution: resolution,
      ids: ["ALL"],
    } satisfies MetricsQuery);

    const queries = AppInsightsMetricsSumMap[tab].reduce((acc, metric, i) => {
      // If it's the first metric or the labels are different from the previous metric, create a new query
      if (i === 0 || metric.labels.join() !== acc[acc.length - 1].labels?.join()) {
        acc.push(createQuery(metric));
      } else {
        // If the labels are the same as the previous metric, add the metric to the previous query
        const metricKey = metric.metricKeys instanceof Array ? metric.metricKeys : [metric.metricKeys];
        acc[acc.length - 1].metrics.push(...metricKey);
      }
      return acc;
    }, [] as MetricsQuery[]);
    const promises = queries.map((query) => subscriberService.getMetrics(query));
    const response = (await Promise.all(promises)).flat();
    return {
      data: response,
      interval: { startTime, endTime },
      resolution
    }
  }
);

const itDashboardSlice = createSlice({
  name: "itDashboard",
  initialState,
  reducers: {
    setTimelineData: (state, action) => {
      state.timelineData = action.payload;
    },
    setSelectedPanel: (state, action) => {
      state.selectedPanel = action.payload;
    },
    setLeafNodeID: (state, action) => {
      state.leafNodeID = action.payload;
    },
    setSelectedTimestamp: (state, action) => {
      state.selectedTimestamp = action.payload;
      updateURL({ timestamp: action.payload });
    },
    setSelectedEventTimestamp: (state, action) => {
      state.selectedEventTimestamp = action.payload;
    },
    setVendorType: (state, action) => {
      state.vendorType = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
      updateURL({ tab: action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.eventData = action.payload;
        state.loading = false;
        state.lastUpdatedAt = Date.now();
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.loading = false;
        state.eventData = [];
        state.lastUpdatedAt = Date.now();
        console.error(action.error);
      })

      .addCase(fetchAppInsights.pending, (state) => {
        state.appInsights.loading = true;
      })
      .addCase(fetchAppInsights.fulfilled, (state, action) => {
        const { data, interval, resolution } = action.payload;

        data.forEach((d) => {
          const key = d.metric;
          const _data = d.metricData ?? [];
          const xData = _data.map((metric) => {
            const { dataPoints, labels } = metric;
            const tempKey = key + "#" + labels?.map((label) => `${label.name}:${label.value}`).join("&");

            if (dataPoints?.length === 0) {
              return {
                key: tempKey,
                labels,
                data: [],
              }
            }
            try {
              const updatedData = fillTimeSeriesData({
                data: dataPoints,
                interval: resolution,
                startTime: interval.startTime,
                endTime: interval.endTime,
                fillType: "none",
                timeRoundingToNearest: "minutes",
                name: key,
              })
              return {
                key: tempKey,
                labels,
                data: updatedData,
              }
            }
            catch (e) {
              console.error("Error in fillTimeSeries", e, key, metric.dataPoints);
              return {
                key: tempKey,
                labels,
                data: metric.dataPoints.length ? metric.dataPoints : []
              }
            }
          })

          state.appInsights.data[key] = xData;
        });

        state.appInsights.loading = false;
      })
      .addCase(fetchAppInsights.rejected, (state, action) => {
        state.appInsights.loading = false;
        console.error(action.error);
      })

      .addCase(fetchAppInsightsSum.pending, (state) => {
        state.appInsights.loading = true;
      })
      .addCase(fetchAppInsightsSum.fulfilled, (state, action) => {
        const { data } = action.payload;

        data.forEach((d) => {
          const key = d.metric;
          const _data = d.metricData ?? [];
          const xData = _data.map((metric) => {
            const { dataPoints, labels } = metric;
            let tempKey = key;
            if(labels?.length > 0) tempKey += "#" + labels?.map((label) => `${label.name}:${label.value}`).join("&");

           return {
              key: tempKey,
              labels,
              data: dataPoints[dataPoints.length - 1][1],
            }
          })
          state.appInsights.sumData[key] = xData;
        });
      })
  },
});

export const itDashboardAction = itDashboardSlice.actions;
export default itDashboardSlice.reducer;

export const timelineData = (state: RootState) => state.itDashboard.timelineData;
export const selectedPanel = (state: RootState) => state.itDashboard.selectedPanel;
export const selectedLeafNodeID = (state: RootState) => state.itDashboard.leafNodeID;
export const selectedTimestamp = (state: RootState) => state.itDashboard.selectedTimestamp;
export const selectedEventTimestamp = (state: RootState) => state.itDashboard.selectedEventTimestamp;
export const eventData = (state: RootState) => state.itDashboard.eventData;
export const loading = (state: RootState) => state.itDashboard.loading;
export const vendorType = (state: RootState) => state.itDashboard.vendorType;
export const activeTab = (state: RootState) => state.itDashboard.activeTab;
export const lastUpdatedAt = (state: RootState) => state.itDashboard.lastUpdatedAt;


// type DataT = {
//   startTime: number;
//   endTime: number;
//   interval: number;
//   range : [number, number];
// }

// function generateData({startTime, endTime, interval, range}: DataT) {
//   const data: [number, number][] = [];

//   // Generate random data for smooth curve
//   let prevValue = Math.random() * (range[1] - range[0]) + range[0];
//   for (let i = startTime; i <= endTime; i += interval) {
//     const value = prevValue + (Math.random() - 0.2) * 10;
//     data.push([i * 1000, Number(value.toFixed(1))]);
//     prevValue = value;
//   }

  
  
//   return data;
// }