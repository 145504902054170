import { jsx as e, jsxs as c } from "react/jsx-runtime";
import d from "./ChatBotHeader.js";
import "../../chunks/Chatbot.style.js";
import g from "./ChatBotFooter.js";
import u from "./ChatBotContainer.js";
import { C as f } from "../../chunks/ChatbotContext.js";
const s = "Virtual Engineer", z = ({
  header: r = { title: s },
  footer: a,
  initialMessages: n,
  personaOptions: l = [
    { name: s, role: "assistant", tagline: "Highway 9 Virtual Engineer" },
    { name: "User", role: "user" },
    { name: "Randle", role: "assistant", color: "#ff3200" },
    { name: "Caroline", role: "assistant", color: "#5D0EAC" }
  ],
  inputOptions: o = { prompt: "Type a message..." },
  events: m,
  messageOptions: h,
  storageKey: i = "chatHistory",
  adapter: C,
  state: t = {
    maximize: !0,
    onClose: () => {
      alert("Close");
    },
    onSizeChange: () => {
      alert("Size Change");
    }
  }
}) => {
  const p = n || JSON.parse(sessionStorage.getItem(i ?? "") || "[]");
  return /* @__PURE__ */ e(f, { state: { ...t, ...o }, events: m, storageKey: i, initialMessages: p, adapter: C, children: /* @__PURE__ */ c("div", { className: "chatbot", children: [
    !r.hide && /* @__PURE__ */ e(d, { ...r, ...t }),
    /* @__PURE__ */ e(u, { ...h, personas: l }),
    !(a != null && a.hide) && /* @__PURE__ */ e(g, { ...t, placeholder: o.prompt })
  ] }) });
};
export {
  z as default
};
