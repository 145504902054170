var a = Object.defineProperty;
var d = (i, e, t) => e in i ? a(i, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : i[e] = t;
var k = (i, e, t) => (d(i, typeof e != "symbol" ? e + "" : e, t), t);
let o = 5;
class g {
  constructor(e) {
    k(this, "socket");
    k(this, "events");
    k(this, "type");
    k(this, "eventListeners", []);
    this.socket = null, this.type = "text", this.events = {
      systemConecting: () => {
        console.log("Connecting to socket");
      },
      systemError: (t) => {
        console.error(t);
      },
      systemMessage: (t) => {
        console.log(t);
      },
      ...e
    };
  }
  connect(e, { type: t, msg: s }) {
    var c, r;
    try {
      this.socket || (this.socket = new WebSocket(e), t && (this.type = t), (r = (c = this.events).systemConecting) == null || r.call(c), this.socket.onopen = () => {
        console.log("Socket connection established"), this.send(s), o = 5, this.reattachListeners();
      }, this.socket.onerror = (n) => {
        var h, l;
        (l = (h = this.events).systemError) == null || l.call(h, JSON.stringify(n));
      }, this.socket.onclose = () => {
        var n, h;
        (h = (n = this.events).systemMessage) == null || h.call(n, `Socket closed. Reconnecting in ${o} seconds`), this.socket = null, setTimeout(() => {
          this.connect(e, { type: t, msg: s }), o = Math.min(o * 2, 60);
        }, o * 1e3);
      });
    } catch (n) {
      throw console.log(n), console.log(`'Error connecting to socket. Reconnecting in ${o} seconds`), this.socket = null, setTimeout(() => {
        this.connect(e, { type: t, msg: s }), o = Math.min(o * 2, 60);
      }, o * 1e3), n;
    }
  }
  disconnect() {
    this.socket && (this.socket.close(), this.socket = null);
  }
  reattachListeners() {
    this.socket && this.eventListeners.forEach(({ event: e, callback: t }) => {
      var s;
      (s = this.socket) == null || s.addEventListener(e, t);
    });
  }
  send(e) {
    var s, c;
    let t;
    switch (this.type) {
      case "text":
        t = e;
        break;
      case "json":
        t = JSON.stringify(e);
        break;
      case "binary":
        t = new Blob([JSON.stringify(e)], { type: "application/json" });
        break;
      default:
        t = e;
        break;
    }
    try {
      this.socket && this.socket.send(t);
    } catch (r) {
      throw console.log(r), (c = (s = this.events).systemError) == null || c.call(s, JSON.stringify(r)), "Error sending message to socket";
    }
  }
  on(e, t) {
    this.socket && (this.socket.addEventListener(e, t), this.eventListeners.push({ event: e, callback: t }));
  }
}
export {
  g as default
};
