/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { Action, AnyAction, Dispatch, MiddlewareAPI, configureStore } from "@reduxjs/toolkit";
import radioGroupReducer from "./context/radioGroupSlice";
import mobilityProfileReducer from "./context/mobilityProfileSlice";
import siteReducer from "./context/siteSlice";
import radioReducer from "./context/radioSlice";
import subscriberReducer from "./context/subscriberSlice";
import networkReducer from "./context/networkSlice";
import edgeReducer from "./context/edgeSlice";
import operatorReducer from "./context/operatorSlice";
import operatorRadioReducer from "./context/OperatorRAdioSlice";
import settingsReducer from "./context/settingsSlice";
import loadingReducer from "./context/loadingSlice";
import radioEditReducer from "./context/radioEditSlice";
import deviceGroupReducer from "./context/deviceGroupSlice";
import utilityReducer from "./context/utilitySlice";
import dashboardReducer from "./context/dashboardSlice";
import authReducer from "./context/authSlice";
import simulatorReducer from "./context/simulatorSlice";
import syncProfileReducer from "./context/syncProfileSlice";
import edgeEditReducer from "~/store/context/EdgeEditSlice";
import hardwareReducer from "./context/hardwareSlice";
import "./context/eventContext";
import radioConfigReducer from "./context/radioConfigSlice";
import zoneSlice from "./context/zoneSlice";
import zoneGroupSlice from "./context/zoneGroupSlice";
import itDashboardSlice from "./context/itDashboardSlice";
import kpiTemplateSlice from "./context/kpiTemplateSlice";
import insightSlice from "./context/insightSlice";
import onboardingSlice from "./context/onboardingSlice";
import policiesSlice from "./context/policiesSlice";
import alertProfileSlice from "./context/alertProfileSlice";
import userSlice  from "./context/userSlice";
import webSocketMiddleWare from "./middleware/eventsWebsocket";
import monitoringProfileReducer from "./context/monitoringProfileSlice";
const middlewares = [webSocketMiddleWare];

export const store = configureStore({
  reducer: {
    // reducer
    mobilityProfile: mobilityProfileReducer,
    alertProfile: alertProfileSlice,
    hardware: hardwareReducer,
    site: siteReducer,
    radioGroup: radioGroupReducer,
    radio: radioReducer,
    radioEdit: radioEditReducer,
    subscriber: subscriberReducer,
    deviceGroup: deviceGroupReducer,
    network: networkReducer,
    edge: edgeReducer,
    edgeEdit: edgeEditReducer,
    operator: operatorReducer,
    operatorRadio: operatorRadioReducer,
    settings: settingsReducer,
    loading: loadingReducer,
    utility: utilityReducer,
    dashboard: dashboardReducer,
    auth: authReducer,
    simulator: simulatorReducer,
    syncProfile: syncProfileReducer,
    monitoringProfile: monitoringProfileReducer,
    radioConfig: radioConfigReducer,
    zone: zoneSlice,
    zoneGroup: zoneGroupSlice,
    itDashboard: itDashboardSlice,
    kpiTemplate: kpiTemplateSlice,
    insight: insightSlice,
    onboarding: onboardingSlice,
    policies: policiesSlice,
    user : userSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = Action | ((dispatch: AppDispatch, getState: () => RootState) => ReturnType);
// Redux Middleware
export interface ReduxMiddleware<
  S = any, // type of the state supported by this middleware.
  D extends Dispatch = Dispatch, // type of the dispatch supported by this middleware.
  A extends Action = AnyAction, // type of the action(s) supported by this middleware, inferred from actions passed to applyMiddleware
  DispatchExt = {} // optional override return behavior of dispatch
> {
  (api: MiddlewareAPI<D, S>): (next: D) => (action: A) => A | DispatchExt;
}
