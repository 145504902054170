/**
 Copyright Highway9 Networks Inc.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { defaultPolicy, Policy } from "~/types/policy";

type initState = {
    current: Policy,
    isEdit: boolean;
    isOpen: boolean;
}

const initialState: initState = {
    current: defaultPolicy,
    isEdit: false,
    isOpen: false,
}

export const policiesSlice = createSlice({
    name: "policies",
    initialState,
    reducers: {
        setCurrent: (state, action: PayloadAction<Policy>) => {
            state.current = action.payload;
        },
        setIsEdit: (state, action: PayloadAction<boolean>) => {
            state.isEdit = action.payload;
        },
        resetCurrentPolicy: (state) => {
            state.current = initialState.current;
        },
        setIsOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        }
    }
});

export const policiesActions = policiesSlice.actions;

export default policiesSlice.reducer;

export const selectCurrentPolicy = (state: RootState) => state.policies.current;
export const selectIsEdit = (state: RootState) => state.policies.isEdit;
export const selectIsOpen = (state: RootState) => state.policies.isOpen;