
import { Types } from "~/constants/types";
import evt from "../event";
import { WebSocketMessage } from "~/types/WebsocketMessage";
import jsonmergepatch from "json-merge-patch";

export function socketRefreshHandler(type: string, data: WebSocketMessage[]) {

  // combine the patches of the same id
  const patches = data.reduce((acc, d) => {
    const found = acc.find((a) => a.objectId === d.objectId);
    if (found) {
      found.modifiedValues = jsonmergepatch.merge(found.modifiedValues, d.modifiedValues);
      found.oldValues = jsonmergepatch.merge(found.oldValues, d.oldValues);

      // Precedence over the event type
      // DELETE > CREATE > UPDATE
      if (d.eventType === "DELETE") {
        found.eventType = "DELETE";
      } else if (d.eventType === "CREATE" && found.eventType !== "DELETE") {
        found.eventType = "CREATE";
      }
    } else {
      acc.push(d);
    }
    return acc;
  }, [] as WebSocketMessage[]);

  console.log("Processing event", type, data.map(d => `${d.objectId} : ${d.eventType} (${d.updateEventType})`), '[PATCHES]', patches);


    switch (type) {
      case Types.radio:
          evt.emit('refreshRadios', patches)
        break;
      case Types.site:
        evt.emit('refreshSites', patches)
        break;
      case Types.radioGroup:
        evt.emit('refreshRadioGroups', patches)
        break;

      case Types.device:
        evt.emit('refreshDevices', patches)
        break;
      case Types.deviceGroup:
        evt.emit('refreshDeviceGroups', patches)
        break;

      case Types.edge:
        evt.emit('refreshEdges', patches)
        break;
      case Types.edgePool:
        evt.emit('refreshEdgePools', patches)
        break;

      case Types.network:
        evt.emit('refreshNetwork', patches)
        break;

      case Types.mobilityProfile:
        evt.emit('refreshMobilityProfiles', patches)
        break;

      case Types.syncProfile:
        evt.emit('refreshSyncProfiles', patches)
        break;

      case Types.simulatorAppliance:
        evt.emit('refreshSimulator', patches)
        break;
      case Types.ueSimulator:
        evt.emit('refreshUESimulators', patches)
        break;

      case Types.dnn:
        evt.emit('refreshDNN', patches)
        break;

      case Types.qosProfile:
        evt.emit('refreshQOSProfile', patches)
        break;

      case Types.application:
        evt.emit('refreshPolicy', patches)
        break;
      case Types.virtualMobilityZone:
        evt.emit('refreshVMZone', patches)
        break;
      case Types.zoneGroup:
        evt.emit('refreshZoneGroup', patches)
        break;

      case Types.hardware:
        evt.emit('refreshHardware', patches)
        break;

      case Types.supportChannel:
        evt.emit('refreshSupportChannel', patches)
        break;

      case Types.operation:
        patches.forEach((d) => {
          evt.emit('operations', d.objectId, d);
        });
        break;
    }

}