/** 
 Copyright Highway9 Networks Inc. 
 */ 
import RadioGlobalConfig from "~/types/globalRadioConfig";
import Radio, { RadioAvailableUpgrads } from "../types/radio";
import APIService from "./APIServices";
import { authenticationService } from "./authentication-service";
import { SASLog } from "~/types/SASLogs";
import { MetricGraph } from "~/types/metricGraph";
import { RadioChannelRecommandations } from "~/types/RadioChannelRecommandations";

const radioAPI = new APIService<Radio>("radios");
const radioConfigAPI = new APIService<RadioGlobalConfig>("radios/globalConfig");
const radioCoreKPIsList = new APIService<MetricGraph>("radios/metrics/kpis");

export const radioService = {
  getRadios: radioAPI.getAll,
  createRadio: radioAPI.create,
  updateRadio: radioAPI.update,
  deleteRadio: radioAPI.delete,
  clearSerialNumber,
  getMetricsAggregate: radioAPI.getAggregateMetrics,
  getMetrics: radioAPI.getMetrics,
  signCpi,
  getSASLogsBySerialNo,
  getRadioGroupAvailableUpgrades,
  getRadioGlobalConfig: radioConfigAPI.getAll,
  updateRadioGlobalConfig: radioConfigAPI.update,
  get: radioAPI.get,
  applyChannelRecommandations,
};

export const radioCoreKPIsListService = {
  getRadioCoreKPIsList : radioCoreKPIsList.getAll
}
async function clearSerialNumber(id: string) {
  return radioAPI.post(`/${id}/clearSerialNumber`);
}


async function applyChannelRecommandations(data: RadioChannelRecommandations) {
  return await radioAPI.post("/recommendations/apply", data);
}

async function signCpi(data: any, file: any) {
  const formData = new FormData();
  if (file !== "") {
    formData.append("upfile", file);
  }
  formData.append("metaData", JSON.stringify(data));
  const requestOptions = {
    method: "POST",
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + authenticationService.currentUserValue?.token,
    },
    body: formData,
  };

  return fetch(`/api/v1/orch/radios/operations/signByCpi`, requestOptions);
}

async function getSASLogsBySerialNo(serialNumber: string) {
  const data = await radioAPI.post(`${serialNumber}/SAS/getLogs`, {}) as unknown as SASLog[];
  return data;
}
async function getRadioGroupAvailableUpgrades(payload: any) {
  const requestBody = payload ? payload : { filter: {} };
  const response  = await radioAPI.post(`releases/query`, requestBody) as unknown as RadioAvailableUpgrads[];
  return response;
}

