import { jsx as t, jsxs as o } from "react/jsx-runtime";
import { useChatbotContext as m } from "./hooks/index.js";
import { c as g } from "../../chunks/createSvgIcon.js";
const f = g(/* @__PURE__ */ t("path", {
  d: "M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3m5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72z"
}), "Mic"), C = ({
  placeholder: c = "Type your message here..."
}) => {
  const { prompt: n, setPrompt: i, send: l, isProcessing: a, speech: s, inputRef: h } = m(), r = (e) => {
    e.preventDefault();
    const p = n.trim();
    a || p && (l(n.replace(/\n/g, `

`)), i(""));
  };
  function d() {
    s.isListening ? s.stopListening() : s.startListening();
  }
  return /* @__PURE__ */ o("form", { className: "chatbot-footer", onSubmit: r, children: [
    /* @__PURE__ */ t(
      "textarea",
      {
        disabled: a,
        className: "chatbot-input",
        rows: 1,
        value: n,
        placeholder: c,
        ref: h,
        onChange: (e) => {
          i(e.target.value), e.target.style.height = "auto", e.target.style.height = `${e.target.scrollHeight}px`;
        },
        onKeyDown: (e) => {
          if (e.key === "Enter")
            if (e.preventDefault(), e.shiftKey) {
              i(n + `
`);
              return;
            } else
              e.preventDefault(), r(e);
        }
      }
    ),
    /* @__PURE__ */ o("div", { className: "chatbot-actions", children: [
      /* @__PURE__ */ t("div", { id: "record", className: `action ${s.isListening ? "animate-recording" : ""} ${a ? "disabled" : ""}`, onClick: d, children: /* @__PURE__ */ t(f, {}) }),
      /* @__PURE__ */ t("div", { id: "send", className: `action ${a ? "disabled" : ""}`, onClick: r, children: "➤" })
    ] })
  ] });
};
export {
  C as default
};
