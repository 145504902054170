/**
 Copyright Highway9 Networks Inc.
 */
import { AlertProfile } from "~/types/AlertProfile";
import APIService from "./APIServices";

const alertProfileAPI = new APIService<AlertProfile>("alertProfile");

export const alertService = {
  getAlertProfiles: alertProfileAPI.getAll,
  addAlertProfile: alertProfileAPI.create,
  updateAlertProfile: alertProfileAPI.update,
  deleteAlertProfile: alertProfileAPI.delete,
};
