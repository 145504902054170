/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { store } from "..";
import evt from "../event";

import { fetchRadioGroups, radioGroupActions, updateRadioGroups } from "./radioGroupSlice";
import { fetchEdgePools, fetchEdges } from "./edgeSlice";
import { fetchRadios } from "./radioSlice";
import { fetchDNN, fetchDevices } from "./subscriberSlice";
import { fetchDeviceGroups } from "./deviceGroupSlice";
import { fetchSites } from "./siteSlice";
import { utilityActions } from "./utilitySlice";
import { fetchRadioSimulator, fetchSimulator, fetchUESimulators } from "./simulatorSlice";
import { loadingActions } from "./loadingSlice";
import { fetchServiceController } from "./dashboardSlice";
import { fetchInsights } from "./insightSlice";
import { fetchSupportChannel, fetchSystemSettings } from "./settingsSlice";
import { fetchPolicies, fetchQOSProfiles, fetchZones } from "./zoneSlice";
import { fetchZoneGroups } from "./zoneGroupSlice";
import { fetchHardware } from "./hardwareSlice";
import { workerInstance } from "~/sw";
import { fetchNetwork } from "./networkSlice";
import { fetchSyncProfiles } from "./syncProfileSlice";
import { fetchMobilityProfiles } from "./mobilityProfileSlice";

evt.on("refreshRadios", async (data) => {
  if(data){
    const radios = store.getState().radio.data;
    
    // Check if any of the patches contain upgradeToRelease or runtimeInfo.softwareVersion in modifiedValues
    const hasVersionChanges = data.some(patch => {
      if (!patch.modifiedValues || typeof patch.modifiedValues !== 'object') return false;
      
      // Check for direct upgradeToRelease property
      if ('upgradeToRelease' in patch.modifiedValues) return true;
      
      // Check for nested runtimeInfo.softwareVersion property
      if (patch.modifiedValues.runtimeInfo && 
          typeof patch.modifiedValues.runtimeInfo === 'object' && 
          'softwareVersion' in patch.modifiedValues.runtimeInfo) {
        return true;
      }
      
      return false;
    });
    
    if (hasVersionChanges) {
      // If version-related fields are modified, fetch all radios instead of patching
      await store.dispatch(fetchRadios());
    } else {
      const _radios = await workerInstance.patch(radios, data) as typeof radios;
      store.dispatch({
        type: fetchRadios.typePrefix + "/fulfilled",
        payload: _radios
      });
    }
  } else {
    await store.dispatch(fetchRadios());
  }
});

evt.on("refreshNetwork", async (data) => {
  if(data){
    const network = store.getState().network.data;
    if(network){
      const _network = await workerInstance.patch([network], data) as typeof network;
      store.dispatch({
        type: fetchNetwork.typePrefix + "/fulfilled",
        payload: _network
      });
    }
  } else {
    store.dispatch(fetchNetwork());
  }
});
evt.on("refreshRadioGroups", async (data) => {
  if(data){
    
    const radioGroups = store.getState().radioGroup.data;
    const _radioGroups = await workerInstance.patch(radioGroups, data) as typeof radioGroups
    store.dispatch({
      type: fetchRadioGroups.typePrefix + "/fulfilled",
      payload: _radioGroups
    });
  } else {
    store.dispatch(fetchRadioGroups({}));
  }
});

evt.on("refreshSites", async(data) => {
  if(data){
    const sites = store.getState().site.data;
    const _sites = await workerInstance.patch(sites, data) as typeof sites;
    store.dispatch({
      type: fetchSites.typePrefix + "/fulfilled",
      payload: _sites
    });
  } else { 
    store.dispatch(fetchSites());
  }
});

evt.on("updateGroupAndRadio", () => {
  const state = store.getState();
  const grps = state.radioGroup.data;
  const radios = state.radio.data;
  const _grps = updateRadioGroups(grps, radios);
  store.dispatch(radioGroupActions.setData(_grps));
});

evt.on("refreshEdges", async(data) => {
  if(data){
    const edges = store.getState().edge.data;
    
    // Check if any of the patches contain currentRelease in modifiedValues
    const hasCurrentReleaseChanges = data.some(patch => 
      patch.modifiedValues && 
      typeof patch.modifiedValues === 'object' && 
      ('currentRelease' in patch.modifiedValues || 'upgradeToRelease' in patch.modifiedValues)
    );
    
    if (hasCurrentReleaseChanges) {
      // If currentRelease is modified, fetch all edges instead of patching
      store.dispatch(fetchEdges());
    } else {
      const _edges = await workerInstance.patch(edges, data) as typeof edges;
      store.dispatch({
        type: fetchEdges.typePrefix + "/fulfilled",
        payload: _edges
      });
    }
  } else {
    store.dispatch(fetchEdges());
  }
});

evt.on("refreshEdgePools", async(data) => {
  if(data){
    const edgePools = store.getState().edge.edgePools;
    const _edgePools = await workerInstance.patch(edgePools, data) as typeof edgePools;
    console.log("Edge Pools", _edgePools);
    store.dispatch({
      type: fetchEdgePools.typePrefix + "/fulfilled",
      payload: _edgePools
    });
  } else {
    store.dispatch(fetchEdgePools());
  }
});

evt.on("refreshDevices", async (data) => {
  if(data){
    const _store = store.getState();
    const devices = _store.subscriber.data;
    const radios = _store.radio.data;
    const deviceGroups = _store.deviceGroup.data;

    const _devices = await workerInstance.patch(devices, data) as typeof devices;
    store.dispatch({
      type: fetchDevices.typePrefix + "/fulfilled",
      payload: {
        devices: _devices,
        radios,
        deviceGroups,
      }
    });
  } else {
    store.dispatch(fetchDevices());
  }
});

evt.on("refreshDeviceGroups", async (data) => {
  if(data){
    const deviceGroups = store.getState().deviceGroup.data;
    const _deviceGroups = await workerInstance.patch(deviceGroups, data) as typeof deviceGroups;
    store.dispatch({
      type: fetchDeviceGroups.typePrefix + "/fulfilled",
      payload: _deviceGroups
    });
  } else {
    store.dispatch(fetchDeviceGroups());
  }
});

evt.on("setStartandEndTime", (startTime, endTime) => {
  store.dispatch(utilityActions.setStartTime(startTime));
  store.dispatch(utilityActions.setEndTime(endTime));
  store.dispatch(utilityActions.setTimeInterval({ interval : "custom"}));
});

evt.on("refreshRadioSimulator", () => {
  store.dispatch(fetchRadioSimulator());
});

evt.on("refreshSimulator", async (data) => {
  if(data){
    const simulators = store.getState().simulator.data;
    const _simulators = await workerInstance.patch(simulators, data) as typeof simulators;
    store.dispatch({
      type: fetchSimulator.typePrefix + "/fulfilled",
      payload: _simulators
    });
  } else {
    store.dispatch(fetchSimulator());
  }
});

evt.on("refreshUESimulators", async (data) => {
  if(data){
    const ueSimulators = store.getState().simulator.ueSimulators;
    const _ueSimulators = await workerInstance.patch(ueSimulators, data) as typeof ueSimulators;
    store.dispatch({
      type: fetchUESimulators.typePrefix + "/fulfilled",
      payload: _ueSimulators
    });
  } else {
    store.dispatch(fetchUESimulators());
  }
});

evt.on("refreshSyncProfiles", async (data) => {
  if(data){
    const syncProfiles = store.getState().syncProfile.data;
    const _syncProfiles = await workerInstance.patch(syncProfiles, data) as typeof syncProfiles;
    store.dispatch({
      type: fetchSyncProfiles.typePrefix + "/fulfilled",
      payload: _syncProfiles
    });
  } else {
    store.dispatch(fetchSyncProfiles());
  }
});

evt.on("refreshMobilityProfiles", async (data) => {
  if(data){
    const mobilityProfiles = store.getState().mobilityProfile.data;
    const _mobilityProfiles = await workerInstance.patch(mobilityProfiles, data) as typeof mobilityProfiles;
    store.dispatch({
      type: fetchMobilityProfiles.typePrefix + "/fulfilled",
      payload: _mobilityProfiles
    });
  } else {
    store.dispatch(fetchMobilityProfiles());
  }
});

evt.on("refreshDNN", () => {
  store.dispatch(fetchDNN());
});

evt.on("openSnackbar", (data) => {
  store.dispatch(loadingActions.setSnackBar({ ...data, open: true }));
});

evt.on("refreshServiceController", () => {
  store.dispatch(fetchServiceController());
});


evt.on("refreshInsights", (status, sourceType, sourceId) => {

  store.dispatch(fetchInsights({
    sourceType: sourceType,
    status: status,
    source: sourceId ? { id: sourceId } as any : undefined
  }));
});

evt.on('refreshSystemSettings' , () => {
  store.dispatch(fetchSystemSettings());
});

evt.on('refreshQOSProfile' , () => {
  store.dispatch(fetchQOSProfiles());
});


evt.on('refreshPolicy' , () => {
  store.dispatch(fetchPolicies());
});

evt.on('refreshVMZone' , () => {
  store.dispatch(fetchZones());
});

evt.on('refreshZoneGroup', () => {
  store.dispatch(fetchZoneGroups());
});

evt.on('refreshHardware', () => {
  store.dispatch(fetchHardware());
});

evt.on('refreshSupportChannel', () => {
  store.dispatch(fetchSupportChannel());
});