/** 
 Copyright Highway9 Networks Inc. 
 */ 
import MuiDialogTitle, {DialogTitleProps} from "@mui/material/DialogTitle";
import MuiDialogContent, {DialogContentProps} from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Dialog, {DialogProps} from "@mui/material/Dialog";
import ErrorsComponent from "~/components/ErrorsComponent";

type DProps = {
  onClose: () => void;
} & DialogProps;
export const DialogBox = ((props: DProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Dialog onClose={(event, reason) => reason === "backdropClick" ? undefined : props.onClose()} {...other}>
      {children}
    </Dialog>
  );
});

type DTProps = DialogTitleProps & {
  onClose?: () => void;
}
export const DialogTitle = ((props : DTProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle  {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" style={{
          position: "absolute",
          right: "8px",
          top: "8px",
        }} onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

type DCProps = DialogContentProps & {
  errorsData?: any;
  warningsData?: any;
  closeAlert?: any;
  closeAll?: any;
}
export const DialogContent = ((props: DCProps) => {
  const { errorsData, warningsData, closeAlert, closeAll, children, ...others } = props;
  
  const hasErrorsOrWarnings = errorsData || warningsData;

  return (
    <MuiDialogContent {...others}>
      {hasErrorsOrWarnings && (
        <>
          <ErrorsComponent errorsData={errorsData} warningsData={warningsData} closeAlert={closeAlert} closeAll={closeAll} />
        </>
      )}
      {children}
    </MuiDialogContent>
  );
});

export const DialogActions = MuiDialogActions;
