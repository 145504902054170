/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { Network } from "~/types/network";
import APIService from "./APIServices";
import { SNMP } from "~/types/snmp";
import { AaaServerProfile } from "~/types/AAAProfile";

const networkAPI = new APIService<Network>('networks');
const monitoringSystemAPI = new APIService<SNMP>('networks/monitoringSystems');
const aaaServerProfileAPI = new APIService<AaaServerProfile>('networks/aaaServerProfile');

export const networkService = {
  getNetworks: networkAPI.getAll,
  updateNetwork : networkAPI.update,
  getSNMPData: monitoringSystemAPI.getAll,
  addSNMPData: monitoringSystemAPI.create,
  updateSNMPData: monitoringSystemAPI.update,
  deleteSNMP: monitoringSystemAPI.delete,
  getAAAServerProfilesData: aaaServerProfileAPI.getAll,
  createAAAServerProfile: aaaServerProfileAPI.create,
  updateAAAServerProfile: aaaServerProfileAPI.update,
  deleteAAAServerProfile: aaaServerProfileAPI.delete,
};