import { jsx as qe } from "react/jsx-runtime";
import { Box as Kt } from "@mui/material";
import { styles as Lt } from "./DateTimeSelect.style.js";
import ae from "moment-timezone";
import m, { createContext as pt, useContext as en, useCallback as G, useRef as Oe, useLayoutEffect as tn, useState as xe, useEffect as nn, useMemo as Ye } from "react";
import '../../assets/DatePickerComponent.css';var w;
(function(e) {
  e.Root = "root", e.Chevron = "chevron", e.Day = "day", e.DayButton = "day_button", e.CaptionLabel = "caption_label", e.Dropdowns = "dropdowns", e.Dropdown = "dropdown", e.DropdownRoot = "dropdown_root", e.Footer = "footer", e.MonthGrid = "month_grid", e.MonthCaption = "month_caption", e.MonthsDropdown = "months_dropdown", e.Month = "month", e.Months = "months", e.Nav = "nav", e.NextMonthButton = "button_next", e.PreviousMonthButton = "button_previous", e.Week = "week", e.Weeks = "weeks", e.Weekday = "weekday", e.Weekdays = "weekdays", e.WeekNumber = "week_number", e.WeekNumberHeader = "week_number_header", e.YearsDropdown = "years_dropdown";
})(w || (w = {}));
var x;
(function(e) {
  e.disabled = "disabled", e.hidden = "hidden", e.outside = "outside", e.focused = "focused", e.today = "today";
})(x || (x = {}));
var R;
(function(e) {
  e.range_end = "range_end", e.range_middle = "range_middle", e.range_start = "range_start", e.selected = "selected";
})(R || (R = {}));
var q;
(function(e) {
  e.weeks_before_enter = "weeks_before_enter", e.weeks_before_exit = "weeks_before_exit", e.weeks_after_enter = "weeks_after_enter", e.weeks_after_exit = "weeks_after_exit", e.caption_after_enter = "caption_after_enter", e.caption_after_exit = "caption_after_exit", e.caption_before_enter = "caption_before_enter", e.caption_before_exit = "caption_before_exit";
})(q || (q = {}));
const Te = {}, le = {};
function he(e, t) {
  try {
    const r = (Te[e] || (Te[e] = new Intl.DateTimeFormat("en-GB", {
      timeZone: e,
      hour: "numeric",
      timeZoneName: "longOffset"
    }).format))(t).split("GMT")[1] || "";
    return r in le ? le[r] : je(r, r.split(":"));
  } catch {
    if (e in le)
      return le[e];
    const n = e == null ? void 0 : e.match(rn);
    return n ? je(e, n.slice(1)) : NaN;
  }
}
const rn = /([+-]\d\d):?(\d\d)?/;
function je(e, t) {
  const n = +t[0], r = +(t[1] || 0);
  return le[e] = n > 0 ? n * 60 + r : n * 60 - r;
}
class Q extends Date {
  //#region static
  constructor(...t) {
    super(), t.length > 1 && typeof t[t.length - 1] == "string" && (this.timeZone = t.pop()), this.internal = /* @__PURE__ */ new Date(), isNaN(he(this.timeZone, this)) ? this.setTime(NaN) : t.length ? typeof t[0] == "number" && (t.length === 1 || t.length === 2 && typeof t[1] != "number") ? this.setTime(t[0]) : typeof t[0] == "string" ? this.setTime(+new Date(t[0])) : t[0] instanceof Date ? this.setTime(+t[0]) : (this.setTime(+new Date(...t)), Ke(this), Ee(this)) : this.setTime(Date.now());
  }
  static tz(t, ...n) {
    return n.length ? new Q(...n, t) : new Q(Date.now(), t);
  }
  //#endregion
  //#region time zone
  withTimeZone(t) {
    return new Q(+this, t);
  }
  getTimezoneOffset() {
    return -he(this.timeZone, this);
  }
  //#endregion
  //#region time
  setTime(t) {
    return Date.prototype.setTime.apply(this, arguments), Ee(this), +this;
  }
  //#endregion
  //#region date-fns integration
  [Symbol.for("constructDateFrom")](t) {
    return new Q(+new Date(t), this.timeZone);
  }
  //#endregion
}
const Ge = /^(get|set)(?!UTC)/;
Object.getOwnPropertyNames(Date.prototype).forEach((e) => {
  if (!Ge.test(e))
    return;
  const t = e.replace(Ge, "$1UTC");
  Q.prototype[t] && (e.startsWith("get") ? Q.prototype[e] = function() {
    return this.internal[t]();
  } : (Q.prototype[e] = function() {
    return Date.prototype[t].apply(this.internal, arguments), an(this), +this;
  }, Q.prototype[t] = function() {
    return Date.prototype[t].apply(this, arguments), Ee(this), +this;
  }));
});
function Ee(e) {
  e.internal.setTime(+e), e.internal.setUTCMinutes(e.internal.getUTCMinutes() - e.getTimezoneOffset());
}
function an(e) {
  Date.prototype.setFullYear.call(e, e.internal.getUTCFullYear(), e.internal.getUTCMonth(), e.internal.getUTCDate()), Date.prototype.setHours.call(e, e.internal.getUTCHours(), e.internal.getUTCMinutes(), e.internal.getUTCSeconds(), e.internal.getUTCMilliseconds()), Ke(e);
}
function Ke(e) {
  const t = he(e.timeZone, e), n = /* @__PURE__ */ new Date(+e);
  n.setUTCHours(n.getUTCHours() - 1);
  const r = -(/* @__PURE__ */ new Date(+e)).getTimezoneOffset(), a = -(/* @__PURE__ */ new Date(+n)).getTimezoneOffset(), o = r - a, s = Date.prototype.getHours.apply(e) !== e.internal.getUTCHours();
  o && s && e.internal.setUTCMinutes(e.internal.getUTCMinutes() + o);
  const i = r - t;
  i && Date.prototype.setUTCMinutes.call(e, Date.prototype.getUTCMinutes.call(e) + i);
  const c = he(e.timeZone, e), l = -(/* @__PURE__ */ new Date(+e)).getTimezoneOffset() - c, d = c !== t, h = l - i;
  if (d && h) {
    Date.prototype.setUTCMinutes.call(e, Date.prototype.getUTCMinutes.call(e) + h);
    const g = he(e.timeZone, e), M = c - g;
    M && (e.internal.setUTCMinutes(e.internal.getUTCMinutes() + M), Date.prototype.setUTCMinutes.call(e, Date.prototype.getUTCMinutes.call(e) + M));
  }
}
class te extends Q {
  //#region static
  static tz(t, ...n) {
    return n.length ? new te(...n, t) : new te(Date.now(), t);
  }
  //#endregion
  //#region representation
  toISOString() {
    const [t, n, r] = this.tzComponents(), a = `${t}${n}:${r}`;
    return this.internal.toISOString().slice(0, -1) + a;
  }
  toString() {
    return `${this.toDateString()} ${this.toTimeString()}`;
  }
  toDateString() {
    const [t, n, r, a] = this.internal.toUTCString().split(" ");
    return `${t == null ? void 0 : t.slice(0, -1)} ${r} ${n} ${a}`;
  }
  toTimeString() {
    const t = this.internal.toUTCString().split(" ")[4], [n, r, a] = this.tzComponents();
    return `${t} GMT${n}${r}${a} (${on(this.timeZone, this)})`;
  }
  toLocaleString(t, n) {
    return Date.prototype.toLocaleString.call(this, t, {
      ...n,
      timeZone: (n == null ? void 0 : n.timeZone) || this.timeZone
    });
  }
  toLocaleDateString(t, n) {
    return Date.prototype.toLocaleDateString.call(this, t, {
      ...n,
      timeZone: (n == null ? void 0 : n.timeZone) || this.timeZone
    });
  }
  toLocaleTimeString(t, n) {
    return Date.prototype.toLocaleTimeString.call(this, t, {
      ...n,
      timeZone: (n == null ? void 0 : n.timeZone) || this.timeZone
    });
  }
  //#endregion
  //#region private
  tzComponents() {
    const t = this.getTimezoneOffset(), n = t > 0 ? "-" : "+", r = String(Math.floor(Math.abs(t) / 60)).padStart(2, "0"), a = String(Math.abs(t) % 60).padStart(2, "0");
    return [n, r, a];
  }
  //#endregion
  withTimeZone(t) {
    return new te(+this, t);
  }
  //#region date-fns integration
  [Symbol.for("constructDateFrom")](t) {
    return new te(+new Date(t), this.timeZone);
  }
  //#endregion
}
function on(e, t) {
  return new Intl.DateTimeFormat("en-GB", {
    timeZone: e,
    timeZoneName: "long"
  }).format(t).slice(12);
}
const Le = 6048e5, sn = 864e5, $e = Symbol.for("constructDateFrom");
function F(e, t) {
  return typeof e == "function" ? e(t) : e && typeof e == "object" && $e in e ? e[$e](t) : e instanceof Date ? new e.constructor(t) : new Date(t);
}
function Y(e, t) {
  return F(t || e, e);
}
function pe(e, t, n) {
  const r = Y(e, n == null ? void 0 : n.in);
  return isNaN(t) ? F((n == null ? void 0 : n.in) || e, NaN) : (t && r.setDate(r.getDate() + t), r);
}
function et(e, t, n) {
  const r = Y(e, n == null ? void 0 : n.in);
  if (isNaN(t))
    return F((n == null ? void 0 : n.in) || e, NaN);
  if (!t)
    return r;
  const a = r.getDate(), o = F((n == null ? void 0 : n.in) || e, r.getTime());
  o.setMonth(r.getMonth() + t + 1, 0);
  const s = o.getDate();
  return a >= s ? o : (r.setFullYear(
    o.getFullYear(),
    o.getMonth(),
    a
  ), r);
}
let cn = {};
function ge() {
  return cn;
}
function se(e, t) {
  var i, c, f, l;
  const n = ge(), r = (t == null ? void 0 : t.weekStartsOn) ?? ((c = (i = t == null ? void 0 : t.locale) == null ? void 0 : i.options) == null ? void 0 : c.weekStartsOn) ?? n.weekStartsOn ?? ((l = (f = n.locale) == null ? void 0 : f.options) == null ? void 0 : l.weekStartsOn) ?? 0, a = Y(e, t == null ? void 0 : t.in), o = a.getDay(), s = (o < r ? 7 : 0) + o - r;
  return a.setDate(a.getDate() - s), a.setHours(0, 0, 0, 0), a;
}
function me(e, t) {
  return se(e, { ...t, weekStartsOn: 1 });
}
function tt(e, t) {
  const n = Y(e, t == null ? void 0 : t.in), r = n.getFullYear(), a = F(n, 0);
  a.setFullYear(r + 1, 0, 4), a.setHours(0, 0, 0, 0);
  const o = me(a), s = F(n, 0);
  s.setFullYear(r, 0, 4), s.setHours(0, 0, 0, 0);
  const i = me(s);
  return n.getTime() >= o.getTime() ? r + 1 : n.getTime() >= i.getTime() ? r : r - 1;
}
function Re(e) {
  const t = Y(e), n = new Date(
    Date.UTC(
      t.getFullYear(),
      t.getMonth(),
      t.getDate(),
      t.getHours(),
      t.getMinutes(),
      t.getSeconds(),
      t.getMilliseconds()
    )
  );
  return n.setUTCFullYear(t.getFullYear()), +e - +n;
}
function ie(e, ...t) {
  const n = F.bind(
    null,
    e || t.find((r) => typeof r == "object")
  );
  return t.map(n);
}
function ye(e, t) {
  const n = Y(e, t == null ? void 0 : t.in);
  return n.setHours(0, 0, 0, 0), n;
}
function nt(e, t, n) {
  const [r, a] = ie(
    n == null ? void 0 : n.in,
    e,
    t
  ), o = ye(r), s = ye(a), i = +o - Re(o), c = +s - Re(s);
  return Math.round((i - c) / sn);
}
function un(e, t) {
  const n = tt(e, t), r = F((t == null ? void 0 : t.in) || e, 0);
  return r.setFullYear(n, 0, 4), r.setHours(0, 0, 0, 0), me(r);
}
function fn(e, t, n) {
  return pe(e, t * 7, n);
}
function dn(e, t, n) {
  return et(e, t * 12, n);
}
function ln(e, t) {
  let n, r = t == null ? void 0 : t.in;
  return e.forEach((a) => {
    !r && typeof a == "object" && (r = F.bind(null, a));
    const o = Y(a, r);
    (!n || n < o || isNaN(+o)) && (n = o);
  }), F(r, n || NaN);
}
function hn(e, t) {
  let n, r = t == null ? void 0 : t.in;
  return e.forEach((a) => {
    !r && typeof a == "object" && (r = F.bind(null, a));
    const o = Y(a, r);
    (!n || n > o || isNaN(+o)) && (n = o);
  }), F(r, n || NaN);
}
function mn(e, t, n) {
  const [r, a] = ie(
    n == null ? void 0 : n.in,
    e,
    t
  );
  return +ye(r) == +ye(a);
}
function rt(e) {
  return e instanceof Date || typeof e == "object" && Object.prototype.toString.call(e) === "[object Date]";
}
function yn(e) {
  return !(!rt(e) && typeof e != "number" || isNaN(+Y(e)));
}
function gn(e, t, n) {
  const [r, a] = ie(
    n == null ? void 0 : n.in,
    e,
    t
  ), o = r.getFullYear() - a.getFullYear(), s = r.getMonth() - a.getMonth();
  return o * 12 + s;
}
function wn(e, t) {
  const n = Y(e, t == null ? void 0 : t.in), r = n.getMonth();
  return n.setFullYear(n.getFullYear(), r + 1, 0), n.setHours(23, 59, 59, 999), n;
}
function Mn(e, t) {
  const [n, r] = ie(e, t.start, t.end);
  return { start: n, end: r };
}
function bn(e, t) {
  const { start: n, end: r } = Mn(t == null ? void 0 : t.in, e);
  let a = +n > +r;
  const o = a ? +n : +r, s = a ? r : n;
  s.setHours(0, 0, 0, 0), s.setDate(1);
  let i = (t == null ? void 0 : t.step) ?? 1;
  if (!i)
    return [];
  i < 0 && (i = -i, a = !a);
  const c = [];
  for (; +s <= o; )
    c.push(F(n, s)), s.setMonth(s.getMonth() + i);
  return a ? c.reverse() : c;
}
function Dn(e, t) {
  const n = Y(e, t == null ? void 0 : t.in);
  return n.setDate(1), n.setHours(0, 0, 0, 0), n;
}
function kn(e, t) {
  const n = Y(e, t == null ? void 0 : t.in), r = n.getFullYear();
  return n.setFullYear(r + 1, 0, 0), n.setHours(23, 59, 59, 999), n;
}
function at(e, t) {
  const n = Y(e, t == null ? void 0 : t.in);
  return n.setFullYear(n.getFullYear(), 0, 1), n.setHours(0, 0, 0, 0), n;
}
function ot(e, t) {
  var i, c, f, l;
  const n = ge(), r = (t == null ? void 0 : t.weekStartsOn) ?? ((c = (i = t == null ? void 0 : t.locale) == null ? void 0 : i.options) == null ? void 0 : c.weekStartsOn) ?? n.weekStartsOn ?? ((l = (f = n.locale) == null ? void 0 : f.options) == null ? void 0 : l.weekStartsOn) ?? 0, a = Y(e, t == null ? void 0 : t.in), o = a.getDay(), s = (o < r ? -7 : 0) + 6 - (o - r);
  return a.setDate(a.getDate() + s), a.setHours(23, 59, 59, 999), a;
}
function On(e, t) {
  return ot(e, { ...t, weekStartsOn: 1 });
}
const vn = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
}, Wn = (e, t, n) => {
  let r;
  const a = vn[e];
  return typeof a == "string" ? r = a : t === 1 ? r = a.one : r = a.other.replace("{{count}}", t.toString()), n != null && n.addSuffix ? n.comparison && n.comparison > 0 ? "in " + r : r + " ago" : r;
};
function Pe(e) {
  return (t = {}) => {
    const n = t.width ? String(t.width) : e.defaultWidth;
    return e.formats[n] || e.formats[e.defaultWidth];
  };
}
const Sn = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
}, Cn = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
}, Nn = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, Yn = {
  date: Pe({
    formats: Sn,
    defaultWidth: "full"
  }),
  time: Pe({
    formats: Cn,
    defaultWidth: "full"
  }),
  dateTime: Pe({
    formats: Nn,
    defaultWidth: "full"
  })
}, Tn = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
}, Pn = (e, t, n, r) => Tn[e];
function fe(e) {
  return (t, n) => {
    const r = n != null && n.context ? String(n.context) : "standalone";
    let a;
    if (r === "formatting" && e.formattingValues) {
      const s = e.defaultFormattingWidth || e.defaultWidth, i = n != null && n.width ? String(n.width) : s;
      a = e.formattingValues[i] || e.formattingValues[s];
    } else {
      const s = e.defaultWidth, i = n != null && n.width ? String(n.width) : e.defaultWidth;
      a = e.values[i] || e.values[s];
    }
    const o = e.argumentCallback ? e.argumentCallback(t) : t;
    return a[o];
  };
}
const xn = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
}, En = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
}, _n = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  wide: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
}, Fn = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
}, Bn = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
}, In = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
}, Hn = (e, t) => {
  const n = Number(e), r = n % 100;
  if (r > 20 || r < 10)
    switch (r % 10) {
      case 1:
        return n + "st";
      case 2:
        return n + "nd";
      case 3:
        return n + "rd";
    }
  return n + "th";
}, An = {
  ordinalNumber: Hn,
  era: fe({
    values: xn,
    defaultWidth: "wide"
  }),
  quarter: fe({
    values: En,
    defaultWidth: "wide",
    argumentCallback: (e) => e - 1
  }),
  month: fe({
    values: _n,
    defaultWidth: "wide"
  }),
  day: fe({
    values: Fn,
    defaultWidth: "wide"
  }),
  dayPeriod: fe({
    values: Bn,
    defaultWidth: "wide",
    formattingValues: In,
    defaultFormattingWidth: "wide"
  })
};
function de(e) {
  return (t, n = {}) => {
    const r = n.width, a = r && e.matchPatterns[r] || e.matchPatterns[e.defaultMatchWidth], o = t.match(a);
    if (!o)
      return null;
    const s = o[0], i = r && e.parsePatterns[r] || e.parsePatterns[e.defaultParseWidth], c = Array.isArray(i) ? jn(i, (d) => d.test(s)) : (
      // [TODO] -- I challenge you to fix the type
      qn(i, (d) => d.test(s))
    );
    let f;
    f = e.valueCallback ? e.valueCallback(c) : c, f = n.valueCallback ? (
      // [TODO] -- I challenge you to fix the type
      n.valueCallback(f)
    ) : f;
    const l = t.slice(s.length);
    return { value: f, rest: l };
  };
}
function qn(e, t) {
  for (const n in e)
    if (Object.prototype.hasOwnProperty.call(e, n) && t(e[n]))
      return n;
}
function jn(e, t) {
  for (let n = 0; n < e.length; n++)
    if (t(e[n]))
      return n;
}
function Gn(e) {
  return (t, n = {}) => {
    const r = t.match(e.matchPattern);
    if (!r)
      return null;
    const a = r[0], o = t.match(e.parsePattern);
    if (!o)
      return null;
    let s = e.valueCallback ? e.valueCallback(o[0]) : o[0];
    s = n.valueCallback ? n.valueCallback(s) : s;
    const i = t.slice(a.length);
    return { value: s, rest: i };
  };
}
const $n = /^(\d+)(th|st|nd|rd)?/i, Rn = /\d+/i, zn = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
}, Qn = {
  any: [/^b/i, /^(a|c)/i]
}, Xn = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
}, Vn = {
  any: [/1/i, /2/i, /3/i, /4/i]
}, Zn = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
}, Un = {
  narrow: [
    /^j/i,
    /^f/i,
    /^m/i,
    /^a/i,
    /^m/i,
    /^j/i,
    /^j/i,
    /^a/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ],
  any: [
    /^ja/i,
    /^f/i,
    /^mar/i,
    /^ap/i,
    /^may/i,
    /^jun/i,
    /^jul/i,
    /^au/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ]
}, Jn = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
}, Kn = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
}, Ln = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
}, pn = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
}, er = {
  ordinalNumber: Gn({
    matchPattern: $n,
    parsePattern: Rn,
    valueCallback: (e) => parseInt(e, 10)
  }),
  era: de({
    matchPatterns: zn,
    defaultMatchWidth: "wide",
    parsePatterns: Qn,
    defaultParseWidth: "any"
  }),
  quarter: de({
    matchPatterns: Xn,
    defaultMatchWidth: "wide",
    parsePatterns: Vn,
    defaultParseWidth: "any",
    valueCallback: (e) => e + 1
  }),
  month: de({
    matchPatterns: Zn,
    defaultMatchWidth: "wide",
    parsePatterns: Un,
    defaultParseWidth: "any"
  }),
  day: de({
    matchPatterns: Jn,
    defaultMatchWidth: "wide",
    parsePatterns: Kn,
    defaultParseWidth: "any"
  }),
  dayPeriod: de({
    matchPatterns: Ln,
    defaultMatchWidth: "any",
    parsePatterns: pn,
    defaultParseWidth: "any"
  })
}, _e = {
  code: "en-US",
  formatDistance: Wn,
  formatLong: Yn,
  formatRelative: Pn,
  localize: An,
  match: er,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
function tr(e, t) {
  const n = Y(e, t == null ? void 0 : t.in);
  return nt(n, at(n)) + 1;
}
function st(e, t) {
  const n = Y(e, t == null ? void 0 : t.in), r = +me(n) - +un(n);
  return Math.round(r / Le) + 1;
}
function it(e, t) {
  var l, d, h, g;
  const n = Y(e, t == null ? void 0 : t.in), r = n.getFullYear(), a = ge(), o = (t == null ? void 0 : t.firstWeekContainsDate) ?? ((d = (l = t == null ? void 0 : t.locale) == null ? void 0 : l.options) == null ? void 0 : d.firstWeekContainsDate) ?? a.firstWeekContainsDate ?? ((g = (h = a.locale) == null ? void 0 : h.options) == null ? void 0 : g.firstWeekContainsDate) ?? 1, s = F((t == null ? void 0 : t.in) || e, 0);
  s.setFullYear(r + 1, 0, o), s.setHours(0, 0, 0, 0);
  const i = se(s, t), c = F((t == null ? void 0 : t.in) || e, 0);
  c.setFullYear(r, 0, o), c.setHours(0, 0, 0, 0);
  const f = se(c, t);
  return +n >= +i ? r + 1 : +n >= +f ? r : r - 1;
}
function nr(e, t) {
  var i, c, f, l;
  const n = ge(), r = (t == null ? void 0 : t.firstWeekContainsDate) ?? ((c = (i = t == null ? void 0 : t.locale) == null ? void 0 : i.options) == null ? void 0 : c.firstWeekContainsDate) ?? n.firstWeekContainsDate ?? ((l = (f = n.locale) == null ? void 0 : f.options) == null ? void 0 : l.firstWeekContainsDate) ?? 1, a = it(e, t), o = F((t == null ? void 0 : t.in) || e, 0);
  return o.setFullYear(a, 0, r), o.setHours(0, 0, 0, 0), se(o, t);
}
function ct(e, t) {
  const n = Y(e, t == null ? void 0 : t.in), r = +se(n, t) - +nr(n, t);
  return Math.round(r / Le) + 1;
}
function N(e, t) {
  const n = e < 0 ? "-" : "", r = Math.abs(e).toString().padStart(t, "0");
  return n + r;
}
const L = {
  // Year
  y(e, t) {
    const n = e.getFullYear(), r = n > 0 ? n : 1 - n;
    return N(t === "yy" ? r % 100 : r, t.length);
  },
  // Month
  M(e, t) {
    const n = e.getMonth();
    return t === "M" ? String(n + 1) : N(n + 1, 2);
  },
  // Day of the month
  d(e, t) {
    return N(e.getDate(), t.length);
  },
  // AM or PM
  a(e, t) {
    const n = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return n.toUpperCase();
      case "aaa":
        return n;
      case "aaaaa":
        return n[0];
      case "aaaa":
      default:
        return n === "am" ? "a.m." : "p.m.";
    }
  },
  // Hour [1-12]
  h(e, t) {
    return N(e.getHours() % 12 || 12, t.length);
  },
  // Hour [0-23]
  H(e, t) {
    return N(e.getHours(), t.length);
  },
  // Minute
  m(e, t) {
    return N(e.getMinutes(), t.length);
  },
  // Second
  s(e, t) {
    return N(e.getSeconds(), t.length);
  },
  // Fraction of second
  S(e, t) {
    const n = t.length, r = e.getMilliseconds(), a = Math.trunc(
      r * Math.pow(10, n - 3)
    );
    return N(a, t.length);
  }
}, oe = {
  am: "am",
  pm: "pm",
  midnight: "midnight",
  noon: "noon",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  night: "night"
}, ze = {
  // Era
  G: function(e, t, n) {
    const r = e.getFullYear() > 0 ? 1 : 0;
    switch (t) {
      case "G":
      case "GG":
      case "GGG":
        return n.era(r, { width: "abbreviated" });
      case "GGGGG":
        return n.era(r, { width: "narrow" });
      case "GGGG":
      default:
        return n.era(r, { width: "wide" });
    }
  },
  // Year
  y: function(e, t, n) {
    if (t === "yo") {
      const r = e.getFullYear(), a = r > 0 ? r : 1 - r;
      return n.ordinalNumber(a, { unit: "year" });
    }
    return L.y(e, t);
  },
  // Local week-numbering year
  Y: function(e, t, n, r) {
    const a = it(e, r), o = a > 0 ? a : 1 - a;
    if (t === "YY") {
      const s = o % 100;
      return N(s, 2);
    }
    return t === "Yo" ? n.ordinalNumber(o, { unit: "year" }) : N(o, t.length);
  },
  // ISO week-numbering year
  R: function(e, t) {
    const n = tt(e);
    return N(n, t.length);
  },
  // Extended year. This is a single number designating the year of this calendar system.
  // The main difference between `y` and `u` localizers are B.C. years:
  // | Year | `y` | `u` |
  // |------|-----|-----|
  // | AC 1 |   1 |   1 |
  // | BC 1 |   1 |   0 |
  // | BC 2 |   2 |  -1 |
  // Also `yy` always returns the last two digits of a year,
  // while `uu` pads single digit years to 2 characters and returns other years unchanged.
  u: function(e, t) {
    const n = e.getFullYear();
    return N(n, t.length);
  },
  // Quarter
  Q: function(e, t, n) {
    const r = Math.ceil((e.getMonth() + 1) / 3);
    switch (t) {
      case "Q":
        return String(r);
      case "QQ":
        return N(r, 2);
      case "Qo":
        return n.ordinalNumber(r, { unit: "quarter" });
      case "QQQ":
        return n.quarter(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "QQQQQ":
        return n.quarter(r, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return n.quarter(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone quarter
  q: function(e, t, n) {
    const r = Math.ceil((e.getMonth() + 1) / 3);
    switch (t) {
      case "q":
        return String(r);
      case "qq":
        return N(r, 2);
      case "qo":
        return n.ordinalNumber(r, { unit: "quarter" });
      case "qqq":
        return n.quarter(r, {
          width: "abbreviated",
          context: "standalone"
        });
      case "qqqqq":
        return n.quarter(r, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return n.quarter(r, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Month
  M: function(e, t, n) {
    const r = e.getMonth();
    switch (t) {
      case "M":
      case "MM":
        return L.M(e, t);
      case "Mo":
        return n.ordinalNumber(r + 1, { unit: "month" });
      case "MMM":
        return n.month(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "MMMMM":
        return n.month(r, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return n.month(r, { width: "wide", context: "formatting" });
    }
  },
  // Stand-alone month
  L: function(e, t, n) {
    const r = e.getMonth();
    switch (t) {
      case "L":
        return String(r + 1);
      case "LL":
        return N(r + 1, 2);
      case "Lo":
        return n.ordinalNumber(r + 1, { unit: "month" });
      case "LLL":
        return n.month(r, {
          width: "abbreviated",
          context: "standalone"
        });
      case "LLLLL":
        return n.month(r, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return n.month(r, { width: "wide", context: "standalone" });
    }
  },
  // Local week of year
  w: function(e, t, n, r) {
    const a = ct(e, r);
    return t === "wo" ? n.ordinalNumber(a, { unit: "week" }) : N(a, t.length);
  },
  // ISO week of year
  I: function(e, t, n) {
    const r = st(e);
    return t === "Io" ? n.ordinalNumber(r, { unit: "week" }) : N(r, t.length);
  },
  // Day of the month
  d: function(e, t, n) {
    return t === "do" ? n.ordinalNumber(e.getDate(), { unit: "date" }) : L.d(e, t);
  },
  // Day of year
  D: function(e, t, n) {
    const r = tr(e);
    return t === "Do" ? n.ordinalNumber(r, { unit: "dayOfYear" }) : N(r, t.length);
  },
  // Day of week
  E: function(e, t, n) {
    const r = e.getDay();
    switch (t) {
      case "E":
      case "EE":
      case "EEE":
        return n.day(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "EEEEE":
        return n.day(r, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return n.day(r, {
          width: "short",
          context: "formatting"
        });
      case "EEEE":
      default:
        return n.day(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Local day of week
  e: function(e, t, n, r) {
    const a = e.getDay(), o = (a - r.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "e":
        return String(o);
      case "ee":
        return N(o, 2);
      case "eo":
        return n.ordinalNumber(o, { unit: "day" });
      case "eee":
        return n.day(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "eeeee":
        return n.day(a, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return n.day(a, {
          width: "short",
          context: "formatting"
        });
      case "eeee":
      default:
        return n.day(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone local day of week
  c: function(e, t, n, r) {
    const a = e.getDay(), o = (a - r.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "c":
        return String(o);
      case "cc":
        return N(o, t.length);
      case "co":
        return n.ordinalNumber(o, { unit: "day" });
      case "ccc":
        return n.day(a, {
          width: "abbreviated",
          context: "standalone"
        });
      case "ccccc":
        return n.day(a, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return n.day(a, {
          width: "short",
          context: "standalone"
        });
      case "cccc":
      default:
        return n.day(a, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // ISO day of week
  i: function(e, t, n) {
    const r = e.getDay(), a = r === 0 ? 7 : r;
    switch (t) {
      case "i":
        return String(a);
      case "ii":
        return N(a, t.length);
      case "io":
        return n.ordinalNumber(a, { unit: "day" });
      case "iii":
        return n.day(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "iiiii":
        return n.day(r, {
          width: "narrow",
          context: "formatting"
        });
      case "iiiiii":
        return n.day(r, {
          width: "short",
          context: "formatting"
        });
      case "iiii":
      default:
        return n.day(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM or PM
  a: function(e, t, n) {
    const a = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "aaa":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "aaaaa":
        return n.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return n.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM, PM, midnight, noon
  b: function(e, t, n) {
    const r = e.getHours();
    let a;
    switch (r === 12 ? a = oe.noon : r === 0 ? a = oe.midnight : a = r / 12 >= 1 ? "pm" : "am", t) {
      case "b":
      case "bb":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "bbb":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "bbbbb":
        return n.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return n.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // in the morning, in the afternoon, in the evening, at night
  B: function(e, t, n) {
    const r = e.getHours();
    let a;
    switch (r >= 17 ? a = oe.evening : r >= 12 ? a = oe.afternoon : r >= 4 ? a = oe.morning : a = oe.night, t) {
      case "B":
      case "BB":
      case "BBB":
        return n.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        });
      case "BBBBB":
        return n.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return n.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Hour [1-12]
  h: function(e, t, n) {
    if (t === "ho") {
      let r = e.getHours() % 12;
      return r === 0 && (r = 12), n.ordinalNumber(r, { unit: "hour" });
    }
    return L.h(e, t);
  },
  // Hour [0-23]
  H: function(e, t, n) {
    return t === "Ho" ? n.ordinalNumber(e.getHours(), { unit: "hour" }) : L.H(e, t);
  },
  // Hour [0-11]
  K: function(e, t, n) {
    const r = e.getHours() % 12;
    return t === "Ko" ? n.ordinalNumber(r, { unit: "hour" }) : N(r, t.length);
  },
  // Hour [1-24]
  k: function(e, t, n) {
    let r = e.getHours();
    return r === 0 && (r = 24), t === "ko" ? n.ordinalNumber(r, { unit: "hour" }) : N(r, t.length);
  },
  // Minute
  m: function(e, t, n) {
    return t === "mo" ? n.ordinalNumber(e.getMinutes(), { unit: "minute" }) : L.m(e, t);
  },
  // Second
  s: function(e, t, n) {
    return t === "so" ? n.ordinalNumber(e.getSeconds(), { unit: "second" }) : L.s(e, t);
  },
  // Fraction of second
  S: function(e, t) {
    return L.S(e, t);
  },
  // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
  X: function(e, t, n) {
    const r = e.getTimezoneOffset();
    if (r === 0)
      return "Z";
    switch (t) {
      case "X":
        return Xe(r);
      case "XXXX":
      case "XX":
        return ee(r);
      case "XXXXX":
      case "XXX":
      default:
        return ee(r, ":");
    }
  },
  // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
  x: function(e, t, n) {
    const r = e.getTimezoneOffset();
    switch (t) {
      case "x":
        return Xe(r);
      case "xxxx":
      case "xx":
        return ee(r);
      case "xxxxx":
      case "xxx":
      default:
        return ee(r, ":");
    }
  },
  // Timezone (GMT)
  O: function(e, t, n) {
    const r = e.getTimezoneOffset();
    switch (t) {
      case "O":
      case "OO":
      case "OOO":
        return "GMT" + Qe(r, ":");
      case "OOOO":
      default:
        return "GMT" + ee(r, ":");
    }
  },
  // Timezone (specific non-location)
  z: function(e, t, n) {
    const r = e.getTimezoneOffset();
    switch (t) {
      case "z":
      case "zz":
      case "zzz":
        return "GMT" + Qe(r, ":");
      case "zzzz":
      default:
        return "GMT" + ee(r, ":");
    }
  },
  // Seconds timestamp
  t: function(e, t, n) {
    const r = Math.trunc(+e / 1e3);
    return N(r, t.length);
  },
  // Milliseconds timestamp
  T: function(e, t, n) {
    return N(+e, t.length);
  }
};
function Qe(e, t = "") {
  const n = e > 0 ? "-" : "+", r = Math.abs(e), a = Math.trunc(r / 60), o = r % 60;
  return o === 0 ? n + String(a) : n + String(a) + t + N(o, 2);
}
function Xe(e, t) {
  return e % 60 === 0 ? (e > 0 ? "-" : "+") + N(Math.abs(e) / 60, 2) : ee(e, t);
}
function ee(e, t = "") {
  const n = e > 0 ? "-" : "+", r = Math.abs(e), a = N(Math.trunc(r / 60), 2), o = N(r % 60, 2);
  return n + a + t + o;
}
const Ve = (e, t) => {
  switch (e) {
    case "P":
      return t.date({ width: "short" });
    case "PP":
      return t.date({ width: "medium" });
    case "PPP":
      return t.date({ width: "long" });
    case "PPPP":
    default:
      return t.date({ width: "full" });
  }
}, ut = (e, t) => {
  switch (e) {
    case "p":
      return t.time({ width: "short" });
    case "pp":
      return t.time({ width: "medium" });
    case "ppp":
      return t.time({ width: "long" });
    case "pppp":
    default:
      return t.time({ width: "full" });
  }
}, rr = (e, t) => {
  const n = e.match(/(P+)(p+)?/) || [], r = n[1], a = n[2];
  if (!a)
    return Ve(e, t);
  let o;
  switch (r) {
    case "P":
      o = t.dateTime({ width: "short" });
      break;
    case "PP":
      o = t.dateTime({ width: "medium" });
      break;
    case "PPP":
      o = t.dateTime({ width: "long" });
      break;
    case "PPPP":
    default:
      o = t.dateTime({ width: "full" });
      break;
  }
  return o.replace("{{date}}", Ve(r, t)).replace("{{time}}", ut(a, t));
}, ar = {
  p: ut,
  P: rr
}, or = /^D+$/, sr = /^Y+$/, ir = ["D", "DD", "YY", "YYYY"];
function cr(e) {
  return or.test(e);
}
function ur(e) {
  return sr.test(e);
}
function fr(e, t, n) {
  const r = dr(e, t, n);
  if (console.warn(r), ir.includes(e))
    throw new RangeError(r);
}
function dr(e, t, n) {
  const r = e[0] === "Y" ? "years" : "days of the month";
  return `Use \`${e.toLowerCase()}\` instead of \`${e}\` (in \`${t}\`) for formatting ${r} to the input \`${n}\`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md`;
}
const lr = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, hr = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, mr = /^'([^]*?)'?$/, yr = /''/g, gr = /[a-zA-Z]/;
function wr(e, t, n) {
  var l, d, h, g, M, D, y, b;
  const r = ge(), a = (n == null ? void 0 : n.locale) ?? r.locale ?? _e, o = (n == null ? void 0 : n.firstWeekContainsDate) ?? ((d = (l = n == null ? void 0 : n.locale) == null ? void 0 : l.options) == null ? void 0 : d.firstWeekContainsDate) ?? r.firstWeekContainsDate ?? ((g = (h = r.locale) == null ? void 0 : h.options) == null ? void 0 : g.firstWeekContainsDate) ?? 1, s = (n == null ? void 0 : n.weekStartsOn) ?? ((D = (M = n == null ? void 0 : n.locale) == null ? void 0 : M.options) == null ? void 0 : D.weekStartsOn) ?? r.weekStartsOn ?? ((b = (y = r.locale) == null ? void 0 : y.options) == null ? void 0 : b.weekStartsOn) ?? 0, i = Y(e, n == null ? void 0 : n.in);
  if (!yn(i))
    throw new RangeError("Invalid time value");
  let c = t.match(hr).map((u) => {
    const k = u[0];
    if (k === "p" || k === "P") {
      const S = ar[k];
      return S(u, a.formatLong);
    }
    return u;
  }).join("").match(lr).map((u) => {
    if (u === "''")
      return { isToken: !1, value: "'" };
    const k = u[0];
    if (k === "'")
      return { isToken: !1, value: Mr(u) };
    if (ze[k])
      return { isToken: !0, value: u };
    if (k.match(gr))
      throw new RangeError(
        "Format string contains an unescaped latin alphabet character `" + k + "`"
      );
    return { isToken: !1, value: u };
  });
  a.localize.preprocessor && (c = a.localize.preprocessor(i, c));
  const f = {
    firstWeekContainsDate: o,
    weekStartsOn: s,
    locale: a
  };
  return c.map((u) => {
    if (!u.isToken)
      return u.value;
    const k = u.value;
    (!(n != null && n.useAdditionalWeekYearTokens) && ur(k) || !(n != null && n.useAdditionalDayOfYearTokens) && cr(k)) && fr(k, t, String(e));
    const S = ze[k[0]];
    return S(i, k, a.localize, f);
  }).join("");
}
function Mr(e) {
  const t = e.match(mr);
  return t ? t[1].replace(yr, "'") : e;
}
function br(e, t) {
  const n = Y(e, t == null ? void 0 : t.in), r = n.getFullYear(), a = n.getMonth(), o = F(n, 0);
  return o.setFullYear(r, a + 1, 0), o.setHours(0, 0, 0, 0), o.getDate();
}
function Dr(e, t) {
  return Y(e, t == null ? void 0 : t.in).getMonth();
}
function kr(e, t) {
  return Y(e, t == null ? void 0 : t.in).getFullYear();
}
function Or(e, t) {
  return +Y(e) > +Y(t);
}
function vr(e, t) {
  return +Y(e) < +Y(t);
}
function Wr(e, t, n) {
  const [r, a] = ie(
    n == null ? void 0 : n.in,
    e,
    t
  );
  return r.getFullYear() === a.getFullYear() && r.getMonth() === a.getMonth();
}
function Sr(e, t, n) {
  const [r, a] = ie(
    n == null ? void 0 : n.in,
    e,
    t
  );
  return r.getFullYear() === a.getFullYear();
}
function Cr(e, t, n) {
  const r = Y(e, n == null ? void 0 : n.in), a = r.getFullYear(), o = r.getDate(), s = F((n == null ? void 0 : n.in) || e, 0);
  s.setFullYear(a, t, 15), s.setHours(0, 0, 0, 0);
  const i = br(s);
  return r.setMonth(t, Math.min(o, i)), r;
}
function Nr(e, t, n) {
  const r = Y(e, n == null ? void 0 : n.in);
  return isNaN(+r) ? F((n == null ? void 0 : n.in) || e, NaN) : (r.setFullYear(t), r);
}
const Ze = 5, Yr = 4;
function Tr(e, t) {
  const n = t.startOfMonth(e), r = n.getDay() > 0 ? n.getDay() : 7, a = t.addDays(e, -r + 1), o = t.addDays(a, Ze * 7 - 1);
  return t.getMonth(e) === t.getMonth(o) ? Ze : Yr;
}
function ft(e, t) {
  const n = t.startOfMonth(e), r = n.getDay();
  return r === 1 ? n : r === 0 ? t.addDays(n, -1 * 6) : t.addDays(n, -1 * (r - 1));
}
function Pr(e, t) {
  const n = ft(e, t), r = Tr(e, t);
  return t.addDays(n, r * 7 - 1);
}
class U {
  /**
   * Creates an instance of DateLib.
   *
   * @param options The options for the date library.
   * @param overrides Overrides for the date library functions.
   */
  constructor(t, n) {
    this.Date = Date, this.today = () => {
      var r;
      return (r = this.overrides) != null && r.today ? this.overrides.today() : this.options.timeZone ? te.tz(this.options.timeZone) : new this.Date();
    }, this.newDate = (r, a, o) => {
      var s;
      return (s = this.overrides) != null && s.newDate ? this.overrides.newDate(r, a, o) : this.options.timeZone ? new te(r, a, o, this.options.timeZone) : new Date(r, a, o);
    }, this.addDays = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.addDays) == null ? void 0 : s.call(o, r, a)) ?? pe(r, a);
    }, this.addMonths = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.addMonths) == null ? void 0 : s.call(o, r, a)) ?? et(r, a);
    }, this.addWeeks = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.addWeeks) == null ? void 0 : s.call(o, r, a)) ?? fn(r, a);
    }, this.addYears = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.addYears) == null ? void 0 : s.call(o, r, a)) ?? dn(r, a);
    }, this.differenceInCalendarDays = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.differenceInCalendarDays) == null ? void 0 : s.call(o, r, a)) ?? nt(r, a);
    }, this.differenceInCalendarMonths = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.differenceInCalendarMonths) == null ? void 0 : s.call(o, r, a)) ?? gn(r, a);
    }, this.eachMonthOfInterval = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.eachMonthOfInterval) == null ? void 0 : o.call(a, r)) ?? bn(r);
    }, this.endOfBroadcastWeek = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.endOfBroadcastWeek) == null ? void 0 : s.call(o, r, a)) ?? Pr(r, this);
    }, this.endOfISOWeek = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.endOfISOWeek) == null ? void 0 : o.call(a, r)) ?? On(r);
    }, this.endOfMonth = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.endOfMonth) == null ? void 0 : o.call(a, r)) ?? wn(r);
    }, this.endOfWeek = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.endOfWeek) == null ? void 0 : s.call(o, r, a ?? this.options)) ?? ot(r, a ?? this.options);
    }, this.endOfYear = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.endOfYear) == null ? void 0 : o.call(a, r)) ?? kn(r);
    }, this.format = (r, a, o) => {
      var i, c;
      const s = ((c = (i = this.overrides) == null ? void 0 : i.format) == null ? void 0 : c.call(i, r, a, o ?? this.options)) ?? wr(r, a, o ?? this.options);
      return this.options.numerals && this.options.numerals !== "latn" ? this.replaceDigits(s) : s;
    }, this.getISOWeek = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.getISOWeek) == null ? void 0 : o.call(a, r)) ?? st(r);
    }, this.getMonth = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.getMonth) == null ? void 0 : o.call(a, r)) ?? Dr(r);
    }, this.getYear = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.getYear) == null ? void 0 : o.call(a, r)) ?? kr(r);
    }, this.getWeek = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.getWeek) == null ? void 0 : s.call(o, r, a ?? this.options)) ?? ct(r, a ?? this.options);
    }, this.isAfter = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.isAfter) == null ? void 0 : s.call(o, r, a)) ?? Or(r, a);
    }, this.isBefore = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.isBefore) == null ? void 0 : s.call(o, r, a)) ?? vr(r, a);
    }, this.isDate = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.isDate) == null ? void 0 : o.call(a, r)) ?? rt(r);
    }, this.isSameDay = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.isSameDay) == null ? void 0 : s.call(o, r, a)) ?? mn(r, a);
    }, this.isSameMonth = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.isSameMonth) == null ? void 0 : s.call(o, r, a)) ?? Wr(r, a);
    }, this.isSameYear = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.isSameYear) == null ? void 0 : s.call(o, r, a)) ?? Sr(r, a);
    }, this.max = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.max) == null ? void 0 : o.call(a, r)) ?? ln(r);
    }, this.min = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.min) == null ? void 0 : o.call(a, r)) ?? hn(r);
    }, this.setMonth = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.setMonth) == null ? void 0 : s.call(o, r, a)) ?? Cr(r, a);
    }, this.setYear = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.setYear) == null ? void 0 : s.call(o, r, a)) ?? Nr(r, a);
    }, this.startOfBroadcastWeek = (r, a) => {
      var o, s;
      return ((s = (o = this.overrides) == null ? void 0 : o.startOfBroadcastWeek) == null ? void 0 : s.call(o, r, a ?? this)) ?? ft(r, a ?? this);
    }, this.startOfDay = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.startOfDay) == null ? void 0 : o.call(a, r)) ?? ye(r);
    }, this.startOfISOWeek = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.startOfISOWeek) == null ? void 0 : o.call(a, r)) ?? me(r);
    }, this.startOfMonth = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.startOfMonth) == null ? void 0 : o.call(a, r)) ?? Dn(r);
    }, this.startOfWeek = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.startOfWeek) == null ? void 0 : o.call(a, r)) ?? se(r, this.options);
    }, this.startOfYear = (r) => {
      var a, o;
      return ((o = (a = this.overrides) == null ? void 0 : a.startOfYear) == null ? void 0 : o.call(a, r)) ?? at(r);
    }, this.options = { locale: _e, ...t }, this.overrides = n;
  }
  /**
   * Generate digit map dynamically using Intl.NumberFormat.
   *
   * @since 9.5.0
   */
  getDigitMap() {
    const { numerals: t = "latn" } = this.options, n = new Intl.NumberFormat("en-US", {
      numberingSystem: t
    }), r = {};
    for (let a = 0; a < 10; a++)
      r[a.toString()] = n.format(a);
    return r;
  }
  /**
   * Replace Arabic digits with the target numbering system digits.
   *
   * @since 9.5.0
   */
  replaceDigits(t) {
    const n = this.getDigitMap();
    return t.replace(/\d/g, (r) => n[r] || r);
  }
  /**
   * Format number using the custom numbering system.
   *
   * @since 9.5.0
   * @param value The number to format.
   * @returns The formatted number.
   */
  formatNumber(t) {
    return this.replaceDigits(t.toString());
  }
}
const J = new U();
function xr(e, t, n = {}) {
  return Object.entries(e).filter(([, a]) => a === !0).reduce((a, [o]) => (n[o] ? a.push(n[o]) : t[x[o]] ? a.push(t[x[o]]) : t[R[o]] && a.push(t[R[o]]), a), [t[w.Day]]);
}
function Er(e) {
  return m.createElement("button", { ...e });
}
function _r(e) {
  return m.createElement("span", { ...e });
}
function Fr(e) {
  const { size: t = 24, orientation: n = "left", className: r } = e;
  return m.createElement(
    "svg",
    { className: r, width: t, height: t, viewBox: "0 0 24 24" },
    n === "up" && m.createElement("polygon", { points: "6.77 17 12.5 11.43 18.24 17 20 15.28 12.5 8 5 15.28" }),
    n === "down" && m.createElement("polygon", { points: "6.77 8 12.5 13.57 18.24 8 20 9.72 12.5 17 5 9.72" }),
    n === "left" && m.createElement("polygon", { points: "16 18.112 9.81111111 12 16 5.87733333 14.0888889 4 6 12 14.0888889 20" }),
    n === "right" && m.createElement("polygon", { points: "8 18.612 14.1888889 12.5 8 6.37733333 9.91111111 4.5 18 12.5 9.91111111 20.5" })
  );
}
function Br(e) {
  const { day: t, modifiers: n, ...r } = e;
  return m.createElement("td", { ...r });
}
function Ir(e) {
  const { day: t, modifiers: n, ...r } = e, a = m.useRef(null);
  return m.useEffect(() => {
    var o;
    n.focused && ((o = a.current) == null || o.focus());
  }, [n.focused]), m.createElement("button", { ref: a, ...r });
}
function Hr(e) {
  const { options: t, className: n, components: r, classNames: a, ...o } = e, s = [a[w.Dropdown], n].join(" "), i = t == null ? void 0 : t.find(({ value: c }) => c === o.value);
  return m.createElement(
    "span",
    { "data-disabled": o.disabled, className: a[w.DropdownRoot] },
    m.createElement(r.Select, { className: s, ...o }, t == null ? void 0 : t.map(({ value: c, label: f, disabled: l }) => m.createElement(r.Option, { key: c, value: c, disabled: l }, f))),
    m.createElement(
      "span",
      { className: a[w.CaptionLabel], "aria-hidden": !0 },
      i == null ? void 0 : i.label,
      m.createElement(r.Chevron, { orientation: "down", size: 18, className: a[w.Chevron] })
    )
  );
}
function Ar(e) {
  return m.createElement("div", { ...e });
}
function qr(e) {
  return m.createElement("div", { ...e });
}
function jr(e) {
  const { calendarMonth: t, displayIndex: n, ...r } = e;
  return m.createElement("div", { ...r }, e.children);
}
function Gr(e) {
  const { calendarMonth: t, displayIndex: n, ...r } = e;
  return m.createElement("div", { ...r });
}
function $r(e) {
  return m.createElement("table", { ...e });
}
function Rr(e) {
  return m.createElement("div", { ...e });
}
const dt = pt(void 0);
function we() {
  const e = en(dt);
  if (e === void 0)
    throw new Error("useDayPicker() must be used within a custom component.");
  return e;
}
function zr(e) {
  const { components: t } = we();
  return m.createElement(t.Dropdown, { ...e });
}
function Qr(e) {
  const { onPreviousClick: t, onNextClick: n, previousMonth: r, nextMonth: a, ...o } = e, { components: s, classNames: i, labels: { labelPrevious: c, labelNext: f } } = we(), l = G((h) => {
    a && (n == null || n(h));
  }, [a, n]), d = G((h) => {
    r && (t == null || t(h));
  }, [r, t]);
  return m.createElement(
    "nav",
    { ...o },
    m.createElement(
      s.PreviousMonthButton,
      { type: "button", className: i[w.PreviousMonthButton], tabIndex: r ? void 0 : -1, "aria-disabled": r ? void 0 : !0, "aria-label": c(r), onClick: d },
      m.createElement(s.Chevron, { disabled: r ? void 0 : !0, className: i[w.Chevron], orientation: "left" })
    ),
    m.createElement(
      s.NextMonthButton,
      { type: "button", className: i[w.NextMonthButton], tabIndex: a ? void 0 : -1, "aria-disabled": a ? void 0 : !0, "aria-label": f(a), onClick: l },
      m.createElement(s.Chevron, { disabled: a ? void 0 : !0, orientation: "right", className: i[w.Chevron] })
    )
  );
}
function Xr(e) {
  const { components: t } = we();
  return m.createElement(t.Button, { ...e });
}
function Vr(e) {
  return m.createElement("option", { ...e });
}
function Zr(e) {
  const { components: t } = we();
  return m.createElement(t.Button, { ...e });
}
function Ur(e) {
  const { rootRef: t, ...n } = e;
  return m.createElement("div", { ...n, ref: t });
}
function Jr(e) {
  return m.createElement("select", { ...e });
}
function Kr(e) {
  const { week: t, ...n } = e;
  return m.createElement("tr", { ...n });
}
function Lr(e) {
  return m.createElement("th", { ...e });
}
function pr(e) {
  return m.createElement(
    "thead",
    { "aria-hidden": !0 },
    m.createElement("tr", { ...e })
  );
}
function ea(e) {
  const { week: t, ...n } = e;
  return m.createElement("th", { ...n });
}
function ta(e) {
  return m.createElement("th", { ...e });
}
function na(e) {
  return m.createElement("tbody", { ...e });
}
function ra(e) {
  const { components: t } = we();
  return m.createElement(t.Dropdown, { ...e });
}
const aa = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  Button: Er,
  CaptionLabel: _r,
  Chevron: Fr,
  Day: Br,
  DayButton: Ir,
  Dropdown: Hr,
  DropdownNav: Ar,
  Footer: qr,
  Month: jr,
  MonthCaption: Gr,
  MonthGrid: $r,
  Months: Rr,
  MonthsDropdown: zr,
  Nav: Qr,
  NextMonthButton: Xr,
  Option: Vr,
  PreviousMonthButton: Zr,
  Root: Ur,
  Select: Jr,
  Week: Kr,
  WeekNumber: ea,
  WeekNumberHeader: ta,
  Weekday: Lr,
  Weekdays: pr,
  Weeks: na,
  YearsDropdown: ra
}, Symbol.toStringTag, { value: "Module" }));
function oa(e) {
  return {
    ...aa,
    ...e
  };
}
function sa(e) {
  const t = {
    "data-mode": e.mode ?? void 0,
    "data-required": "required" in e ? e.required : void 0,
    "data-multiple-months": e.numberOfMonths && e.numberOfMonths > 1 || void 0,
    "data-week-numbers": e.showWeekNumber || void 0,
    "data-broadcast-calendar": e.broadcastCalendar || void 0
  };
  return Object.entries(e).forEach(([n, r]) => {
    n.startsWith("data-") && (t[n] = r);
  }), t;
}
function ia() {
  const e = {};
  for (const t in w)
    e[w[t]] = `rdp-${w[t]}`;
  for (const t in x)
    e[x[t]] = `rdp-${x[t]}`;
  for (const t in R)
    e[R[t]] = `rdp-${R[t]}`;
  for (const t in q)
    e[q[t]] = `rdp-${q[t]}`;
  return e;
}
function lt(e, t, n) {
  return (n ?? new U(t)).format(e, "LLLL y");
}
const ca = lt;
function ua(e, t, n) {
  return (n ?? new U(t)).format(e, "d");
}
function fa(e, t = J) {
  return t.format(e, "LLLL");
}
function da(e) {
  return e < 10 ? `0${e.toLocaleString()}` : `${e.toLocaleString()}`;
}
function la() {
  return "";
}
function ha(e, t, n) {
  return (n ?? new U(t)).format(e, "cccccc");
}
function ht(e, t = J) {
  return t.format(e, "yyyy");
}
const ma = ht, ya = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  formatCaption: lt,
  formatDay: ua,
  formatMonthCaption: ca,
  formatMonthDropdown: fa,
  formatWeekNumber: da,
  formatWeekNumberHeader: la,
  formatWeekdayName: ha,
  formatYearCaption: ma,
  formatYearDropdown: ht
}, Symbol.toStringTag, { value: "Module" }));
function ga(e) {
  return e != null && e.formatMonthCaption && !e.formatCaption && (e.formatCaption = e.formatMonthCaption), e != null && e.formatYearCaption && !e.formatYearDropdown && (e.formatYearDropdown = e.formatYearCaption), {
    ...ya,
    ...e
  };
}
function wa(e, t, n, r, a) {
  const { startOfMonth: o, startOfYear: s, endOfYear: i, eachMonthOfInterval: c, getMonth: f } = a;
  return c({
    start: s(e),
    end: i(e)
  }).map((h) => {
    const g = r.formatMonthDropdown(h, a), M = f(h), D = t && h < o(t) || n && h > o(n) || !1;
    return { value: M, label: g, disabled: D };
  });
}
function Ma(e, t = {}, n = {}) {
  let r = { ...t == null ? void 0 : t[w.Day] };
  return Object.entries(e).filter(([, a]) => a === !0).forEach(([a]) => {
    r = {
      ...r,
      ...n == null ? void 0 : n[a]
    };
  }), r;
}
function ba(e, t, n) {
  const r = e.today(), a = n ? e.startOfBroadcastWeek(r, e) : t ? e.startOfISOWeek(r) : e.startOfWeek(r), o = [];
  for (let s = 0; s < 7; s++) {
    const i = e.addDays(a, s);
    o.push(i);
  }
  return o;
}
function Da(e, t, n, r) {
  if (!e || !t)
    return;
  const { startOfYear: a, endOfYear: o, addYears: s, getYear: i, isBefore: c, isSameYear: f } = r, l = a(e), d = o(t), h = [];
  let g = l;
  for (; c(g, d) || f(g, d); )
    h.push(g), g = s(g, 1);
  return h.map((M) => {
    const D = n.formatYearDropdown(M, r);
    return {
      value: i(M),
      label: D,
      disabled: !1
    };
  });
}
function mt(e, t, n) {
  return (n ?? new U(t)).format(e, "LLLL y");
}
const ka = mt;
function Oa(e, t, n, r) {
  let a = (r ?? new U(n)).format(e, "PPPP");
  return t != null && t.today && (a = `Today, ${a}`), a;
}
function yt(e, t, n, r) {
  let a = (r ?? new U(n)).format(e, "PPPP");
  return t.today && (a = `Today, ${a}`), t.selected && (a = `${a}, selected`), a;
}
const va = yt;
function Wa() {
  return "";
}
function Sa(e) {
  return "Choose the Month";
}
function Ca(e) {
  return "Go to the Next Month";
}
function Na(e) {
  return "Go to the Previous Month";
}
function Ya(e, t, n) {
  return (n ?? new U(t)).format(e, "cccc");
}
function Ta(e, t) {
  return `Week ${e}`;
}
function Pa(e) {
  return "Week Number";
}
function xa(e) {
  return "Choose the Year";
}
const Ea = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  labelCaption: ka,
  labelDay: va,
  labelDayButton: yt,
  labelGrid: mt,
  labelGridcell: Oa,
  labelMonthDropdown: Sa,
  labelNav: Wa,
  labelNext: Ca,
  labelPrevious: Na,
  labelWeekNumber: Ta,
  labelWeekNumberHeader: Pa,
  labelWeekday: Ya,
  labelYearDropdown: xa
}, Symbol.toStringTag, { value: "Module" }));
function _a(e, t, { classNames: n, months: r, focused: a, dateLib: o }) {
  const s = Oe(null), i = Oe(r), c = Oe(!1);
  tn(() => {
    const f = i.current;
    if (i.current = r, !t || !e.current || // safety check because the ref can be set to anything by consumers
    !(e.current instanceof HTMLElement))
      return;
    const l = s.current, d = e.current.cloneNode(!0);
    if (d instanceof HTMLElement ? ([
      ...d.querySelectorAll("[data-animated-month]") ?? []
    ].forEach((y) => {
      const b = y.querySelector("[data-animated-month]");
      b && y.contains(b) && y.removeChild(b);
    }), s.current = d) : s.current = null, r.length === 0 || f.length === 0 || r.length !== f.length || // skip animation if a day is focused because it can cause issues to the animation and is better for a11y
    a || c.current || o.isSameMonth(r[0].date, f[0].date))
      return;
    const g = [
      ...(l == null ? void 0 : l.querySelectorAll("[data-animated-month]")) ?? []
    ], M = [
      ...e.current.querySelectorAll("[data-animated-month]") ?? []
    ];
    if (M && M.every((D) => D instanceof HTMLElement) && g && g.every((D) => D instanceof HTMLElement)) {
      c.current = !0;
      const D = o.isAfter(r[0].date, f[0].date);
      M.forEach((y, b) => {
        const u = g[b];
        if (!u)
          return;
        const k = D ? n[q.caption_after_enter] : n[q.caption_before_enter], S = D ? n[q.weeks_after_enter] : n[q.weeks_before_enter];
        y.style.position = "relative", y.style.overflow = "hidden";
        const _ = y.querySelector("[data-animated-caption]");
        _ && _ instanceof HTMLElement && _.classList.add(k);
        const O = y.querySelector("[data-animated-weeks]");
        O && O instanceof HTMLElement && O.classList.add(S);
        const v = () => {
          c.current = !1, _ && _ instanceof HTMLElement && _.classList.remove(k), O && O instanceof HTMLElement && O.classList.remove(S), y.style.position = "", y.style.overflow = "", y.contains(u) && y.removeChild(u);
        };
        u.style.pointerEvents = "none", u.style.position = "absolute", u.style.overflow = "hidden", u.setAttribute("aria-hidden", "true");
        const T = u.querySelector("[data-animated-weekdays]");
        T && T instanceof HTMLElement && (T.style.opacity = "0");
        const H = u.querySelector("[data-animated-caption]");
        H && H instanceof HTMLElement && (H.classList.add(D ? n[q.caption_before_exit] : n[q.caption_after_exit]), H.addEventListener("animationend", v));
        const A = u.querySelector("[data-animated-weeks]");
        A && A instanceof HTMLElement && A.classList.add(D ? n[q.weeks_before_exit] : n[q.weeks_after_exit]), y.insertBefore(u, y.firstChild);
      });
    }
  });
}
function Fa(e, t, n, r) {
  const a = e[0], o = e[e.length - 1], { ISOWeek: s, fixedWeeks: i, broadcastCalendar: c } = n ?? {}, { addDays: f, differenceInCalendarDays: l, differenceInCalendarMonths: d, endOfBroadcastWeek: h, endOfISOWeek: g, endOfMonth: M, endOfWeek: D, isAfter: y, startOfBroadcastWeek: b, startOfISOWeek: u, startOfWeek: k } = r, S = c ? b(a, r) : s ? u(a) : k(a), _ = c ? h(o, r) : s ? g(M(o)) : D(M(o)), O = l(_, S), v = d(o, a) + 1, T = [];
  for (let j = 0; j <= O; j++) {
    const B = f(S, j);
    if (t && y(B, t))
      break;
    T.push(B);
  }
  const A = (c ? 35 : 42) * v;
  if (i && T.length < A) {
    const j = A - T.length;
    for (let B = 0; B < j; B++) {
      const p = f(T[T.length - 1], 1);
      T.push(p);
    }
  }
  return T;
}
function Ba(e) {
  const t = [];
  return e.reduce((n, r) => {
    const a = [], o = r.weeks.reduce((s, i) => [...s, ...i.days], a);
    return [...n, ...o];
  }, t);
}
function Ia(e, t, n, r) {
  const { numberOfMonths: a = 1 } = n, o = [];
  for (let s = 0; s < a; s++) {
    const i = r.addMonths(e, s);
    if (t && i > t)
      break;
    o.push(i);
  }
  return o;
}
function Ue(e, t) {
  const { month: n, defaultMonth: r, today: a = t.today(), numberOfMonths: o = 1, endMonth: s, startMonth: i } = e;
  let c = n || r || a;
  const { differenceInCalendarMonths: f, addMonths: l, startOfMonth: d } = t;
  if (s && f(s, c) < 0) {
    const h = -1 * (o - 1);
    c = l(s, h);
  }
  return i && f(c, i) < 0 && (c = i), d(c);
}
class gt {
  constructor(t, n, r = J) {
    this.date = t, this.displayMonth = n, this.outside = !!(n && !r.isSameMonth(t, n)), this.dateLib = r;
  }
  /**
   * Check if the day is the same as the given day: considering if it is in the
   * same display month.
   */
  isEqualTo(t) {
    return this.dateLib.isSameDay(t.date, this.date) && this.dateLib.isSameMonth(t.displayMonth, this.displayMonth);
  }
}
class Ha {
  constructor(t, n) {
    this.date = t, this.weeks = n;
  }
}
class Aa {
  constructor(t, n) {
    this.days = n, this.weekNumber = t;
  }
}
function qa(e, t, n, r) {
  const { addDays: a, endOfBroadcastWeek: o, endOfISOWeek: s, endOfMonth: i, endOfWeek: c, getISOWeek: f, getWeek: l, startOfBroadcastWeek: d, startOfISOWeek: h, startOfWeek: g } = r, M = e.reduce((D, y) => {
    const b = n.broadcastCalendar ? d(y, r) : n.ISOWeek ? h(y) : g(y), u = n.broadcastCalendar ? o(y, r) : n.ISOWeek ? s(i(y)) : c(i(y)), k = t.filter((v) => v >= b && v <= u), S = n.broadcastCalendar ? 35 : 42;
    if (n.fixedWeeks && k.length < S) {
      const v = t.filter((T) => {
        const H = S - k.length;
        return T > u && T <= a(u, H);
      });
      k.push(...v);
    }
    const _ = k.reduce((v, T) => {
      const H = n.ISOWeek ? f(T) : l(T), A = v.find((B) => B.weekNumber === H), j = new gt(T, y, r);
      return A ? A.days.push(j) : v.push(new Aa(H, [j])), v;
    }, []), O = new Ha(y, _);
    return D.push(O), D;
  }, []);
  return n.reverseMonths ? M.reverse() : M;
}
function ja(e, t) {
  let { startMonth: n, endMonth: r } = e;
  const { startOfYear: a, startOfDay: o, startOfMonth: s, endOfMonth: i, addYears: c, endOfYear: f, newDate: l, today: d } = t, { fromYear: h, toYear: g, fromMonth: M, toMonth: D } = e;
  !n && M && (n = M), !n && h && (n = t.newDate(h, 0, 1)), !r && D && (r = D), !r && g && (r = l(g, 11, 31));
  const y = e.captionLayout === "dropdown" || e.captionLayout === "dropdown-years";
  return n ? n = s(n) : h ? n = l(h, 0, 1) : !n && y && (n = a(c(e.today ?? d(), -100))), r ? r = i(r) : g ? r = l(g, 11, 31) : !r && y && (r = f(e.today ?? d())), [
    n && o(n),
    r && o(r)
  ];
}
function Ga(e, t, n, r) {
  if (n.disableNavigation)
    return;
  const { pagedNavigation: a, numberOfMonths: o = 1 } = n, { startOfMonth: s, addMonths: i, differenceInCalendarMonths: c } = r, f = a ? o : 1, l = s(e);
  if (!t)
    return i(l, f);
  if (!(c(t, e) < o))
    return i(l, f);
}
function $a(e, t, n, r) {
  if (n.disableNavigation)
    return;
  const { pagedNavigation: a, numberOfMonths: o } = n, { startOfMonth: s, addMonths: i, differenceInCalendarMonths: c } = r, f = a ? o ?? 1 : 1, l = s(e);
  if (!t)
    return i(l, -f);
  if (!(c(l, t) <= 0))
    return i(l, -f);
}
function Ra(e) {
  const t = [];
  return e.reduce((n, r) => [...n, ...r.weeks], t);
}
function ve(e, t) {
  const [n, r] = xe(e);
  return [t === void 0 ? n : t, r];
}
function za(e, t) {
  const [n, r] = ja(e, t), { startOfMonth: a, endOfMonth: o } = t, s = Ue(e, t), [i, c] = ve(
    s,
    // initialMonth is always computed from props.month if provided
    e.month ? s : void 0
  );
  nn(() => {
    const O = Ue(e, t);
    c(O);
  }, [e.timeZone]);
  const f = Ia(i, r, e, t), l = Fa(f, e.endMonth ? o(e.endMonth) : void 0, e, t), d = qa(f, l, e, t), h = Ra(d), g = Ba(d), M = $a(i, n, e, t), D = Ga(i, r, e, t), { disableNavigation: y, onMonthChange: b } = e, u = (O) => h.some((v) => v.days.some((T) => T.isEqualTo(O))), k = (O) => {
    if (y)
      return;
    let v = a(O);
    n && v < a(n) && (v = a(n)), r && v > a(r) && (v = a(r)), c(v), b == null || b(v);
  };
  return {
    months: d,
    weeks: h,
    days: g,
    navStart: n,
    navEnd: r,
    previousMonth: M,
    nextMonth: D,
    goToMonth: k,
    goToDay: (O) => {
      u(O) || k(O.date);
    }
  };
}
function Qa(e, t, n, r) {
  let a, o = 0, s = !1;
  for (; o < e.length && !s; ) {
    const i = e[o], c = t(i);
    !c[x.disabled] && !c[x.hidden] && !c[x.outside] && (c[x.focused] || r != null && r.isEqualTo(i) || n(i.date) || c[x.today]) && (a = i, s = !0), o++;
  }
  return a || (a = e.find((i) => {
    const c = t(i);
    return !c[x.disabled] && !c[x.hidden] && !c[x.outside];
  })), a;
}
function V(e, t, n = !1, r = J) {
  let { from: a, to: o } = e;
  const { differenceInCalendarDays: s, isSameDay: i } = r;
  return a && o ? (s(o, a) < 0 && ([a, o] = [o, a]), s(t, a) >= (n ? 1 : 0) && s(o, t) >= (n ? 1 : 0)) : !n && o ? i(o, t) : !n && a ? i(a, t) : !1;
}
function wt(e) {
  return !!(e && typeof e == "object" && "before" in e && "after" in e);
}
function Fe(e) {
  return !!(e && typeof e == "object" && "from" in e);
}
function Mt(e) {
  return !!(e && typeof e == "object" && "after" in e);
}
function bt(e) {
  return !!(e && typeof e == "object" && "before" in e);
}
function Dt(e) {
  return !!(e && typeof e == "object" && "dayOfWeek" in e);
}
function kt(e, t) {
  return Array.isArray(e) && e.every(t.isDate);
}
function Z(e, t, n = J) {
  const r = Array.isArray(t) ? t : [t], { isSameDay: a, differenceInCalendarDays: o, isAfter: s } = n;
  return r.some((i) => {
    if (typeof i == "boolean")
      return i;
    if (n.isDate(i))
      return a(e, i);
    if (kt(i, n))
      return i.includes(e);
    if (Fe(i))
      return V(i, e, !1, n);
    if (Dt(i))
      return Array.isArray(i.dayOfWeek) ? i.dayOfWeek.includes(e.getDay()) : i.dayOfWeek === e.getDay();
    if (wt(i)) {
      const c = o(i.before, e), f = o(i.after, e), l = c > 0, d = f < 0;
      return s(i.before, i.after) ? d && l : l || d;
    }
    return Mt(i) ? o(e, i.after) > 0 : bt(i) ? o(i.before, e) > 0 : typeof i == "function" ? i(e) : !1;
  });
}
function Xa(e, t, n, r, a, o, s) {
  const { ISOWeek: i, broadcastCalendar: c } = o, { addDays: f, addMonths: l, addWeeks: d, addYears: h, endOfBroadcastWeek: g, endOfISOWeek: M, endOfWeek: D, max: y, min: b, startOfBroadcastWeek: u, startOfISOWeek: k, startOfWeek: S } = s;
  let O = {
    day: f,
    week: d,
    month: l,
    year: h,
    startOfWeek: (v) => c ? u(v, s) : i ? k(v) : S(v),
    endOfWeek: (v) => c ? g(v, s) : i ? M(v) : D(v)
  }[e](n, t === "after" ? 1 : -1);
  return t === "before" && r ? O = y([r, O]) : t === "after" && a && (O = b([a, O])), O;
}
function Ot(e, t, n, r, a, o, s, i = 0) {
  if (i > 365)
    return;
  const c = Xa(
    e,
    t,
    n.date,
    // should be refDay? or refDay.date?
    r,
    a,
    o,
    s
  ), f = !!(o.disabled && Z(c, o.disabled, s)), l = !!(o.hidden && Z(c, o.hidden, s)), d = c, h = new gt(c, d, s);
  return !f && !l ? h : Ot(e, t, h, r, a, o, s, i + 1);
}
function Va(e, t, n, r, a) {
  const { autoFocus: o } = e, [s, i] = xe(), c = Qa(t.days, n, r || (() => !1), s), [f, l] = xe(o ? c : void 0);
  return {
    isFocusTarget: (D) => !!(c != null && c.isEqualTo(D)),
    setFocused: l,
    focused: f,
    blur: () => {
      i(f), l(void 0);
    },
    moveFocus: (D, y) => {
      if (!f)
        return;
      const b = Ot(D, y, f, t.navStart, t.navEnd, e, a);
      b && (t.goToDay(b), l(b));
    }
  };
}
function Za(e, t, n) {
  const { disabled: r, hidden: a, modifiers: o, showOutsideDays: s, broadcastCalendar: i, today: c } = t, { isSameDay: f, isSameMonth: l, startOfMonth: d, isBefore: h, endOfMonth: g, isAfter: M } = n, D = t.startMonth && d(t.startMonth), y = t.endMonth && g(t.endMonth), b = {
    [x.focused]: [],
    [x.outside]: [],
    [x.disabled]: [],
    [x.hidden]: [],
    [x.today]: []
  }, u = {};
  for (const k of e) {
    const { date: S, displayMonth: _ } = k, O = !!(_ && !l(S, _)), v = !!(D && h(S, D)), T = !!(y && M(S, y)), H = !!(r && Z(S, r, n)), A = !!(a && Z(S, a, n)) || v || T || // Broadcast calendar will show outside days as default
    !i && !s && O || i && s === !1 && O, j = f(S, c ?? n.today());
    O && b.outside.push(k), H && b.disabled.push(k), A && b.hidden.push(k), j && b.today.push(k), o && Object.keys(o).forEach((B) => {
      const p = o == null ? void 0 : o[B];
      p && Z(S, p, n) && (u[B] ? u[B].push(k) : u[B] = [k]);
    });
  }
  return (k) => {
    const S = {
      [x.focused]: !1,
      [x.disabled]: !1,
      [x.hidden]: !1,
      [x.outside]: !1,
      [x.today]: !1
    }, _ = {};
    for (const O in b) {
      const v = b[O];
      S[O] = v.some((T) => T === k);
    }
    for (const O in u)
      _[O] = u[O].some((v) => v === k);
    return {
      ...S,
      // custom modifiers should override all the previous ones
      ..._
    };
  };
}
function Ua(e, t) {
  const { selected: n, required: r, onSelect: a } = e, [o, s] = ve(n, a ? n : void 0), i = a ? n : o, { isSameDay: c } = t, f = (g) => (i == null ? void 0 : i.some((M) => c(M, g))) ?? !1, { min: l, max: d } = e;
  return {
    selected: i,
    select: (g, M, D) => {
      let y = [...i ?? []];
      if (f(g)) {
        if ((i == null ? void 0 : i.length) === l || r && (i == null ? void 0 : i.length) === 1)
          return;
        y = i == null ? void 0 : i.filter((b) => !c(b, g));
      } else
        (i == null ? void 0 : i.length) === d ? y = [g] : y = [...y, g];
      return a || s(y), a == null || a(y, g, M, D), y;
    },
    isSelected: f
  };
}
function Ja(e, t, n = 0, r = 0, a = !1, o = J) {
  const { from: s, to: i } = t || {}, { isSameDay: c, isAfter: f, isBefore: l } = o;
  let d;
  if (!s && !i)
    d = { from: e, to: n > 0 ? void 0 : e };
  else if (s && !i)
    c(s, e) ? a ? d = { from: s, to: void 0 } : d = void 0 : l(e, s) ? d = { from: e, to: s } : d = { from: s, to: e };
  else if (s && i)
    if (c(s, e) && c(i, e))
      a ? d = { from: s, to: i } : d = void 0;
    else if (c(s, e))
      d = { from: s, to: n > 0 ? void 0 : e };
    else if (c(i, e))
      d = { from: e, to: n > 0 ? void 0 : e };
    else if (l(e, s))
      d = { from: e, to: i };
    else if (f(e, s))
      d = { from: s, to: e };
    else if (f(e, i))
      d = { from: s, to: e };
    else
      throw new Error("Invalid range");
  if (d != null && d.from && (d != null && d.to)) {
    const h = o.differenceInCalendarDays(d.to, d.from);
    r > 0 && h > r ? d = { from: e, to: void 0 } : n > 1 && h < n && (d = { from: e, to: void 0 });
  }
  return d;
}
function Ka(e, t, n = J) {
  const r = Array.isArray(t) ? t : [t];
  let a = e.from;
  const o = n.differenceInCalendarDays(e.to, e.from), s = Math.min(o, 6);
  for (let i = 0; i <= s; i++) {
    if (r.includes(a.getDay()))
      return !0;
    a = n.addDays(a, 1);
  }
  return !1;
}
function Je(e, t, n = J) {
  return V(e, t.from, !1, n) || V(e, t.to, !1, n) || V(t, e.from, !1, n) || V(t, e.to, !1, n);
}
function La(e, t, n = J) {
  const r = Array.isArray(t) ? t : [t];
  if (r.filter((i) => typeof i != "function").some((i) => typeof i == "boolean" ? i : n.isDate(i) ? V(e, i, !1, n) : kt(i, n) ? i.some((c) => V(e, c, !1, n)) : Fe(i) ? i.from && i.to ? Je(e, { from: i.from, to: i.to }, n) : !1 : Dt(i) ? Ka(e, i.dayOfWeek, n) : wt(i) ? n.isAfter(i.before, i.after) ? Je(e, {
    from: n.addDays(i.after, 1),
    to: n.addDays(i.before, -1)
  }, n) : Z(e.from, i, n) || Z(e.to, i, n) : Mt(i) || bt(i) ? Z(e.from, i, n) || Z(e.to, i, n) : !1))
    return !0;
  const s = r.filter((i) => typeof i == "function");
  if (s.length) {
    let i = e.from;
    const c = n.differenceInCalendarDays(e.to, e.from);
    for (let f = 0; f <= c; f++) {
      if (s.some((l) => l(i)))
        return !0;
      i = n.addDays(i, 1);
    }
  }
  return !1;
}
function pa(e, t) {
  const { disabled: n, excludeDisabled: r, selected: a, required: o, onSelect: s } = e, [i, c] = ve(a, s ? a : void 0), f = s ? a : i;
  return {
    selected: f,
    select: (h, g, M) => {
      const { min: D, max: y } = e, b = h ? Ja(h, f, D, y, o, t) : void 0;
      return r && n && (b != null && b.from) && b.to && La({ from: b.from, to: b.to }, n, t) && (b.from = h, b.to = void 0), s || c(b), s == null || s(b, h, g, M), b;
    },
    isSelected: (h) => f && V(f, h, !1, t)
  };
}
function eo(e, t) {
  const { selected: n, required: r, onSelect: a } = e, [o, s] = ve(n, a ? n : void 0), i = a ? n : o, { isSameDay: c } = t;
  return {
    selected: i,
    select: (d, h, g) => {
      let M = d;
      return !r && i && i && c(d, i) && (M = void 0), a || s(M), a == null || a(M, d, h, g), M;
    },
    isSelected: (d) => i ? c(i, d) : !1
  };
}
function to(e, t) {
  const n = eo(e, t), r = Ua(e, t), a = pa(e, t);
  switch (e.mode) {
    case "single":
      return n;
    case "multiple":
      return r;
    case "range":
      return a;
    default:
      return;
  }
}
function no(e) {
  const { components: t, formatters: n, labels: r, dateLib: a, locale: o, classNames: s } = Ye(() => {
    const W = { ..._e, ...e.locale };
    return {
      dateLib: new U({
        locale: W,
        weekStartsOn: e.broadcastCalendar ? 1 : e.weekStartsOn,
        firstWeekContainsDate: e.firstWeekContainsDate,
        useAdditionalWeekYearTokens: e.useAdditionalWeekYearTokens,
        useAdditionalDayOfYearTokens: e.useAdditionalDayOfYearTokens,
        timeZone: e.timeZone,
        numerals: e.numerals
      }, e.dateLib),
      components: oa(e.components),
      formatters: ga(e.formatters),
      labels: { ...Ea, ...e.labels },
      locale: W,
      classNames: { ...ia(), ...e.classNames }
    };
  }, [
    e.locale,
    e.broadcastCalendar,
    e.weekStartsOn,
    e.firstWeekContainsDate,
    e.useAdditionalWeekYearTokens,
    e.useAdditionalDayOfYearTokens,
    e.timeZone,
    e.numerals,
    e.dateLib,
    e.components,
    e.formatters,
    e.labels,
    e.classNames
  ]), { captionLayout: i, mode: c, onDayBlur: f, onDayClick: l, onDayFocus: d, onDayKeyDown: h, onDayMouseEnter: g, onDayMouseLeave: M, onNextClick: D, onPrevClick: y, showWeekNumber: b, styles: u } = e, { formatCaption: k, formatDay: S, formatMonthDropdown: _, formatWeekNumber: O, formatWeekNumberHeader: v, formatWeekdayName: T, formatYearDropdown: H } = n, A = za(e, a), { days: j, months: B, navStart: p, navEnd: We, previousMonth: ne, nextMonth: re, goToMonth: X } = A, Se = Za(j, e, a), { isSelected: ce, select: ue, selected: Me } = to(e, a) ?? {}, { blur: Be, focused: be, isFocusTarget: vt, moveFocus: Ie, setFocused: De } = Va(e, A, Se, ce ?? (() => !1), a), { labelDayButton: Wt, labelGridcell: St, labelGrid: Ct, labelMonthDropdown: Nt, labelNav: Yt, labelWeekday: Tt, labelWeekNumber: Pt, labelWeekNumberHeader: xt, labelYearDropdown: Et } = r, _t = Ye(() => ba(a, e.ISOWeek), [a, e.ISOWeek]), He = c !== void 0 || l !== void 0, Ft = G(() => {
    ne && (X(ne), y == null || y(ne));
  }, [ne, X, y]), Bt = G(() => {
    re && (X(re), D == null || D(re));
  }, [X, re, D]), It = G((W, E) => (P) => {
    P.preventDefault(), P.stopPropagation(), De(W), ue == null || ue(W.date, E, P), l == null || l(W.date, E, P);
  }, [ue, l, De]), Ht = G((W, E) => (P) => {
    De(W), d == null || d(W.date, E, P);
  }, [d, De]), At = G((W, E) => (P) => {
    Be(), f == null || f(W.date, E, P);
  }, [Be, f]), qt = G((W, E) => (P) => {
    const K = {
      ArrowLeft: ["day", e.dir === "rtl" ? "after" : "before"],
      ArrowRight: ["day", e.dir === "rtl" ? "before" : "after"],
      ArrowDown: ["week", "after"],
      ArrowUp: ["week", "before"],
      PageUp: [P.shiftKey ? "year" : "month", "before"],
      PageDown: [P.shiftKey ? "year" : "month", "after"],
      Home: ["startOfWeek", "before"],
      End: ["endOfWeek", "after"]
    };
    if (K[P.key]) {
      P.preventDefault(), P.stopPropagation();
      const [$, ke] = K[P.key];
      Ie($, ke);
    }
    h == null || h(W.date, E, P);
  }, [Ie, h, e.dir]), jt = G((W, E) => (P) => {
    g == null || g(W.date, E, P);
  }, [g]), Gt = G((W, E) => (P) => {
    M == null || M(W.date, E, P);
  }, [M]), $t = G((W) => (E) => {
    const P = Number(E.target.value), K = a.setMonth(a.startOfMonth(W), P);
    X(K);
  }, [a, X]), Rt = G((W) => (E) => {
    const P = Number(E.target.value), K = a.setYear(a.startOfMonth(W), P);
    X(K);
  }, [a, X]), { className: zt, style: Qt } = Ye(() => ({
    className: [s[w.Root], e.className].filter(Boolean).join(" "),
    style: { ...u == null ? void 0 : u[w.Root], ...e.style }
  }), [s, e.className, e.style, u]), Xt = sa(e), Ae = Oe(null);
  _a(Ae, !!e.animate, {
    classNames: s,
    months: B,
    focused: be,
    dateLib: a
  });
  const Vt = {
    dayPickerProps: e,
    selected: Me,
    select: ue,
    isSelected: ce,
    months: B,
    nextMonth: re,
    previousMonth: ne,
    goToMonth: X,
    getModifiers: Se,
    components: t,
    classNames: s,
    styles: u,
    labels: r,
    formatters: n
  };
  return m.createElement(
    dt.Provider,
    { value: Vt },
    m.createElement(
      t.Root,
      { rootRef: e.animate ? Ae : void 0, className: zt, style: Qt, dir: e.dir, id: e.id, lang: e.lang, nonce: e.nonce, title: e.title, role: e.role, "aria-label": e["aria-label"], ...Xt },
      m.createElement(
        t.Months,
        { className: s[w.Months], style: u == null ? void 0 : u[w.Months] },
        !e.hideNavigation && m.createElement(t.Nav, { className: s[w.Nav], style: u == null ? void 0 : u[w.Nav], "aria-label": Yt(), onPreviousClick: Ft, onNextClick: Bt, previousMonth: ne, nextMonth: re }),
        B.map((W, E) => {
          const P = wa(W.date, p, We, n, a), K = Da(p, We, n, a);
          return m.createElement(
            t.Month,
            { "data-animated-month": e.animate ? "true" : void 0, className: s[w.Month], style: u == null ? void 0 : u[w.Month], key: E, displayIndex: E, calendarMonth: W },
            m.createElement(t.MonthCaption, { "data-animated-caption": e.animate ? "true" : void 0, className: s[w.MonthCaption], style: u == null ? void 0 : u[w.MonthCaption], calendarMonth: W, displayIndex: E }, i != null && i.startsWith("dropdown") ? m.createElement(
              t.DropdownNav,
              { className: s[w.Dropdowns], style: u == null ? void 0 : u[w.Dropdowns] },
              i === "dropdown" || i === "dropdown-months" ? m.createElement(t.MonthsDropdown, { className: s[w.MonthsDropdown], "aria-label": Nt(), classNames: s, components: t, disabled: !!e.disableNavigation, onChange: $t(W.date), options: P, style: u == null ? void 0 : u[w.Dropdown], value: a.getMonth(W.date) }) : m.createElement("span", { role: "status", "aria-live": "polite" }, _(W.date, a)),
              i === "dropdown" || i === "dropdown-years" ? m.createElement(t.YearsDropdown, { className: s[w.YearsDropdown], "aria-label": Et(a.options), classNames: s, components: t, disabled: !!e.disableNavigation, onChange: Rt(W.date), options: K, style: u == null ? void 0 : u[w.Dropdown], value: a.getYear(W.date) }) : m.createElement("span", { role: "status", "aria-live": "polite" }, H(W.date, a))
            ) : m.createElement(t.CaptionLabel, { className: s[w.CaptionLabel], role: "status", "aria-live": "polite" }, k(W.date, a.options, a))),
            m.createElement(
              t.MonthGrid,
              { role: "grid", "aria-multiselectable": c === "multiple" || c === "range", "aria-label": Ct(W.date, a.options, a) || void 0, className: s[w.MonthGrid], style: u == null ? void 0 : u[w.MonthGrid] },
              !e.hideWeekdays && m.createElement(
                t.Weekdays,
                { "data-animated-weekdays": e.animate ? "true" : void 0, className: s[w.Weekdays], style: u == null ? void 0 : u[w.Weekdays] },
                b && m.createElement(t.WeekNumberHeader, { "aria-label": xt(a.options), className: s[w.WeekNumberHeader], style: u == null ? void 0 : u[w.WeekNumberHeader], scope: "col" }, v()),
                _t.map(($, ke) => m.createElement(t.Weekday, { "aria-label": Tt($, a.options, a), className: s[w.Weekday], key: ke, style: u == null ? void 0 : u[w.Weekday], scope: "col" }, T($, a.options, a)))
              ),
              m.createElement(t.Weeks, { "data-animated-weeks": e.animate ? "true" : void 0, className: s[w.Weeks], style: u == null ? void 0 : u[w.Weeks] }, W.weeks.map(($, ke) => m.createElement(
                t.Week,
                { className: s[w.Week], key: $.weekNumber, style: u == null ? void 0 : u[w.Week], week: $ },
                b && m.createElement(t.WeekNumber, { week: $, style: u == null ? void 0 : u[w.WeekNumber], "aria-label": Pt($.weekNumber, {
                  locale: o
                }), className: s[w.WeekNumber], scope: "row", role: "rowheader" }, O($.weekNumber)),
                $.days.map((I) => {
                  const { date: z } = I, C = Se(I);
                  if (C[x.focused] = !C.hidden && !!(be != null && be.isEqualTo(I)), C[R.selected] = (ce == null ? void 0 : ce(z)) || C.selected, Fe(Me)) {
                    const { from: Ce, to: Ne } = Me;
                    C[R.range_start] = !!(Ce && Ne && a.isSameDay(z, Ce)), C[R.range_end] = !!(Ce && Ne && a.isSameDay(z, Ne)), C[R.range_middle] = V(Me, z, !0, a);
                  }
                  const Zt = Ma(C, u, e.modifiersStyles), Ut = xr(C, s, e.modifiersClassNames), Jt = !He && !C.hidden ? St(z, C, a.options, a) : void 0;
                  return m.createElement(t.Day, { key: `${a.format(z, "yyyy-MM-dd")}_${a.format(I.displayMonth, "yyyy-MM")}`, day: I, modifiers: C, className: Ut.join(" "), style: Zt, role: "gridcell", "aria-selected": C.selected || void 0, "aria-label": Jt, "data-day": a.format(z, "yyyy-MM-dd"), "data-month": I.outside ? a.format(z, "yyyy-MM") : void 0, "data-selected": C.selected || void 0, "data-disabled": C.disabled || void 0, "data-hidden": C.hidden || void 0, "data-outside": I.outside || void 0, "data-focused": C.focused || void 0, "data-today": C.today || void 0 }, !C.hidden && He ? m.createElement(t.DayButton, { className: s[w.DayButton], style: u == null ? void 0 : u[w.DayButton], type: "button", day: I, modifiers: C, disabled: C.disabled || void 0, tabIndex: vt(I) ? 0 : -1, "aria-label": Wt(z, C, a.options, a), onClick: It(I, C), onBlur: At(I, C), onFocus: Ht(I, C), onKeyDown: qt(I, C), onMouseEnter: jt(I, C), onMouseLeave: Gt(I, C) }, S(z, a.options, a)) : !C.hidden && S(I.date, a.options, a));
                })
              )))
            )
          );
        })
      ),
      e.footer && m.createElement(t.Footer, { className: s[w.Footer], style: u == null ? void 0 : u[w.Footer], role: "status", "aria-live": "polite" }, e.footer)
    )
  );
}
const co = ({
  selectedDate: e,
  onDateChange: t,
  month: n,
  year: r,
  minTime: a,
  maxTime: o,
  startDate: s,
  endDate: i,
  dateType: c
}) => /* @__PURE__ */ qe(Kt, { sx: Lt.calenderContainer, children: /* @__PURE__ */ qe(
  no,
  {
    mode: "single",
    month: new Date(parseInt(r), parseInt(n)),
    style: { width: "100%" },
    showWeekNumber: !1,
    hideNavigation: !0,
    className: "date-time-selector-calender",
    selected: new Date(e * 1e3),
    onDayClick: (f) => {
      t(ae(f, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").unix());
    },
    disabled: {
      before: (() => {
        if (c === "end" && s)
          return ae.unix(s).toDate();
        if (a && a > 0)
          return ae.unix(a).toDate();
      })(),
      after: (() => c === "start" && i ? ae.unix(i).toDate() : o && o !== 1 / 0 ? ae.unix(o).toDate() : ae().endOf("day").toDate())()
    },
    styles: {
      week: {
        backgroundColor: "transparent"
      }
    }
  }
) });
export {
  co as DatePickerComponent
};
