/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { UserInfo, Permission, USER_PERMISSION, PERMISSION_OBJECT } from "~/types/user";

export type PermissionsMap = { [key in PERMISSION_OBJECT]?: Partial<Record<USER_PERMISSION, boolean>> }
export interface AuthState {
  user: UserInfo | null;
  token: any;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: any;
  currentRoute: string | null;
  permissions: PermissionsMap;
  permissionsLoading: boolean;
}

const initialState: AuthState = {
  user: null,
  token: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,
  currentRoute: null,
  permissions: {},
  permissionsLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoading = true;
    },
    setUser: (state, action : PayloadAction<UserInfo>) => {
      state.user = action.payload;
      if (action.payload) {
        state.isAuthenticated = true;
        state.isLoading = false;
      }
    },
    setCurrentRoute: (state, action: PayloadAction<string>) => {
      state.currentRoute = action.payload;
    },
    setUserPermissions(state, action: PayloadAction<Permission[]>) {
      const permissionsMap: PermissionsMap = {};
      action.payload?.forEach((permission) => {
        if(!permissionsMap[permission.objectType]){
          permissionsMap[permission.objectType] = {};
        }
        permission.permissions.forEach((permissionType) => {
          if(permissionsMap[permission.objectType]){
            permissionsMap[permission.objectType]![permissionType] = true;
          }
        });
      });
      state.permissions = permissionsMap;
    },
    setUserPermissionsLoading(state, action: PayloadAction<boolean>) {
      state.permissionsLoading = action.payload;
    },
  },
});

export const { login, setUser, setCurrentRoute, setUserPermissions, setUserPermissionsLoading } = authSlice.actions;

export default authSlice.reducer;

export const authUser = (state: RootState) => state.auth.user;
export const authToken = (state: RootState) => state.auth.token;
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectIsLoading = (state: RootState) => state.auth.isLoading;
export const selectUserPermssions = (state: RootState) => state.auth.permissions;
export const selectUserPermssionsLoading = (state: RootState) => state.auth.permissionsLoading;
