/**
 * Copyright Highway9 Networks Inc.
 */
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { authenticationService } from "../services";
import useErrorsAndWarnings from "../hooks/useErrorsAndWarnings";
import ErrorsComponent from "../components/ErrorsComponent";
import { LoginView } from "@h9/ui-lib";
import { useAppDispatch } from "~/store/hooks";
import { fetchSystemSettings } from "~/store/context/settingsSlice";
import { IT_DASHBOARD, ONBOARDING } from "~/constants/routes";
import { DEFAULT_LANDING_PAGE_ENUM, System } from "~/types/systemSetting";
export function LoginPage() {
  const dispatch = useAppDispatch();
  const { errors, warnings, setErrorsAndWarnings, closeAlert, closeAll } = useErrorsAndWarnings();
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  async function handleSubmit(email: string, password: string) {
    setLoadingButton(true);
    try {
      const sessionInfo = await authenticationService.getSessionInfo(email.trim());
      if (!sessionInfo) {
        return;
      }
      await authenticationService.login(email.trim(), password.trim(), sessionInfo.authKey);
      console.log("Logged in.");

      setIsLoggedIn(true);
    } catch (error : any) {
      console.error(error);
      setErrorsAndWarnings(error.errors, error.warnings);
    } finally {
      setLoadingButton(false);
    }
  }

  async function handleNext(email: string) {
    setLoadingButton(true);
    try {
      const sessionInfo = await authenticationService.getSessionInfo(email);
      if (sessionInfo?.authType === "SAML") {
        window.location.href = sessionInfo.redirectUrl;
        return;
      }
      if (sessionInfo?.authType === "PASSWORD") {
        setHidePassword(false);
      }
    } catch (error) {
      console.log("error, no SAML authType found", error);
      setHidePassword(false);
    } finally {
      setLoadingButton(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      authenticationService
        .getSession()
        .then((response) => {
          if (response.status === 200) {
            const url = sessionStorage.getItem("url") || "/";
            console.log("url: ", url);
            window.location.replace(url);
            //window.location.reload();
          }
        })
        .catch((error) => {
          setLoading(false);
          setErrorsAndWarnings(error.errors, error.warnings);
        });
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const url = sessionStorage.getItem("url");
      if (url) {
        // If there is a url in session storage, then the user wanted to go to a specific page but was redirected to the login page.
        // Hence redirect to that page instead of the default landing page from system settings
        window.location.replace(url);
        return;
      }

      // If there is no url in session storage, then redirect to the default landing page
      dispatch(fetchSystemSettings())
        .then(response => {
          let url = '/';
          if (response.meta.requestStatus === 'fulfilled') {
            const systemSettings = response.payload as System[];
            const defaultLandingPage: DEFAULT_LANDING_PAGE_ENUM = systemSettings.find((item) => item.name === "default.landing.page")?.value;
            switch (defaultLandingPage) {
              case 'Monitor':
                url = IT_DASHBOARD
                break;
              case 'Onboarding':
                const isOnboardingEnabled = systemSettings.some((item) => item.name === "dashboard.getting.started.enabled" && item.value)
                url = isOnboardingEnabled ? ONBOARDING : '/';
                break;
              default:
                url = '/'
            }
          }
          window.location.replace(url);
        })
        .catch(error => {
          console.error(error)
          // Though the system settings API failed, the login was still successful
          // Hence redirect to '/' path
          window.location.replace('/')
        })
    }
  }, [isLoggedIn, dispatch]);

  if (loading) {
    return (
      <div style={{ height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress size={80} />
      </div>
    );
  }

  function handleForgotPassword() {
    const host = window.location.host;
    const protocol = window.location.protocol;
    const urlHostFragments = host.split(".");
    let redirectUrl = "";
    if (urlHostFragments.length <= 2) {
      // We only have top level domain name e.g. ["higway9networks", "com"]
      redirectUrl = `console.${host}/login`;
    } else {
      urlHostFragments[0] = "console";
      redirectUrl = `${urlHostFragments.join(".")}/login`;
    }

    window.open(`${protocol}//${redirectUrl}`);
  }

  return (
    <LoginView
      errorsComponent={
        <ErrorsComponent errorsData={errors} warningsData={warnings} closeAll={closeAll} closeAlert={closeAlert} />
      }
      hidePassword={hidePassword}
      loading={loadingButton}
      onLogin={handleSubmit}
      onNext={handleNext}
      onForgotPassword={handleForgotPassword}
    />
  );
}
