/** 
 Copyright Highway9 Networks Inc. 
 */ 
import moment from "moment";
import { authenticationService } from "./authentication-service";
import { groupBy } from "~/helpers/utils";
import APIService from "./APIServices";
import { Input, OperationDetails } from "~/types/operations";

const operationAPI = new APIService<OperationDetails>("operations");

export const operationService = {
  createOperation: operationAPI.create,
  deleteOperation: operationAPI.delete,
  GetOperationById,
  GenerateTechBundle,
  GenerateBulkDownload,
  GeneratePcap,
  DownloadFile,
  GetBundleStatus,
  GetPcapStatus,
  GetExportStatus,
  ExportRadio,
};

async function GetOperationById(id: string) {
  try {
    return await operationAPI.get(id);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

/**
 * @example
 * DownloadFile("https://www.example.com/file.pdf")
 */
export async function DownloadFile(link: string) {
  const _path = new URL(link).pathname;
  const url = location.origin + _path;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
      Authorization: "Bearer " + authenticationService.currentUserValue?.token,
    },
  };
  try {
    const response = await fetch(url, requestOptions);

    const filenameHeader = response.headers.get("content-disposition") ?? "";
    const subStrings = filenameHeader.split("=");
    const filename = subStrings[1].trim().replace(/"/g, "");

    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();

    return true;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

type OQuery = {
  objectType: string;
  objectId?: string;
  operationType: string;
  startTime?: number;
  endTime?: number;
};

async function getAllOperations(query: OQuery) {
  try {
    const operations = (await operationAPI.post("query", query)) as unknown as OperationDetails[];
    return operations;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

/**
 * Generate a tech support bundle for the given edge ID
  @example
  GenerateTechBundle("edge-1", {
    systemInfo: true,
    logFiles: true,
    coreDumps: true,
  }
 */
async function GenerateTechBundle(
  edgeID: string,
  params: {
    systemInfo: string;
    logFiles: string;
    coreDumps: string;
    packetCapture: string;
    startTime: string;
    endTime: string;
  }
) {
  const requestBody = {
    name: "Tech_bundle_" + Date.now(),
    type: "operation",
    operationType: "GENERATE_TECH_SUPPORT_BUNDLE",
    objectId: edgeID,
    objectType: "edge",
    input: [
      { key: "systemInfo", value: params.systemInfo },
      { key: "logFiles", value: params.logFiles },
      { key: "coreDumps", value: params.coreDumps },
      { key: "startTime", value: params.startTime },
      {key: "packetCapture", value: params.packetCapture},
      { key: "endTime", value: params.endTime },
    ],
  };

  try {
    const data = await operationService.createOperation(requestBody);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

function GeneratePcap(
  name: string,
  edgeID: string,
  params?: {
    capture5GControlPlanePackets?: boolean;
    capture5GDataPlanePackets?: boolean;
    filter: string;
    dataPlaneFilter?: string;
    fileSize: string;
    timeout: string;
    filename: string;
    edgeInterface: string;
    interfaceCount?: string;
    controlPlaneIp?: string;
  }
) {
  const requestBody: OperationDetails = {
    name: name,
    type: "operation",
    operationType: name,
    objectId: edgeID,
    objectType: "edge",
  };

  if (params) {
    requestBody.input = [
      { key: "filter", value: params.filter },
      { key: "fileSize", value: params.fileSize },
      { key: "timeout", value: params.timeout },
      { key: "filename", value: params.filename },
      { key: "interface", value: params.edgeInterface },
    ];
    if ("capture5GControlPlanePackets" in params || "capture5GDataPlanePackets" in params) {
      requestBody?.input?.push(
        { key: "capture5GControlPlanePackets", value: params.capture5GControlPlanePackets ? "true" : "false" },
        { key: "capture5GDataPlanePackets", value: params.capture5GDataPlanePackets ? "true" : "false" },
        { key: "dataPlaneFilter", value: params.dataPlaneFilter ?? null },
        { key: "interfaceCount", value: params.interfaceCount ?? null },
        { key: "controlPlaneIp", value: params.controlPlaneIp ?? null }
      );
    }
  }
  return operationService.createOperation(requestBody);
}

async function ExportRadio(input: Input[]) {
  const requestBody: OperationDetails = {
    type: "operation",
    name: "exportRadio",
    operationType: "EXPORT",
    objectType: "radio",
    isBulk: true,
    input: input,
  };
  try {
    const data = await operationService.createOperation(requestBody);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function GenerateBulkDownload(operationType: string, objectType: string) {
  const requestBody = {
    name: operationType,
    type: "operation",
    operationType: operationType,
    objectType: objectType,
    isBulk: true,
  };

  try {
    const data = await operationService.createOperation(requestBody);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

/**
 * @example
 * GetExportStatus("radio", "EXPORT")
 */
async function GetExportStatus(objectType: string, operationType: string) {
  type Group = "ACKNOWLEDGED" | "IN_PROGRESS" | "FAILED" | "COMPLETED" | "CANCELLED";
  const operations = await getAllOperations({ operationType, objectType });
  const data = groupBy(operations, "status");

  const result: Record<Group, OperationDetails[]> = {
    ACKNOWLEDGED: [],
    IN_PROGRESS: [],
    FAILED: [],
    COMPLETED: [],
    CANCELLED: [],
    ...data,
  };
  return result;
}

async function GetBundleStatus(edgeID: string) {
  const requestBody = {
    objectType: "edge",
    objectId: edgeID,
    operationType: "GENERATE_TECH_SUPPORT_BUNDLE",
    startTime: moment().subtract(1, "days").unix(),
    endTime: moment().unix(),
  };

  type Group = "ACKNOWLEDGED" | "IN_PROGRESS" | "FAILED" | "COMPLETED" | "CANCELLED";
  const operations = await getAllOperations(requestBody);
  const data = groupBy(operations, "status");

  const result: Record<Group, OperationDetails[]> = {
    ACKNOWLEDGED: [],
    IN_PROGRESS: [],
    FAILED: [],
    COMPLETED: [],
    CANCELLED: [],
    ...data,
  };
  return result;
}

async function GetPcapStatus(edgeID: string, name = "START_PACKET_CAPTURE") {
  const requestBody = {
    objectType: "edge",
    objectId: edgeID,
    operationType: name,
    startTime: moment().subtract(1, "days").unix(),
    endTime: moment().unix(),
  };

  type Group = "ACKNOWLEDGED" | "IN_PROGRESS" | "FAILED" | "COMPLETED" | "CANCELLED" | "FETCHING_RESULTS" | "WAIT_NOT_STARTED"| "WAITING_IN_PROGRESS" | "raw";
  const operations = await getAllOperations(requestBody);
  const data = groupBy(operations, "status");

  const result: Record<Group, OperationDetails[]> = {
    ACKNOWLEDGED: [],
    IN_PROGRESS: [],
    FAILED: [],
    COMPLETED: [],
    CANCELLED: [],
    FETCHING_RESULTS: [],
    WAIT_NOT_STARTED: [],
    WAITING_IN_PROGRESS: [],
    ...data,
    raw: operations,
  };
  return result;
}
