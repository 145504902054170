import { Action, createAction } from "@reduxjs/toolkit";
import { ReduxMiddleware } from "..";
import { socketRefreshHandler } from "./socketrefreshHander";
import { WebSocketMessage } from "~/types/WebsocketMessage";
import { authenticationService } from "~/services";
import { Socket } from "@h9/ui-lib";
import EventBatcher from "~/helpers/EventBatch";

const socket = new Socket()
const env = window.location.protocol === 'https:' ? `wss://` :  `ws://`
const url = `${env}${window.location.host}/websocket`

const batcher = new EventBatcher((event : string, data : WebSocketMessage[]) => {
  socketRefreshHandler(event, data);
});

const webSocketMiddleWare : ReduxMiddleware = (api) => (next) => (action) => {
  switch (action.type) {
    case "socket/connect":
      const token = authenticationService.currentUserValue!.token;
      socket.connect(url, {
        msg : {token},
        type: "json"
      });

      socket.on("message", (e) => {
        const message : WebSocketMessage = JSON.parse(e.data);
        batcher.addEvent(message.objectType, message);
      })
      break;
    case "socket/disconnect":
      socket.disconnect();
      break;
    case "socket/send":
      socket.send(action.payload);
      break;

    default:
      break;
  }
  return next(action);
}
export default webSocketMiddleWare;
export function sendSocket<T>(message : T){
  const data = JSON.stringify(message);
  return createAction("socket/send", (action : Action<string>) => {
    return {
      payload: data
  }});
}

export function connectSocket(){
  console.log("connecting");
  return {
    type : 'socket/connect'
  };
}

export function disconnectSocket(){
  console.log("disconnecting");
  return {
    type : 'socket/disconnect'
  }
}
