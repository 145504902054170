import { jsx as e, jsxs as a } from "react/jsx-runtime";
import { Box as n, Typography as c, Grid as x } from "@mui/material";
import { c as m } from "../../chunks/createSvgIcon.js";
import { useMemo as f } from "react";
const g = m(/* @__PURE__ */ e("path", {
  d: "M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7a.996.996 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41s1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0s.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4"
}), "CloseRounded"), A = ({ presetOptions: t, activePreset: s, onPresetChange: i, isLive: l, isRangeOnly: d }) => {
  const p = f(() => Math.ceil(t.length / 2), [t]);
  return /* @__PURE__ */ a(n, { sx: { display: "flex", flexDirection: "column" }, children: [
    /* @__PURE__ */ e(c, { sx: { fontSize: "13px", fontWeight: "600", lineHeight: "15px", color: "#878787", padding: "6px" }, children: l ? "Live Data Presets" : "Data Presets" }),
    /* @__PURE__ */ e(x, { container: !0, sx: { display: "grid", gridTemplateRows: `repeat(${p}, 1fr)`, gap: "4px", gridAutoFlow: "column", gridAutoColumns: "1fr 1fr" }, children: t.map((o) => {
      const r = o.value === s.value;
      return /* @__PURE__ */ a(
        n,
        {
          onClick: () => {
            r || i(o);
          },
          sx: {
            display: "flex",
            alignItems: "center",
            gap: "4px",
            cursor: r ? "default" : "pointer",
            padding: "6px",
            borderRadius: "4px",
            backgroundColor: r ? "#E9F0EE" : "transparent",
            width: "max-content",
            position: "relative"
          },
          children: [
            /* @__PURE__ */ e(c, { sx: { fontSize: "13px", fontWeight: "500", lineHeight: "15px", color: "#226A58" }, children: o.label }),
            r && l && !d && o.value !== "custom" && /* @__PURE__ */ e(
              g,
              {
                onClick: (u) => {
                  u.stopPropagation(), i({ label: "Custom", value: "custom" });
                },
                sx: { fontSize: "14px", color: "#226A58", cursor: "pointer" }
              }
            )
          ]
        },
        o.value
      );
    }) })
  ] });
};
export {
  A as LiveDataPresetsMenu
};
