export type AppInsightsMetrics = {
  name: string;
  metricKeys: string | Array<string>;
  metricLabel?: string | Array<string>;
  labels: string[];
  type?: string;
  unit?: string;
  size?: string;
}

export const AppInsightsMetricsMap: Record<string, AppInsightsMetrics[]> = {
  "Overview": [
    {
      name: "Download Traffic Per Application Category (Bytes)",
      metricKeys: "SUM(server_metrics_sum__down_volume__bytes)",
      labels: ['application', 'application_category'],
      size: 'span 2',
    },
    {
      name: "Upload Traffic Per Application Category (Bytes)",
      metricKeys: "SUM(server_metrics_sum__up_volume__bytes)",
      labels: ['application', 'application_category'],
    },
    {
      name: "Total Traffic Per Application Category (Bytes)",
      metricKeys: "SUM(server_metrics_sum__down_volume__bytes) + SUM(server_metrics_sum__up_volume__bytes)",
      labels: ['application', 'application_category'],
    },
  ],

  // network metrics
  "Metrics": [
    {
      name: "Traffic Throughput (bps)",
      metricKeys: ["8*sum(server_metrics_sum__down_volume__bytes)/keys.resolution", "8*sum(server_metrics_sum__up_volume__bytes)/keys.resolution",],
      metricLabel: ["Download", "Upload"],
      labels: [],
      size: 'span 2',
      unit: "bps",
    },

    {
      name: "Internal Latency (ms)",
      metricKeys: ["APPROX_QUANTILES(server_metrics_p50__internal_rtt__ms, 100)[OFFSET(95)]", "APPROX_QUANTILES(server_metrics_p50__internal_rtt__ms, 100)[OFFSET(50)]"],
      metricLabel: ["Internal RTT p95", "Internal RTT p50"],
      labels: [],
      unit: "ms",
    },

    {
      name: "External Latency (ms)",
      metricKeys: ["APPROX_QUANTILES(server_metrics_p50__external_rtt__ms, 100)[OFFSET(95)]", "APPROX_QUANTILES(server_metrics_p50__external_rtt__ms, 100)[OFFSET(50)]"],
      metricLabel: ["External RTT p95", "External RTT p50"],
      labels: [],
      unit: "ms",
    },
    {
      name: "Subscribers",
      metricKeys: "count(DISTINCT(server_subscriber_id))",
      metricLabel: "Subscribers",
      labels: [],
      type: "line",
      unit: "",
    },
    {
      name: "TCP Packet Loss",
      metricKeys: ["sum(server_metrics_sum__internal_loss__packets)/sum(server_metrics_sum__internal_loss__checks)*100", "sum(server_metrics_sum__external_loss__packets)/sum(server_metrics_sum__external_loss__checks)*100",],
      metricLabel: ["Internal Loss", "External Loss"],
      labels: [],
      type: "column",
      unit: "percent",
    },

  ],

  // experience metrics
  "Experience": [
    {
      name: "Category Experience",
      metricKeys: "100 * SAFE_DIVIDE(SUM(ifNull(app_kqis_sum__state__ux_level__high__sec, 0)), SUM((IFNULL(app_kqis_sum__state__ux_level__low__sec, 0) + IFNULL(app_kqis_sum__state__ux_level__medium__sec, 0) + IFNULL(app_kqis_sum__state__ux_level__high__sec, 0))))",
      labels: ['application_category'],
      type: "line",
      unit: '',
      size: 'span 2',
    },
  ],

  // application metrics
  "ApplicationCategory": [
    {
      name: "Total Traffic per Application Category",
      metricKeys: "SUM(server_metrics_sum__down_volume__bytes)+SUM(server_metrics_sum__up_volume__bytes)",
      labels: ['application_category'],
      size: 'span 2',
    },
  ],
}


export const AppInsightsMetricsSumMap: Record<string, AppInsightsMetrics[]> = {
  "Overview": [
    {
      name: "Download",
      metricKeys: "sum(ip_load_metrics_sum__down_volume__bytes)",       // resolution == (endTime - startTime)
      labels: [],
      type: "label",
    },
    {
      name: "Upload",
      metricKeys: "sum(ip_load_metrics_sum__up_volume__bytes)",       // resolution == (endTime - startTime)
      labels: [],
      type: "label",
    },
    {
      name: "Peak Download",
      metricKeys: "MAX(ifNull(ip_load_metrics_sum__down_volume__bytes, 0))*8/60",       // resolution == (endTime - startTime)
      labels: [],
      type: "label",
    },
    {
      name: "Peak Upload",
      metricKeys: "MAX(ifNull(ip_load_metrics_sum__up_volume__bytes, 0))*8/60",       // resolution == (endTime - startTime)
      labels: [],
      type: "label",
    },
    {
      name: "Application Usage (Bytes)",
      metricKeys: "SUM(server_metrics_sum__down_volume__bytes) + SUM(server_metrics_sum__up_volume__bytes)",       // resolution == (endTime - startTime)
      labels: ['application', 'application_category'],
      type: "piechart",
      size: 'span 1.4',
    },
    {
      name: "Overall Score",
      metricKeys: "100 * SAFE_DIVIDE(SUM(ifNull(app_kqis_sum__state__ux_level__high__sec, 0)), SUM((IFNULL(app_kqis_sum__state__ux_level__low__sec, 0) + IFNULL(app_kqis_sum__state__ux_level__medium__sec, 0) + IFNULL(app_kqis_sum__state__ux_level__high__sec, 0))))",       // resolution == (endTime - startTime)
      labels: [],
      type: "gauge",
      size: 'span 1.3',
    },
    {
      name: "Application Score Radar",
      metricKeys: "100 * SAFE_DIVIDE(SUM(ifNull(app_kqis_sum__state__ux_level__high__sec, 0)), SUM((IFNULL(app_kqis_sum__state__ux_level__low__sec, 0) + IFNULL(app_kqis_sum__state__ux_level__medium__sec, 0) + IFNULL(app_kqis_sum__state__ux_level__high__sec, 0))))",
      // resolution == (endTime - startTime)
      labels: ['application_category'],
      type: "radar",
      size: 'span 1.3',
    },
  ],

  // network metrics
  "Metrics": [],
  "Experience": [

    {
      name: "Top Applications",
      metricKeys: [
        " 100 * SAFE_DIVIDE(SUM(ifNull(app_kqis_sum__state__ux_level__high__sec, 0)), SUM((IFNULL(app_kqis_sum__state__ux_level__low__sec, 0) + IFNULL(app_kqis_sum__state__ux_level__medium__sec, 0) + IFNULL(app_kqis_sum__state__ux_level__high__sec, 0))))",
        "count(DISTINCT(app_kqis_subscriber_id)) ",
        "COUNT(DISTINCT(if(ifNull(app_kqis_sum__state__ux_level__low__sec, 0) + ifNull(app_kqis_sum__state__ux_level__medium__sec, 0) > 0, subscriber_id, NULL)))",
        "1000 * SAFE_DIVIDE(sum(ifNull(app_kqis_sum__state__ux_level__low__sec, 0) + ifNull(app_kqis_sum__state__ux_level__medium__sec, 0)), COUNT(DISTINCT(if(ifNull(app_kqis_sum__state__ux_level__low__sec, 0) + ifNull(app_kqis_sum__state__ux_level__medium__sec, 0) > 0, subscriber_id, NULL))))"
      ],   // resolution == (endTime - startTime)
      metricLabel: ["Score", "Subscribers", "Impacted Subscribers", "Impacted Time per Affected Sub"],
      labels: ['application', 'application_category'],
      type: "table",
      size: 'span 2',
    },
    {
      name: "Worst Subscribers by Impacted Experience",
      metricKeys: [
        "100 * SAFE_DIVIDE(SUM(ifNull(app_kqis_sum__state__ux_level__high__sec, 0)), SUM((IFNULL(app_kqis_sum__state__ux_level__low__sec, 0) + IFNULL(app_kqis_sum__state__ux_level__medium__sec, 0) + IFNULL(app_kqis_sum__state__ux_level__high__sec, 0))))",
        "sum(ifNull(app_kqis_sum__state__ux_level__low__sec, 0) + ifNull(app_kqis_sum__state__ux_level__medium__sec, 0))*1000",
      ],      // resolution == (endTime - startTime)
      metricLabel: ["Score", "Impacted Experience"],
      labels: ['subscriber_id'],
      type: "table",
      size: 'span 2',
    },
  ],
  "ApplicationCategory": [
    {
      name: "Category Summary (Bytes)",
      metricLabel: ['Download', 'Upload'],
      metricKeys: ["SUM(server_metrics_sum__down_volume__bytes)", "SUM(server_metrics_sum__up_volume__bytes)"],       // resolution == (endTime - startTime)
      labels: ['application_category'],
      type: "table",
      unit: "bytes",
      size: 'span 4',
    },
  ],
}

