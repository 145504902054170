import { jsx as e, jsxs as i } from "react/jsx-runtime";
import { Box as c, Select as C, OutlinedInput as I, MenuItem as T, Divider as E, Typography as A } from "@mui/material";
import { useMemo as _, useState as m, useEffect as f } from "react";
import r from "moment-timezone";
import { styles as t } from "../components/DateTimeSelector/DateTimeSelect.style.js";
import { c as q } from "./createSvgIcon.js";
import { DatePickerComponent as G } from "../components/DateTimeSelector/DatePickerComponent.js";
import { TimePickerComponent as J } from "../components/DateTimeSelector/TimePickerComponent.js";
const K = q(/* @__PURE__ */ e("path", {
  d: "M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V10h14zm0-12H5V6h14zm-7 5h5v5h-5z"
}), "EventOutlined"), D = r.months(), Q = Array.from({ length: 6 }, (a, l) => r().year() - l), R = r().year(), H = r().month(), re = ({
  date: a,
  setDate: l,
  dateType: M,
  timeSlots: P,
  isTimeHidden: g,
  minTime: V,
  maxTime: b,
  startDate: k,
  endDate: w
}) => {
  const s = _(() => r.unix(a), [a]), [v, S] = m(s.month().toString()), [h, Y] = m(s.year().toString()), [u, z] = m(a), [d, j] = m(r.unix(a).format("h:mm A")), [B, y] = m(D.slice(0, H + 1)), N = g ? "MMM DD, YYYY" : "MMM DD, YYYY hh:mm A", O = (n) => {
    S(n.target.value);
  }, W = (n) => {
    Y(n.target.value);
  };
  return f(() => {
    parseInt(h) === R ? y(D.slice(0, H + 1)) : y(D);
  }, [h]), f(() => {
    S(s.month().toString()), Y(s.year().toString());
  }, [s]), f(() => {
    const [n, o] = d.split(" "), [p, F] = n.split(":").map(Number);
    let x = p;
    o === "PM" && p < 12 && (x += 12), o === "AM" && p === 12 && (x = 0);
    const L = r.unix(u).hours(x).minutes(F).seconds(0).milliseconds(0);
    l(L.unix());
  }, [u, d, l]), /* @__PURE__ */ i(c, { sx: t.dateTimeSelectorContainer, children: [
    /* @__PURE__ */ i(c, { sx: t.dateTimeSelectorsWrapper, children: [
      /* @__PURE__ */ e(
        C,
        {
          id: "month-select",
          value: v,
          onChange: O,
          displayEmpty: !0,
          classes: { select: "month-year-selector" },
          input: /* @__PURE__ */ e(I, { sx: t.monthYearSelectInput }),
          sx: t.monthYearSelectBox,
          children: B.map((n, o) => /* @__PURE__ */ e(T, { sx: t.monthYearMenuItem, value: o.toString(), children: n }, o))
        }
      ),
      /* @__PURE__ */ e(
        C,
        {
          id: "year-select",
          value: h,
          onChange: W,
          displayEmpty: !0,
          classes: { select: "month-year-selector" },
          input: /* @__PURE__ */ e(I, { sx: t.monthYearSelectInput }),
          sx: t.monthYearSelectBox,
          children: Q.map((n, o) => /* @__PURE__ */ e(T, { sx: t.monthYearMenuItem, value: n.toString(), children: n }, o))
        }
      )
    ] }),
    /* @__PURE__ */ i(c, { sx: { display: "flex", alignItems: "center" }, children: [
      /* @__PURE__ */ e(
        G,
        {
          selectedDate: u,
          onDateChange: z,
          month: v,
          year: h,
          minTime: V,
          maxTime: b,
          startDate: k,
          endDate: w,
          dateType: M
        }
      ),
      !g && /* @__PURE__ */ e(
        J,
        {
          selectedTime: d,
          onTimeChange: j,
          timeSlots: P
        }
      )
    ] }),
    /* @__PURE__ */ e(E, { sx: { borderColor: "#D6D6D6" } }),
    /* @__PURE__ */ i(c, { sx: t.datePickerContainer, children: [
      /* @__PURE__ */ i(c, { sx: t.datePickerContent, children: [
        /* @__PURE__ */ e(K, { sx: t.icon }),
        /* @__PURE__ */ e(A, { sx: { fontSize: "12px", fontWeight: 500 }, children: M === "start" ? "Start Date" : "End Date" })
      ] }),
      /* @__PURE__ */ e(E, { orientation: "vertical", sx: t.verticalDivider }),
      /* @__PURE__ */ e(A, { sx: t.formattedDateText, children: s.format(N) })
    ] })
  ] });
};
export {
  re as D,
  K as E
};
