/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { DataObject } from "./dataObject";

export interface Policy extends DataObject {
  flowRules: FlowRule[];
  name: string;
  runtimeInfo: null;
}

export interface FlowRule {
  flows: Flow[];
  QosProfileType?: string;
  qosProfile?: string;
  name: string;
}

export interface Flow {
  destinationIPRanges: DestinationIPRange[];
  destinationPorts: DestinationPort[];
  protocols: Protocol[];
}

export interface DestinationIPRange {
  ip: string;
}

export interface DestinationPort {
  port: number | null;
}

export interface Protocol {
  protocol: string;
}


export const defaultPolicy: Policy = {
  name: "",
  flowRules: [
      {
          name: "",
          QosProfileType: "default",
          flows: [
              {
                  destinationIPRanges: [{ip: ""}],
                  destinationPorts: [{port: null}],
                  protocols: [{protocol: ""}],
              }
          ]
      }
  ],
  runtimeInfo: null,
}