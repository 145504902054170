/**
 * Copyright Highway9 Networks Inc.
 */
import { QCI } from "~/types/qci";
import { QOS } from "~/types/qos";
import APIService from "./APIServices";

const qciAPI = new APIService<QCI>("qci");
const qosAPI = new APIService<QOS>("qosprofiles");

export const QosService = {
  getQosData: qosAPI.getAll,
  addQoS: qosAPI.create,
  updateQoS: qosAPI.update,
  deleteQOSProfile: qosAPI.delete,
  getQCIData: qciAPI.getAll,
};
