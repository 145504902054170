/** 
 Copyright Highway9 Networks Inc. 
 */
import { SyncronizationProfile } from "~/types/SyncProfile";
import { MobilityProfile } from "../types/mobilityProfile";
import APIService from "./APIServices";
import { MobilityProfileAttributes } from "~/types/mobilityProfileAttributes";


const mobilityAttributesAPI = new APIService<MobilityProfileAttributes>("attribute");
export const mobilityProfileAttributeServices = {
  getMobilityProfilesAttributes: mobilityAttributesAPI.getAll,
  updateMobilityProfileAttributes: mobilityAttributesAPI.create,
};



const mobilityAPI = new APIService<MobilityProfile>("mobilityProfiles");
export const mobilityService = {
  getMobilityProfiles: mobilityAPI.getAll,
  addMobilityProfile: mobilityAPI.create,
  updateMobilityProfile: mobilityAPI.update,
  deleteMobilityProfile: mobilityAPI.delete
};


const synchronizationAPI = new APIService<SyncronizationProfile>("synchronizationProfile");
export const synchronizationService = {
  getAll: synchronizationAPI.getAll,
  add: synchronizationAPI.create,
  update: synchronizationAPI.update,
  delete: synchronizationAPI.delete
}
