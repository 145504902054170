import { jsx as r, jsxs as h, Fragment as Oe } from "react/jsx-runtime";
import { Box as v, Typography as S, Button as ee } from "@mui/material";
import { E as be, D as te } from "../../chunks/DateTimeSelectorMenu.js";
import { c as ye } from "../../chunks/createSvgIcon.js";
import { styles as l } from "./DateTimeSelect.style.js";
import { useState as x, useMemo as F, useCallback as ae, useLayoutEffect as Ce } from "react";
import { AnchorDropdownMenu as N } from "./AnchorDropdownMenu.js";
import { LiveDataPresetsMenu as Be } from "./LiveDataPresetsMenu.js";
import t from "moment-timezone";
import { calculateDateRange as b, generateTimeSlots as Ae, formatPresetLabel as Me } from "../../utilities/datetime.js";
import { Seek as se } from "./Seek.js";
import { useInterval as ke } from "../../utilities/index.js";
const Ee = ye(/* @__PURE__ */ r("path", {
  d: "M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z"
}), "KeyboardArrowDownOutlined"), Se = [
  {
    label: "Last 15 Mins",
    value: "15minute"
  },
  {
    label: "Last 30 Mins",
    value: "30minute"
  },
  {
    label: "Last 1 Hour",
    value: "1hour"
  },
  {
    label: "Last 3 Hours",
    value: "3hours"
  },
  {
    label: "Last 6 Hours",
    value: "6hours"
  },
  {
    label: "Last 24 Hours",
    value: "24hours"
  },
  {
    label: "Last 7 Days",
    value: "7days"
  },
  {
    label: "Last 30 Days",
    value: "30days"
  },
  {
    label: "Last 90 Days",
    value: "90days"
  },
  {
    label: "Last 1 Year",
    value: "1year"
  },
  {
    label: "Custom",
    value: "custom"
  }
], ze = ({
  startTime: y,
  endTime: C,
  interval: I,
  timeSlotInterval: P = 15,
  hideTime: A = !1,
  options: M = Se,
  hideSeek: W = !1,
  hideRange: w = !1,
  rangesOnly: k = !1,
  deltaChangePercent: j = 0.5,
  minTime: E = 0,
  maxTime: B = 1 / 0,
  timezone: s = t.tz.guess(),
  onChange: R,
  isLive: D = !1,
  disablePresetFiltering: z = !1,
  refreshInterval: le = 6e4
}) => {
  const [L, K] = x(null), [V, Y] = x(null), [$, q] = x(null), [G, J] = x(null), [n, p] = x({
    label: "Custom",
    value: "custom"
  }), [c, d] = x(t().subtract(7, "days").unix()), [o, f] = x(t().unix()), [Q, i] = x(!1), [ue, m] = x(!D), re = F(() => {
    if (z)
      return M;
    {
      const e = B === 1 / 0 ? t().add(100, "years").unix() : B, a = t.unix(E).startOf("minute").unix(), u = t.unix(e).startOf("minute").unix();
      return M.filter((T) => {
        if (T.value === "custom")
          return !0;
        const { startDate: De, endDate: he } = b(T.value), xe = t.unix(De).startOf("minute").unix(), pe = t.unix(he).startOf("minute").unix();
        return xe >= a && pe <= u;
      });
    }
  }, [z, M, B, E]), U = F(() => Ae(P), [P]), X = F(() => Math.floor((o - c) * j), [o, c, j]), Z = A ? "MMM DD, YYYY" : "MMM DD, YYYY hh:mm A", O = ae((e, a, u) => {
    R && R(e, a, u);
  }, [R]), H = (e, a) => {
    switch (a) {
      case "live-data-preset-menu":
        K(e.currentTarget);
        break;
      case "custom-time-selector-menu-start":
      case "custom-time-selector-menu-end":
        a === "custom-time-selector-menu-start" ? Y(e.currentTarget) : q(e.currentTarget), i(!0), m(!1);
        break;
    }
    J(a);
  }, g = (e) => {
    switch (e) {
      case "live-data-preset-menu":
        K(null);
        break;
      case "custom-time-selector-menu-start":
        Y(null);
        break;
      case "custom-time-selector-menu-end":
        Y(null), q(null);
        break;
    }
    J(null);
  }, ne = (e) => {
    if (e.value === "custom")
      p(e), i(!0), m(!1), O(c, o, e.value);
    else {
      const { startDate: a, endDate: u } = b(e.value);
      f(t(u).tz(s).valueOf()), d(t(a).tz(s).valueOf()), p(e), O(a, u, e.value), i(!1);
    }
    g(
      "live-data-preset-menu"
      /* LIVE_DATA_PRESET_MENU */
    );
  }, oe = (e) => {
    p({
      label: "Custom",
      value: "custom"
    }), H(
      e,
      "custom-time-selector-menu-start"
      /* CUSTOM_TIME_SELECTOR_MENU_START */
    );
  }, ce = (e) => {
    p({
      label: "Custom",
      value: "custom"
    }), H(
      e,
      "custom-time-selector-menu-end"
      /* CUSTOM_TIME_SELECTOR_MENU_END */
    );
  }, de = () => {
    m(!0), O(c, o, n.value);
  }, fe = () => {
    const { startDate: e, endDate: a } = b(n.value);
    d(t(e).tz(s).valueOf()), f(t(a).tz(s).valueOf()), O(e, a, n.value);
  }, _ = (e, a) => {
    const u = a * e;
    e === 1 && o + u > t().unix() || e === -1 && c + u < E || e === 1 && o + u > B || (d(t(c + u).tz(s).valueOf()), f(t(o + u).tz(s).valueOf()), O(c + u, o + u, n.value));
  }, ie = (e) => {
    if (e > o)
      return;
    d(t(e).tz(s).valueOf());
    const a = t(e).startOf("second").unix(), u = t(c).startOf("second").unix();
    a !== u && (m(!1), i(!0));
  }, me = (e) => {
    if (e < c)
      return;
    f(t(e).tz(s).valueOf());
    const a = t(e).startOf("second").unix(), u = t(o).startOf("second").unix();
    a !== u && (m(!1), i(!0));
  };
  Ce(() => {
    if (I)
      if (I === "custom")
        if (p({
          label: "Custom",
          value: "custom"
        }), y && C)
          d(t(y).tz(s).valueOf()), f(t(C).tz(s).valueOf()), m(!0), i(!0);
        else {
          const { startDate: e, endDate: a } = b("7days");
          d(t(e).tz(s).valueOf()), f(t(a).tz(s).valueOf()), m(!0), i(!0);
        }
      else {
        const e = M.find((a) => a.value === I) || {
          label: "Custom",
          value: "custom"
        };
        if (p(e), e.value === "custom")
          if (y && C)
            d(t(y).tz(s).valueOf()), f(t(C).tz(s).valueOf()), m(!0), i(!0);
          else {
            const { startDate: a, endDate: u } = b("7days");
            d(t(a).tz(s).valueOf()), f(t(u).tz(s).valueOf()), m(!0), i(!0);
          }
        else {
          const { startDate: a, endDate: u } = b(e.value);
          d(t(a).tz(s).valueOf()), f(t(u).tz(s).valueOf());
        }
      }
    else if (y && C)
      p({
        label: "Custom",
        value: "custom"
      }), m(!0), i(!0), d(t(y).tz(s).valueOf()), f(t(C).tz(s).valueOf());
    else {
      const { startDate: e, endDate: a } = b("7days");
      d(t(e).tz(s).valueOf()), f(t(a).tz(s).valueOf()), p({
        label: "7 Days",
        value: "7days"
      });
    }
  }, [y, C, I, M, s, O]);
  const ve = ae(() => {
    if (n.value !== "custom" && D) {
      const { startDate: e, endDate: a } = b(n.value);
      f(t(a).tz(s).valueOf()), d(t(e).tz(s).valueOf()), O(e, a, n.value), m(!0), i(!1);
    }
  }, [n, s, O, D]);
  return ke(ve, D ? le : null, !1, [n]), /* @__PURE__ */ h(v, { sx: l.container, children: [
    /* @__PURE__ */ h(v, { sx: l.innerContainer, children: [
      !w && /* @__PURE__ */ h(v, { sx: [l.dateTimeBox, L && l.activeDateTimeBox, k && l.dateTimeBoxRightBorder], onClick: (e) => H(
        e,
        "live-data-preset-menu"
        /* LIVE_DATA_PRESET_MENU */
      ), children: [
        /* @__PURE__ */ r(be, { sx: l.icon }),
        /* @__PURE__ */ r(S, { sx: l.timeLabel, children: n.label }),
        n.value !== "custom" && D && /* @__PURE__ */ h(v, { sx: [l.liveIndicatorBox, !L && { backgroundColor: "#EFEFEF" }], children: [
          /* @__PURE__ */ r(v, { sx: l.liveDot }),
          /* @__PURE__ */ r(S, { sx: l.liveLabel, children: "LIVE" })
        ] }),
        /* @__PURE__ */ r(Ee, { sx: [l.icon, { fontSize: "16px" }] })
      ] }),
      !k && /* @__PURE__ */ h(v, { sx: [l.dateRangeBox, w && l.dateRangeLeftBorder], children: [
        n.value === "custom" && !W && /* @__PURE__ */ r(se, { direction: -1, distance: X, color: l.arrowIcon.color, onSeek: _ }),
        /* @__PURE__ */ h(v, { sx: l.dateBox, children: [
          /* @__PURE__ */ r(S, { onClick: oe, sx: l.dateText, children: n.value === "custom" || !D ? t.unix(c).tz(s).format(Z) : `~ ${Me(n.value)} ago` }),
          G === "custom-time-selector-menu-start" && /* @__PURE__ */ r(v, { sx: l.activeDateBorder })
        ] }),
        /* @__PURE__ */ r(S, { sx: l.dateToLabel, children: "to" }),
        /* @__PURE__ */ h(v, { sx: l.dateBox, children: [
          /* @__PURE__ */ r(S, { onClick: ce, sx: l.dateText, children: n.value === "custom" || !D ? t.unix(o).tz(s).format(Z) : "Now" }),
          G === "custom-time-selector-menu-end" && /* @__PURE__ */ r(v, { sx: l.activeDateBorder })
        ] }),
        n.value === "custom" && !W && /* @__PURE__ */ r(se, { direction: 1, distance: X, color: l.arrowIcon.color, onSeek: _ })
      ] })
    ] }),
    !k && /* @__PURE__ */ h(v, { children: [
      D && !Q && /* @__PURE__ */ r(ee, { variant: "outlined", sx: l.refreshButton, onClick: fe, children: "Refresh" }),
      !D || Q ? /* @__PURE__ */ r(ee, { variant: "contained", sx: l.applyButton, onClick: de, disabled: ue, children: "Apply" }) : null
    ] }),
    !w && // Live Data Preset Menu
    /* @__PURE__ */ r(
      N,
      {
        menuId: "live-data-preset-menu",
        anchorEl: L,
        open: !!L,
        onClose: () => g(
          "live-data-preset-menu"
          /* LIVE_DATA_PRESET_MENU */
        ),
        menuWidth: 360,
        children: /* @__PURE__ */ r(
          Be,
          {
            presetOptions: re,
            activePreset: n,
            onPresetChange: ne,
            isLive: D,
            isRangeOnly: k
          }
        )
      }
    ),
    !k && /* @__PURE__ */ h(Oe, { children: [
      /* @__PURE__ */ r(
        N,
        {
          menuId: "custom-time-selector-menu-start",
          anchorEl: V,
          open: !!V,
          onClose: () => g(
            "custom-time-selector-menu-start"
            /* CUSTOM_TIME_SELECTOR_MENU_START */
          ),
          menuWidth: A ? 280 : 420,
          children: /* @__PURE__ */ r(
            te,
            {
              date: c,
              setDate: ie,
              dateType: "start",
              timeSlots: U,
              isTimeHidden: A,
              minTime: E,
              maxTime: B,
              startDate: c,
              endDate: o
            }
          )
        }
      ),
      /* @__PURE__ */ r(
        N,
        {
          menuId: "custom-time-selector-menu-end",
          anchorEl: $,
          open: !!$,
          onClose: () => g(
            "custom-time-selector-menu-end"
            /* CUSTOM_TIME_SELECTOR_MENU_END */
          ),
          menuWidth: A ? 280 : 420,
          children: /* @__PURE__ */ r(
            te,
            {
              date: o,
              setDate: me,
              dateType: "end",
              timeSlots: U,
              isTimeHidden: A,
              minTime: E,
              maxTime: B,
              startDate: c,
              endDate: o
            }
          )
        }
      )
    ] })
  ] });
};
export {
  ze as DateTimeSelector
};
