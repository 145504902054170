/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { createAsyncThunk, createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "..";
import { siteService } from "../../services/site-service";
import { RadioGroup, PlanMapData, defaultRadioGroup } from "../../types/radioGroup";
import { ObjectReference } from "../../types/dataObject";
import Radio, { RadioX } from "../../types/radio";
import { fetchRadios } from "./radioSlice";
import { Types } from "../../constants/types";
import { MarkerData } from "@h9/ui-lib";
import { BAICELLS, COLOR, HIGHWAY9, IPSEC_MODE } from "~/constants";
import { calcLengths, groupBy } from "~/helpers/utils";
import { checkStatus } from "~/views/radios/radioHelper";
import { PlanCoods } from "~/components/shared/d3/FloorPlan";

type Coods = {
  radioID: string;
  groupID: string;
  radioCoordinates: {
    lat: number;
    lng: number;
  };
};

type mappingID_with_APCount = {
  [key: string]: number;
};
type mappingStatus = {
  [key: string]: string;
};

type initState = {
  open: boolean;
  groupEditFromExcelImport: boolean;
  edit: boolean;
  current: RadioGroup;
  data: RadioGroup[];
  other?: any;
  status: string;
  channelPlanOpen: boolean;

  // from grps
  selectedIDs: string[];
  radioMappingIds?: mappingID_with_APCount;
  connectedRadioMappingIds?: mappingID_with_APCount;
  radioMappingStatus?: mappingStatus;

  // from edges
  showAdvanceEdges: boolean;
};



export const initialState: initState = {
  open: false,
  groupEditFromExcelImport: false,
  edit: false,
  status: "idle",
  current: defaultRadioGroup,
  data: [],
  channelPlanOpen: false,

  // from grps
  selectedIDs: [Types.all],
  radioMappingIds: {},
  connectedRadioMappingIds: {},
  radioMappingStatus: {},
  showAdvanceEdges: false,
};

export const fetchRadioGroups = createAsyncThunk("radioGroup/fetchRadioGroups", async (query: {timestamp?: number}, thunk) => {
  const timestamp = query?.timestamp;
  const queryString = timestamp ? { timestamp } : {};
  
  const _rgroups = (await siteService.getRadioGroups(queryString)).sort((a, b) => a.name.localeCompare(b.name));
  const state = thunk.getState() as RootState;
  let radios = state.radio.data;
  // add the radios to the radio groups
  if (!radios?.length) {
    const response = await thunk.dispatch(fetchRadios({timestamp}));
    if(response.meta.requestStatus === "fulfilled") {
      radios = response.payload as Radio[];
    }
  }
  const data = updateRadioGroups(_rgroups, radios);
  return data;
});

export const radioGroupSlice = createSlice({
  name: Types.radioGroup,
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setGroupEditFromExcelImport: (state, action: PayloadAction<boolean>) => {
      state.groupEditFromExcelImport = action.payload;
    },
    setEdit: (state, action: PayloadAction<boolean>) => {
      state.edit = action.payload;
    },
    setValues: (state, action: PayloadAction<RadioGroup>) => {
      state.current = action.payload;
    },
    setData: (state, action: PayloadAction<RadioGroup[]>) => {
      state.data = action.payload;
    },
    updateData: (state, action: PayloadAction<RadioGroup>) => {
      const index = state.data.findIndex((item) => item.id === action.payload.id);
      state.data[index] = action.payload;
    },

    setChannelPlanOpen: (state, action: PayloadAction<boolean>) => {
      state.channelPlanOpen = action.payload;
    },

    setSelectedIds: (state, action: PayloadAction<string[]>) => {
      state.selectedIDs = action.payload;
    },
    setRadioMappingIds: (state, action: PayloadAction<mappingID_with_APCount>) => {
      state.radioMappingIds = action.payload;
    },
    setConnectedRadioMappingIds: (state, action: PayloadAction<mappingID_with_APCount>) => {
      state.connectedRadioMappingIds = action.payload;
    },
    setMappingStatus: (state, action: PayloadAction<mappingStatus>) => {
      state.radioMappingStatus = action.payload;
    },

    setRadioCoodinates: (state, action: PayloadAction<Coods>) => {
      const { radioID, groupID, radioCoordinates } = action.payload;
      // find the group
      const group = state.data.find((grp) => grp.id === groupID);
      if (group) {
        // update the radio coordinates location
        group.radioCoordinates = group.radioCoordinates?.map((coods) => {
          if (coods.id === radioID) {
            return {
              ...coods,
              location: radioCoordinates,
            };
          }
          return coods;
        });

        // update the state data with the new group data
        state.data = state.data.map((grp) => {
          if (grp.id === groupID) {
            return group;
          }
          return grp;
        });
      }
    },

    addRadioCoodinates: (
      state,
      action: {
        payload: {
          groupID: string;
          marker: MarkerData;
        };
      }
    ) => {
      const { groupID, marker } = action.payload;
      // find the group
      const group = state.data.find((grp) => grp.id === groupID);

      if (group) {
        // update the radio coordinates location
        group.radioCoordinates = group.radioCoordinates?.concat(marker);
        // update the state data with the new group data
        state.data = state.data.map((grp) => {
          if (grp.id === groupID) {
            return group;
          }
          return grp;
        });
      }
    },

    // edit values
    setName: (state, action: PayloadAction<string>) => {
      state.current.name = action.payload;
    },
    setZoneType: (state, action: PayloadAction<string>) => {
      state.current.zoneType = action.payload;
    },
    setTechnology: (state, action: PayloadAction<string>) => {
      state.current.technology = action.payload;
      const BANDS_5G = ["N48", "N77", "N78"];
      const BANDS_4G = ["B48"];
      const band = state.current.technology === "5G" ? BANDS_5G : BANDS_4G
      if (!band.includes(state.current.frequency.band)) {
        state.current.frequency.band = band[0]
      }
    },
    setIPsecMode: (state, action: PayloadAction<{ value: string; index: number }>) => {
      state.current.edges[action.payload.index].ipsecMode = action.payload.value;
    },
    setIPsecModeAll: (state, action: PayloadAction<{ value: string; }>) => {
      state.current.edges.forEach((edge) => {
        edge.ipsecMode = action.payload.value;
      });
    },
    setEdge: (state, action: PayloadAction<{ value: ObjectReference; index: number }>) => {
      state.current.edges[action.payload.index].edge = action.payload.value;
    },
    addNewEdge: (state) => {
      state.current.edges.push({ edge: { id: "" }, ipsecMode: IPSEC_MODE.PSK });
    },
    removeEdge: (state, action: PayloadAction<number>) => {
      state.current.edges = state.current.edges.filter((_, i) => i !== action.payload);
    },
    setMobilityProfile: (state, action: PayloadAction<ObjectReference | null>) => {
      state.current.mobilityProfile = action.payload;
    },

    setCarrier: (state, action: PayloadAction<{ value: ObjectReference[]; index: number }>) => {
      state.current.edges[action.payload.index].carriers = action.payload.value;
    },
    setRadioSiteId: (state, action: PayloadAction<string>) => {
      state.current.radioSite.id = action.payload;
    },
    setRadioSiteName: (state, action: PayloadAction<string>) => {
      state.current.radioSite.name = action.payload;
    },

    setMacroEnbIdList: (state, action: PayloadAction<number[]>) => {
      state.current.macroEnbIdList = action.payload;
    },

    setOverrideMacroEnbIdList: (state, action: PayloadAction<boolean>) => {
      state.current.overrideMacroEnbIdList = action.payload;
    },

    setLocalExitEnabled: (state, action: PayloadAction<boolean>) => {
      state.current.datapath.localExit.enabled = action.payload;
    },
    setLocalExitMode: (state, action: PayloadAction<string>) => {
      state.current.datapath.localExit.mode = action.payload;
    },
    setLocalExitIpPool: (state, action: PayloadAction<string>) => {
      state.current.datapath.localExit.ipPool = action.payload;
    },
    setLocalExitIpPoolNetmask: (state, action: PayloadAction<string>) => {
      state.current.datapath.localExit.ipPoolNetmask = action.payload;
    },

    setSasEnabled: (state, action: PayloadAction<boolean>) => {
      state.current.sas.sasEnabled = action.payload;
    },
    setSasUserId: (state, action: PayloadAction<string>) => {
      state.current.sas.sasUserId = action.payload;
    },
    setFrequencySelectionLogic: (state, action: PayloadAction<string>) => {
      state.current.sas.frequencySelectionLogic = action.payload;
    },
    setIsOnlyAllowSelectedFrequency: (state, action: PayloadAction<boolean>) => {
      state.current.sas.allowOnlySelectedFrequency = action.payload;
    },

    setFrequencyBand: (state, action: PayloadAction<string>) => {
      state.current.frequency.band = action.payload;
    },
    setFrequencyBandwidth: (state, action: PayloadAction<number>) => {
      state.current.frequency.bandwidth = action.payload;
    },
    setFrequencySubFrameAssignment: (state, action: PayloadAction<number>) => {
      state.current.frequency.subframeAssignment = action.payload;
    },
    setFrequencySpecialSubFramePattern: (state, action: PayloadAction<number>) => {
      state.current.frequency.specialSubframePattern = action.payload;
    },

    setShowAdvanceEdges: (state, action: PayloadAction<boolean>) => {
      state.showAdvanceEdges = action.payload;
    },

    // setLongitude: (state, action:PayloadAction number }) => {
    //     state.current.location.longitude = action.payload;
    // },
    // setLatitude: (state, action:PayloadAction number }) => {
    //     state.current.location.latitude = action.payload;
    // },
    // setZoom: (state, action:PayloadAction number }) => {
    //     state.current.location.zoom = action.payload;
    // },
    // setLocationName: (state, action:PayloadAction string }) => {
    //     state.current.location.name = action.payload;
    // },

    // map tab
    setFloor: (state, action: PayloadAction<number>) => {
      state.current.floor = action.payload;
    },
    setArea: (state, action: PayloadAction<number>) => {
      state.current.area = action.payload;
    },
    setCenter: (state, action: PayloadAction<{ lat: number; lng: number; address?: string }>) => {
      if (state.current.plan) {
        state.current.plan.calibrationPoints.map.center = {
          latitude: action.payload.lat,
          longitude: action.payload.lng,
          name: action.payload.address,
        };
      } else {
        state.current.plan = defaultRadioGroup.plan;
      }
    },
    setFloorPlanImage: (
      state,
      action: PayloadAction<{
        data: string;
        name: string;
      }>
    ) => {
      if (state.current.plan?.calibrationPoints.floorPlan) {
        state.current.plan.calibrationPoints.floorPlan.imageData = action.payload.data;
        state.current.plan.calibrationPoints.floorPlan.imageName = action.payload.name;
      } else {
        if (state.current.plan) {
          state.current.plan.calibrationPoints.floorPlan = {
            imageData: action.payload.data,
            imageName: action.payload.name,
          };
        } else {
          state.current.plan = defaultRadioGroup.plan;
        }
      }
    },

    setFloorPlanCoods: (state, action: PayloadAction<PlanCoods[]>) => {
      state.current.radioFloorPlanCoods = action.payload;
    },

    clearFloorPlanImage: (state) => {
      if (state.current.plan?.calibrationPoints.floorPlan) {
        delete state.current.plan.calibrationPoints.floorPlan.imageData;
        delete state.current.plan.calibrationPoints.floorPlan.imageId;
        delete state.current.plan.calibrationPoints.floorPlan.imageName;
      }
    },

    setRFPlanImage: (
      state,
      action: PayloadAction<{
        data: string;
        name: string;
      }>
    ) => {
      if (state.current.plan?.calibrationPoints.rfPlan) {
        state.current.plan.calibrationPoints.rfPlan.imageData = action.payload.data;
        state.current.plan.calibrationPoints.rfPlan.imageName = action.payload.name;
      } else {
        if (state.current.plan) {
          state.current.plan.calibrationPoints.rfPlan = {
            imageData: action.payload.data,
            imageName: action.payload.name,
          };
        } else {
          state.current.plan = defaultRadioGroup.plan;
        }
      }
    },

    clearRFPlanImage: (state) => {
      if (state.current.plan?.calibrationPoints.rfPlan) {
        delete state.current.plan.calibrationPoints.rfPlan.imageData;
        delete state.current.plan.calibrationPoints.rfPlan.imageId;
        delete state.current.plan.calibrationPoints.rfPlan.imageName;
      }
    },

    setMapZoom: (state, action: PayloadAction<number>) => {
      if (state.current.plan) {
        state.current.plan.calibrationPoints.map.zoom = action.payload;
      } else {
        state.current.plan = defaultRadioGroup.plan;
      }
    },
    setMapType: (state, action: PayloadAction<string>) => {
      if (state.current.plan) {
        state.current.plan.calibrationPoints.map.view = action.payload.toUpperCase();
      } else {
        state.current.plan = defaultRadioGroup.plan;
      }
    },
    setMapData: (state, action: PayloadAction<PlanMapData>) => {
      if (state.current.plan) {
        state.current.plan.calibrationPoints.map = action.payload;
      } else {
        state.current.plan = defaultRadioGroup.plan;
      }
    },

    setOther: (state, action: PayloadAction<Object>) => {
      state.other = {
        ...state.other,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRadioGroups.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRadioGroups.fulfilled, (state, action) => {
        state.data = action.payload;

        // sync the current radio group with the data if it exists and is not open
        if (!state.open && state.current?.id) {
          const current = state.data.find((item) => item.id === state.current.id);
          if (current) {
            state.current = current;
          }
        }

        state.status = "idle";
      })
      .addCase(fetchRadioGroups.rejected, (state) => {
        state.status = "error";
      });
  },
});

export default radioGroupSlice.reducer;
export const radioGroupActions = radioGroupSlice.actions;

export const radioGroupState = (state: RootState) => state.radioGroup.current;
export const radioGroupOpen = (state: RootState) => state.radioGroup.open;
export const groupEditFromExcelImport = (state: RootState) => state.radioGroup.groupEditFromExcelImport;
export const radioGroupEdit = (state: RootState) => state.radioGroup.edit;
export const radioGroupData = (state: RootState) => state.radioGroup.data;
export const radioGroupOther = (state: RootState) => state.radioGroup.other;
export const radioGroupById = (id: string) => (state: RootState) =>
  state.radioGroup.data.find((item) => item.id === id);
export const radioChannelPlanOpen = (state: RootState) => state.radioGroup.channelPlanOpen;

export const radioGroupSelected = (state: RootState) => state.radioGroup.selectedIDs;
export const radioGroupMappingIds = (state: RootState) => state.radioGroup.radioMappingIds;
export const radioGroupConnectedMappingIDs = (state: RootState) => state.radioGroup.connectedRadioMappingIds;
export const radioGroupMappingStatus = (state: RootState) => state.radioGroup.radioMappingStatus;
export const radioGroupShowAdvanceEdges = (state: RootState) => state.radioGroup.showAdvanceEdges;
export const radioGroupLoading = (state: RootState) => state.radioGroup.status === "loading";

export function updateRadioGroups(Groups: RadioGroup[], radios: Radio[]) {
  return Groups.map((grp) => {
    const _radios = radios.filter((radio) => radio.radioGroup?.id === grp.id);
    const _group: RadioGroup = {
      ...grp,
      radios: _radios,
    };

    // add the radio coordinates to the radio group
    const coods: MarkerData[] = [];
    const planCoods: PlanCoods[] = [];
    const plmnList: string[] = [];
    const vendorList: string[] = [];
    let fallbackIndex = 0;
    const radiosWithoutFloorPlan = _radios.filter((radio) => !radio.floorPlan?.xOffset || !radio.floorPlan?.yOffset);
    const baseMultiplier = _radios.length > 1 
    ? 55 - Math.min(50, _radios.length * 2) 
    : 55;
    const offsetMultiplier = radiosWithoutFloorPlan.length > 1 
      ? baseMultiplier + (200 / radiosWithoutFloorPlan.length) 
      : baseMultiplier;

      _radios.forEach((radio, i) => {
      if (radio) {
        const location = {
          lat: radio.location?.latitude ?? grp.plan?.calibrationPoints?.map?.points[0].latitude ?? 0,
          lng: radio.location?.longitude ?? grp.plan?.calibrationPoints?.map?.points[0].longitude ?? 0,
        };

        const planCood: PlanCoods = {
          x: radio.floorPlan?.xOffset ?? (500 + fallbackIndex * offsetMultiplier * (-1) ** fallbackIndex),
          y: radio.floorPlan?.yOffset ?? (500 + fallbackIndex * offsetMultiplier * (-1) ** fallbackIndex),
          id: radio.id ?? nanoid(),
          icon: iconColor((radio as RadioX).statusColor),
          title: radio.name,
        };

        if (!radio.floorPlan?.xOffset || !radio.floorPlan?.yOffset) {
          fallbackIndex++;
        }

        // remove at later point
        const coords: MarkerData = {
          id: radio.id ?? nanoid(),
          location: location,
          title: radio.name,
          icon: iconColor((radio as RadioX).statusColor),
        };
        coods.push(coords);
        planCoods.push(planCood);

        // add the plmn list
        if (radio.runtimeInfo?.plmnList) {
          plmnList.push(...radio.runtimeInfo.plmnList);
        }
        // add the vendor list
        if (radio.runtimeInfo?.vendorType) {
          // check if it is baicells , set it as highway9
          if (radio.runtimeInfo.vendorType === BAICELLS) {
            vendorList.push(HIGHWAY9);
          } else {
            vendorList.push(radio.runtimeInfo.vendorType);
          }
        }
      }
    });
    _group.radioCoordinates = coods;
    _group.radioFloorPlanCoods = planCoods;
    _group._plmnList = Array.from(new Set(plmnList));
    _group._vendorList = Array.from(new Set(vendorList));
    return _group;
  });
}

export function syncRadioStatusAndConnectedwithGroups(data: RadioX[]) {
  const dispatch = store.dispatch;
  // set the grp status and ids
  const grpedRows = groupBy(data, "radioGroup.id");
  const grpStatus: { [key: string]: string } = {};
  const connectedApsObj: { [key: string]: number } = {};
  for (const key in grpedRows) {
    if (Object.hasOwnProperty.call(grpedRows, key)) {
      const radios = grpedRows[key];
      const status = radios?.map((radio) => radio.statusColor); // [COLOR.GREEN, COLOR.GREEN, COLOR.RED]
      grpStatus[key] = checkStatus(status);

      // get count of green status
      const connectedCount = status.filter((item) => item === COLOR.GREEN).length;
      connectedApsObj[key] = connectedCount;
    }
  }
  const grpLengthObj = calcLengths(grpedRows);
  dispatch(radioGroupActions.setRadioMappingIds(grpLengthObj));
  dispatch(radioGroupActions.setConnectedRadioMappingIds(connectedApsObj));
  dispatch(radioGroupActions.setMappingStatus(grpStatus));
}

function iconColor(status: string) {
  switch (status) {
    case COLOR.GREEN:
      return "/images/pointer-green.svg";
    case COLOR.ORANGE:
      return "/images/pointer-orange.svg";
    case COLOR.RED:
      return "/images/pointer-red.svg";
    default:
      return "/images/pointer-grey.svg";
  }
}
