/**
 * @file loadingSlice.ts
 * @ CopyRight (C) 2021-2022 All rights reserved. Highway9 Networks Inc.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { useAppDispatch, useAppSelector } from "../hooks";
import LoadingX from "../../components/shared/LoadingX";
import SnackbarX from "../../components/shared/SnackbarX";

type initState = {
  open: boolean;
  snackBar: {
    open: boolean;
    message: React.ReactNode;
    severity: "success" | "error" | "warning" | "info";
    hideDuration?: number;
  };
};

const initialState: initState = {
  open: false,
  snackBar: {
    open: false,
    message: "",
    severity: "success",
    hideDuration: 6000,
  },
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setSnackBar: (
      state,
      action: PayloadAction<{ open?: boolean; message: React.ReactNode; severity: "success" | "error" | "warning" | "info", hideDuration?: number }>
    ) => {
      state.snackBar = { ...action.payload, open: action.payload.open ?? true, hideDuration: action.payload.hideDuration ?? 6000 };
    },
    setOpenSnackBar: (state, action: PayloadAction<boolean>) => {
      state.snackBar.open = action.payload;
    },
  },
});

export const loadingActions = loadingSlice.actions;

export const useLoading = () => {
  const loading = useAppSelector((state: RootState) => state.loading.open);
  const dispatch = useAppDispatch();
  const setLoading = (open: boolean) => {
    dispatch(loadingActions.setLoading(open));
  };
  return { loading, setLoading, LoadingComponent: LoadingX };
};

export const useSnackBar = () => {
  const open = useAppSelector((state: RootState) => state.loading.snackBar.open);
  const message = useAppSelector((state: RootState) => state.loading.snackBar.message);
  const severity = useAppSelector((state: RootState) => state.loading.snackBar.severity);
  const dispatch = useAppDispatch();
  const setOpen = (open: boolean) => {
    dispatch(loadingActions.setOpenSnackBar(open));
  };
  const setSnackBar = (open: boolean, message: React.ReactNode, severity: "success" | "error" | "warning" | "info", hideDuration?: number) => {
    dispatch(loadingActions.setSnackBar({ open, message, severity, hideDuration }));
  };

  return { open, message, severity, setOpen, setSnackBar, SnackBarComponent: SnackbarX };
};

export default loadingSlice.reducer;
