/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { Route, Switch, useLocation } from "react-router-dom";
import {
  AUDITLOGS,
  DASHBOARD,
  DEVICES,
  EDGES,
  NETWORKS,
  RADIOS,
  SETTINGS,
  SIMULATORS,
  VMZ,
  HARDWARE_INVENTORY,
  PROFILES,
  IT_DASHBOARD,
  ONBOARDING,
  CREATE_SITE,
  CREATE_VMZ,
  CREATE_RADIO_GROUP,
  CREATE_VMZ_GROUP,
  CREATE_APPLICATION,
  CREATE_DEVICE_GROUP,
  DEVICE_ADMINISTRATION,
  CREATE_NETWORK,
  DEPLOY_EDGE,
  CONNECT_NETWORKS_TO_EDGE,
} from "../constants/routes";
import { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { CircularProgress } from "@mui/material";
import onAppStart from "./onAppStart";
import Layout from "./Layout";
import LoginExpiryAlert from "~/components/shared/LoginExpiryAlert";
import { useAppSelector } from "~/store/hooks";
import { selectUserPermssions, selectUserPermssionsLoading } from "~/store/context/authSlice";
import { settingsLoading, settingsSystemOnboardingEnabled } from "~/store/context/settingsSlice";
import { useInterval } from "~/hooks/useInterval";
import evt from "~/store/event";

const Dashboard = lazy(() => import("../views/dashboard-new/Dashboard"));
const RadiosPanelNew = lazy(() => import("../views/radios/RadiosPanelNew"));
const EdgesPage = lazy(() => import("../views/edges/EdgesPage"));
const SubscribersPanel = lazy(() => import("../views/subscribers/SubscribersPanel"));
const DnnPanel = lazy(() => import("../views/dnn/DnnPanel"));
const VMZone = lazy(() => import("../views/vmz"));
const Diagnostics = lazy(() => import("../views/diagnostics/SimulatorPanel"));
const AuditLog = lazy(() => import("../views/audit/AuditLog"));
const AdminPanel = lazy(() => import("../views/adminstration/AdminPanel"));
const NotImplemented = lazy(() => import("../views/NotImplemented/NotImplemented"));
const HardwareInventoryPanel = lazy(() => import("../views/hardwareInventory/HarwareInventoryPanel"));
const Profiles = lazy(() => import("../views/profiles/ProfilePanel"));
const CreateSite = lazy(() => import("../views/Onboarding/CreateSite"));
const ITDashboard = lazy(() => import("../views/ITDashboard"));
const Onboarding = lazy(() => import("../views/Onboarding/LandingPage"));
const CreateVmz = lazy(() => import("../views/Onboarding/CreateVmz"));
const CreateRadioGroup = lazy(() => import("../views/Onboarding/CreateRadioGroup"));
const CreateVmzGroup = lazy(() => import("../views/Onboarding/CreateVmzGroup"));
const CreateApplication = lazy(() => import("../views/Onboarding/CreateApplication"));
const CreateDeviceGroup = lazy(() => import("../views/Onboarding/CreateDeviceGroup"));
const DeviceAdministration = lazy(() => import("../views/Onboarding/DeviceAdministration"));
const CreateNetwork = lazy(() => import("../views/Onboarding/CreateNetwork"));
const DeployEdge = lazy(() => import("../views/Onboarding/DeployEdge"));
const ConnectNetworksToEdge = lazy(() => import("../views/Onboarding/ConnectNetworksToEdge"));

type LState = {
  fromChat?: boolean;
} | undefined;

type Props = {};
const AppRoutes = (_props: Props) => {
  /**
   * Runs the onAppStart function when the component mounts.
   * This effect is responsible for performing any necessary setup or initialization tasks when the app starts.
   */
  useEffect(() => {
    onAppStart();
  }, []);

  useInterval(() => { evt.emit('refreshServiceController') }, 60000);

  const permissionsMap = useAppSelector(selectUserPermssions);
  const permissionsLoading = useAppSelector(selectUserPermssionsLoading);
  const onboardingEnabled = useAppSelector(settingsSystemOnboardingEnabled);
  const systemSettingsLoading = useAppSelector(settingsLoading);

  const history = useLocation<LState>();
  const [loading, setLoading] = useState(false);

  const showOnboarding = useMemo(() => {
    return !systemSettingsLoading && !permissionsLoading && onboardingEnabled && (
      // At least on of the view permission should exist for the Getting Started page to be visible
      permissionsMap.radioSite?.VIEW || permissionsMap.radioGroup?.VIEW || permissionsMap.dnn?.VIEW || permissionsMap.deviceGroup?.VIEW || permissionsMap.zone?.VIEW || permissionsMap.edge?.VIEW || permissionsMap.device?.VIEW
    )
  }, [onboardingEnabled, systemSettingsLoading, permissionsLoading, permissionsMap]);

  useEffect(() => {
    if (history.state?.fromChat) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [history]);


  return (
    <Layout>
        <Suspense fallback={<Loading />}>
        { loading && <Loading overlay /> }
        <Switch>
          <Route exact path={DASHBOARD} component={Dashboard} />
          { permissionsMap.radio?.VIEW && <Route exact path={RADIOS} component={RadiosPanelNew} />}
          { permissionsMap.edge?.VIEW && permissionsMap.dnn?.VIEW && <Route exact path={EDGES} component={EdgesPage} />}
          { permissionsMap.device?.VIEW && <Route exact path={DEVICES} component={SubscribersPanel} />}
          { permissionsMap.dnn?.VIEW && <Route exact path={NETWORKS} component={DnnPanel} />}
          { permissionsMap.zone?.VIEW && <Route exact path={VMZ} component={VMZone} />}
          { permissionsMap.simulator?.VIEW && <Route exact path={SIMULATORS} component={Diagnostics} />}
          { permissionsMap.network?.VIEW && <Route exact path={SETTINGS} component={AdminPanel} />}
          { permissionsMap.mobilityProfile?.VIEW && permissionsMap.systemSettings?.VIEW && <Route exact path={PROFILES} component={Profiles} />}
          { permissionsMap.log?.VIEW && <Route exact path={AUDITLOGS} component={AuditLog} />}
          { permissionsMap.vmcHardware?.VIEW && <Route exact path={HARDWARE_INVENTORY} component={HardwareInventoryPanel} />}
          { permissionsMap.itAdminDashboard?.VIEW && <Route exact path={IT_DASHBOARD} component={ITDashboard} />}
          { showOnboarding &&
            <Route path={ONBOARDING}>
              <Switch>
                {/* CreateSiteFlow */}
                { permissionsMap.radioSite?.ADD && <Route exact path={CREATE_SITE} component={CreateSite} />}
                {/* CreateVMZFlow */}
                { permissionsMap.zone?.ADD && <Route exact path={CREATE_VMZ} component={CreateVmz} />}
                {/* CreateVMZGroupFlow */}
                { permissionsMap.zoneGroup?.ADD && <Route exact path={CREATE_VMZ_GROUP} component={CreateVmzGroup} />}
                {/* CreateApplicationFlow */}
                { permissionsMap.application?.ADD && <Route exact path={CREATE_APPLICATION} component={CreateApplication} />}
                {/* CreateRadioGroupFlow */}
                { permissionsMap.radioGroup?.ADD && <Route exact path={CREATE_RADIO_GROUP} component={CreateRadioGroup} />}
                {/* CreateNetworkFlow */}
                { permissionsMap.dnn?.ADD && <Route exact path={CREATE_NETWORK} component={CreateNetwork} />}
                {/* CreateDeviceGroupFlow */}
                { permissionsMap.deviceGroup?.ADD && <Route exact path={CREATE_DEVICE_GROUP} component={CreateDeviceGroup} />}
                {/* Device Administration */}
                { permissionsMap.device?.EDIT && <Route exact path={DEVICE_ADMINISTRATION} component={DeviceAdministration} />}
                {/* DeployEdgeFlow */}
                { permissionsMap.edge?.ADD && <Route exact path={DEPLOY_EDGE} component={DeployEdge} />}
                {/* ConnectNetworksToEdgeFlow */}
                { permissionsMap.edge?.EDIT && <Route exact path={CONNECT_NETWORKS_TO_EDGE} component={ConnectNetworksToEdge} />}
                {/* Default onboarding view */}
                <Route path={ONBOARDING} component={Onboarding} />
              </Switch>
            </Route>
          }
          { (permissionsLoading || systemSettingsLoading) && <Route component={Loading} />}
          <Route component={NotImplemented} />
        </Switch>
      </Suspense>
      <LoginExpiryAlert />
    </Layout>
  );
};

export default AppRoutes;

type LoadingProps = {
  overlay?: boolean;
};

function Loading({ overlay }: LoadingProps) {
  return (
    <div
      style={{
        position: overlay ? "absolute" : undefined,
        top: 0,
        left: 0,
        backdropFilter: "blur(3px)",
        zIndex: 1000,

        display: "flex",
        width: overlay ? "80vw" : "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="primary" />
    </div>
  );
}
