import { jsx as o } from "react/jsx-runtime";
import "../../chunks/Chatbot.style.js";
import "@mui/material";
import "react";
import "../atoms/FormControl/FormControl.js";
import "../atoms/FormControlLabel/FormControlLabel.js";
import "../../chunks/index.esm.js";
import "../../chunks/server.browser.js";
import '../../assets/map.css';/* empty css                */import "../atoms/Grid/Grid.js";
import "../atoms/Paper/Paper.js";
import "../atoms/Box/Box.js";
import "../atoms/Radio/Radio.js";
import "../atoms/RadioGroup/RadioGroup.js";
import "../atoms/Typography/Typography.js";
import "../atoms/Stepper/Stepper.js";
import "../atoms/Step/Step.js";
import "../atoms/StepLabel/StepLabel.js";
import "../../chunks/style.module.js";
import "../../chunks/DateTimeSelectorMenu.js";
import "moment-timezone";
import "../../chunks/runtime.js";
import "../../chunks/TableContext.js";
import "../ReactTable/SearchFilter.js";
import "../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../chunks/transform.js";
import "../molecules/NetworkMap/NetworkMapUtils.js";
import c from "../../assets/icons/UserIcon.js";
import a from "../../assets/icons/AgentIcon.js";
import "../organisms/RadarGraph/RadarGraph.js";
import "../organisms/ScoreGauge/ScoreGauge.js";
import "../organisms/SunburstGraph/SunburstGraph.js";
import "../../chunks/ChatbotContext.js";
import "../ReactMap/index.js";
import "../ReactMap/GMapStatic.js";
function Y({ name: n, role: r, avatar: i, color: m, size: p = 24 }) {
  const s = r === "assistant", e = r === "user", t = `${n}`;
  return /* @__PURE__ */ o("span", { className: "avatar", title: t, id: r, children: (() => {
    if (i)
      return i;
    if (s)
      return /* @__PURE__ */ o(a, { title: t, size: p, color: m ?? "#616161" });
    if (e)
      return /* @__PURE__ */ o(c, { title: t, size: p, color: m ?? "#616161" });
  })() });
}
export {
  Y as Avatar
};
