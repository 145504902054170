import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { SNMP } from "~/types/snmp";
import { RootState } from "~/store";
import { networkService } from "~/services/network-service";
import { AaaServerProfile } from "~/types/AAAProfile";
type initState = {
  open: boolean;
  current: SNMP | null;
  data: SNMP[];

  aaa: {
    open: boolean;
    current: AaaServerProfile | null;
    data: AaaServerProfile[];
  }
};

const initialState: initState = {
  open: false,
  current: null,
  data: [],

  aaa: {
    open: false,
    current: null,
    data: [],
  }
};


export const fetchMonitoringProfiles = createAsyncThunk("monitoringProfile/fetchMonitoringProfiles", async () => {
  const data = await networkService.getSNMPData();
  return data;
});

export const fetchAAAProfiles = createAsyncThunk("monitoringProfile/fetchAAAProfiles", async () => {
  const data = await networkService.getAAAServerProfilesData();
  return data;
});


const monitoringProfileSlice = createSlice({
  name: "monitoringProfile",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setValues: (state, action: PayloadAction<SNMP | null>) => {
      state.current = action.payload;
    },
    setAAAValues: (state, action: PayloadAction<AaaServerProfile | null>) => {
      state.aaa.current = action.payload;
    },
    setAAAOpen: (state, action: PayloadAction<boolean>) => {
      state.aaa.open = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMonitoringProfiles.fulfilled, (state, action) => {
      state.data = action.payload;
    })
    .addCase(fetchAAAProfiles.fulfilled, (state, action) => {
      state.aaa.data = action.payload;
    })
  },
});

export const monitoringProfileActions = monitoringProfileSlice.actions;
export default monitoringProfileSlice.reducer;

export const monitoringProfileOpen = (state: RootState) => state.monitoringProfile.open;
export const monitoringProfileCurrent = (state: RootState) => state.monitoringProfile.current;
export const monitoringProfileData = (state: RootState) => state.monitoringProfile.data;


export const aaaProfileOpen = (state: RootState) => state.monitoringProfile.aaa.open;
export const aaaProfileCurrent = (state: RootState) => state.monitoringProfile.aaa.current;
export const aaaProfileData = (state: RootState) => state.monitoringProfile.aaa.data;







