/**
 * Copyright Highway9 Networks Inc.
 */
import { Simulator } from "~/types/simulator";
import APIService from "./APIServices";

const simulatorAPI = new APIService<Simulator>('simulators');

export const simulatorServices = {
  getSimulators : simulatorAPI.getAll,
  createSimulators : simulatorAPI.create,
  deleteSimulator : simulatorAPI.delete
};
