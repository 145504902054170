/**
 Copyright Highway9 Networks Inc.
 */
import { Insight } from "~/types/Insight";
import APIService from "./APIServices";

const InsightAPI = new APIService<Insight>("insights/query");

const insightService = {
   getInsights: ( filter : Partial<Insight>) => InsightAPI.post<Partial<Insight>>('', filter) as unknown as Promise<Insight[]>,
};

export default insightService;
