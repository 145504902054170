/**
 * Copyright (C) Highway 9 Networks Inc. 2024
 */
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VMZGroupService } from "~/services/VMZService";
import { ZoneGroup } from "~/types/zone";
import { RootState } from "..";
import { Types } from "~/constants/types";

type initState = {
    data: ZoneGroup[];
    current: ZoneGroup | null;
    open: boolean;
    selecteedZoneGroupIDs: string[];
    isEdit: boolean;
}

export const defaultVmzGroup: ZoneGroup = {
    name: "",
    description: "",
};

const initialState : initState = {
    data : [],
    current : null,
    open : false,
    selecteedZoneGroupIDs : [Types.all],
    isEdit: false,
};

export const fetchZoneGroups = createAsyncThunk("vmzGroup/fetchZoneGroups", async () => {
    try {
        const vmzGroups = await VMZGroupService.getVMZGroupData();
        return vmzGroups;
    } catch (error) {
        console.log(error);
        throw error;
    }
});

const zoneGroupSlice = createSlice({
    name: "zoneGroup",
    initialState,
    reducers: {
        setCurrent: (state, action: PayloadAction<ZoneGroup | null>) => {
            state.current = action.payload;
        },
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        },
        setSelectedZoneGroupIDs: (state, action: PayloadAction<string[]>) => {
            state.selecteedZoneGroupIDs = action.payload;
        },
        setEdit : (state, action : PayloadAction<boolean>) => {
            state.isEdit = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchZoneGroups.fulfilled, (state, action) => {
            state.data = action.payload;
        });
    },
});

export const zoneGroupActions = zoneGroupSlice.actions;

export default zoneGroupSlice.reducer;

export const zoneGroupData = (state : RootState) => state.zoneGroup.data;
export const zoneGroupCurrent = (state : RootState) => state.zoneGroup.current;
export const zoneGroupOpen= (state : RootState) => state.zoneGroup.open;
export const zoneGroupSelectedIDs = (state : RootState) => state.zoneGroup.selecteedZoneGroupIDs;
export const zoneGroupEdit = (state : RootState) => state.zoneGroup.isEdit;
