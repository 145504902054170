import { jsx as o } from "react/jsx-runtime";
import m from "moment-timezone";
import { c as l } from "../../chunks/createSvgIcon.js";
import { Box as d } from "@mui/material";
import { styles as n } from "./DateTimeSelect.style.js";
const i = l([/* @__PURE__ */ o("path", {
  d: "M17.59 18 19 16.59 14.42 12 19 7.41 17.59 6l-6 6z"
}, "0"), /* @__PURE__ */ o("path", {
  d: "m11 18 1.41-1.41L7.83 12l4.58-4.59L11 6l-6 6z"
}, "1")], "KeyboardDoubleArrowLeftOutlined"), s = l([/* @__PURE__ */ o("path", {
  d: "M6.41 6 5 7.41 9.58 12 5 16.59 6.41 18l6-6z"
}, "0"), /* @__PURE__ */ o("path", {
  d: "m13 6-1.41 1.41L16.17 12l-4.58 4.59L13 18l6-6z"
}, "1")], "KeyboardDoubleArrowRightOutlined"), h = ({ direction: r, distance: e, color: t, onSeek: a }) => /* @__PURE__ */ o(
  d,
  {
    sx: n.arrowIcon,
    title: `Seek ${m.duration(e, "seconds").humanize()} ${r === -1 ? "backward" : "forward"}`,
    onClick: () => a(r, e),
    children: r === -1 ? /* @__PURE__ */ o(i, { sx: { color: t } }) : /* @__PURE__ */ o(s, { sx: { color: t } })
  }
);
export {
  h as Seek
};
