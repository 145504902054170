/** 
 * Copyright Highway9 Networks Inc. 
 */ 
import * as React from "react";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { loadingActions } from "~/store/context/loadingSlice";
import { Box, IconButton } from "@h9/ui-lib";
import { Icons } from "../GraphicalResources";
import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const severityConfig = {
  success: {
    bgcolor: "#E0FFF0",
    color: "#277A52",
    border: "1px solid #4B857680",
    iconBg: "#277A52",
    icon: <Icons.CheckCircleIcon sx={{ fontSize: '24px' }} />,
  },
  error: {
    bgcolor: "#FFE0E4",
    color: "#D71630",
    border: "1px solid #D7163080",
    icon: <Icons.ReportIcon sx={{ fontSize: '24px' }} />,
  },
  warning: {
    bgcolor: "#FFEFE0",
    color: "#FF7A00",
    border: "1px solid #FF7A0080",
    icon: <Icons.WarningIcon sx={{ fontSize: '24px' }} />,
  },
  info: {
    bgcolor: "#E2F2FF",
    color: "#1E88E5",
    border: "1px solid #1E88E580",
    icon: <Icons.error sx={{ fontSize: '24px' }} />,
  },
};

const styles = {
  alertContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    padding: "12px 16px",
    gap: "12px",
  },
  iconButton: {
    padding: "0px",
    fontSize: "16px",
  },
  message: {
    fontSize: "13px",
    fontWeight: 500,
  }
} satisfies Record<string, SxProps<Theme>>;

const CustomAlert = React.forwardRef<HTMLDivElement, { message: React.ReactNode; severity: keyof typeof severityConfig; onClose: () => void }>(
  function CustomAlert({ message, severity, onClose }, ref) {
    const { bgcolor, color, border, icon } = severityConfig[severity];
    return (
      <Box
        ref={ref}
        sx={{ ...styles.alertContainer, bgcolor, color, border }}
      >
        {icon}
        <Box sx={{ ...styles.message, color }}>{message}</Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ ...styles.iconButton, color }}
        >
          <Icons.closeIcon />
        </IconButton>
      </Box>
    );
  }
);

type Props = {
  action?: React.ReactNode;
};

const defaultPosition: SnackbarOrigin = {
  vertical: "top",
  horizontal: "center",
};

const SnackbarX = (props: Props) => {
  const dispatch = useAppDispatch();
  const { open, message, severity, hideDuration } = useAppSelector((state) => state.loading.snackBar);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(loadingActions.setSnackBar({ open: false, message: "", severity }));
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={hideDuration}
      onClose={handleClose}
      action={props.action}
      anchorOrigin={defaultPosition}
    >
      {severity in severityConfig ? (
        <CustomAlert message={message} severity={severity as keyof typeof severityConfig} onClose={handleClose} />
      ) : (
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      )}
    </Snackbar>
  );
};

export default SnackbarX;
