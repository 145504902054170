// @Copyright 2023 - present , Highway 9 networks

import { WebSocketMessage } from "~/types/WebsocketMessage";

// need to batch the similar events of similar object type as single call happening in 1 second
// create an array holding the events and flush it every 1 second

// the array will not hold the duplicate events and it will forward those events to process and then clear array for every 1 sec cycle.
const exceptions = new Set<string>([]);

type T = WebSocketMessage;

/**
 * A class that batches events and processes them at a specified interval.
 * 
 * @template T - The type of data associated with the events.
 */
class EventBatcher {
  private eventMap = new Map<string, T[]>();
  private counter: number;
  private intervalId: NodeJS.Timeout;
  private processEvent: (event: string, data: T[]) => void;
  private flushing = false;

  /**
   * Creates an instance of EventBatcher.
   * 
   * @param processEvent - A callback function to process the batched events.
   * @param flushInterval - The interval (in seconds) at which events are flushed and processed. Default is 2 seconds.
   */
  constructor(processEvent: (event: string, data: T[]) => void, flushInterval = 2) {
    this.counter = 0;
    this.processEvent = processEvent;
    this.intervalId = setInterval(() => this.flushEvents(), flushInterval * 1000);
  }

  /**
   * Adds an event to the batch.
   * 
   * @param event - The name of the event.
   * @param data - The data associated with the event.
   */
  public addEvent(event: string, data: T) {
    const key = event;

    // Immediately process events in the exceptions list
    if(exceptions.has(key)){
      this.processEvent(key, [data]);
      return
    }

    if (!this.eventMap.has(key)) {
      this.eventMap.set(key, []);
    }
    this.eventMap.get(key)!.push(data);
    this.counter++;
  }

  /**
   * Flushes all batched events and processes them.
   */
  private async flushEvents() {
    const count = this.counter - this.eventMap.size;
    if (this.counter === 0 || this.flushing) {
      return
    }
    this.flushing = true;
    console.log(`Flushing ${count} events`);
    this.counter = 0;

    const promises : Promise<void>[] = [];

    this.eventMap.forEach((data, event) => {
      promises.push(this.handleProcessEvent(event, data));
    });

    try {
      await Promise.all(promises);
    } catch (error) {
      console.error("Error processing events", error);
    } finally {
    this.eventMap.clear();
    this.flushing = false;
    }
  }

  /**
   * Returns the keys of all batched events.
   * @returns An iterator of event keys.
   */
  public getEvents() {
    return this.eventMap.keys();
  }

  public stop() {
    clearInterval(this.intervalId);
  }

  /**
   * Handles the processing of a single event.
   * 
   * @param event - The name of the event.
   * @param data - The data associated with the event.
   */
  private async handleProcessEvent(event: string, data: T[]) {
    try {
    await this.processEvent(event, data);
    } catch (error) {
      console.error("Error processing event", event, error);
    }
  }
}

export default EventBatcher;
