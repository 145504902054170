/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import markerA from "./markerA.svg";
import markerB from "./markerB.svg";
import current from "./current.svg";
import pointer from "./pointer.svg";
import pointerGreen from "./pointer-green.svg";
import pointerGrey from "./pointer-grey.svg";
import green from "./green-plain.svg";
import pin from "./pin.svg";
import celltower from "./celltower.svg";
import radioIndoor from "./Radio-Indoor-Icon.svg";
import connectedDevices from "./connected-devices.svg";
import locationMapMarker from "./location-map-marker.svg";
import networkUtilization from "./network-utilization.svg";
import loginPageLogo from "./login_page_logo.svg";
import highway9Logo from "./highway9-logo.svg"
import dataBarAdd from "./data-bar-vertical-add.svg";
import networkMapBackgroundGradient from "./network-map-background-gradient.svg";
import map from "./map.svg";
import security from "./security.svg";
import teams from "./microsoft-teams.svg";

export const asset = {
  markerA,
  markerB,
  map,
  security,
  currentLocation: current,
  pointer,
  pin,
  radioGreen: pointerGreen,
  radioGrey: pointerGrey,
  green,
  celltower,
  radioIndoor,
  connectedDevices,
  locationMapMarker,
  networkUtilization,
  loginPageLogo,
  highway9Logo,
  dataBarAdd,
  networkMapBackgroundGradient,
  teams,
};
