/** 
 Copyright Highway9 Networks Inc. 
 */ 
import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { authenticationService } from "../services";

export const AuthRouter = ({ component: Component, ...rest } : RouteProps) => {
  const url = window.location.href;
  const pathname = window.location.pathname;
  if(pathname !== '/'){
    sessionStorage.setItem("url", url);
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        authenticationService.validateCurrentUser();
        const currentUser = authenticationService.currentUserValue;

        if (!currentUser) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }

        if(!Component){
          return <></>
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};
