/** 
 Copyright Highway9 Networks Inc. 
 */ 
import { SupportChannel } from "~/types/SupportChannel";
import APIService from "./APIServices";
import { authenticationService } from "./authentication-service";

const supportChannelAPI = new APIService<SupportChannel>("supportChannel");

export const supportChannelServices = {
  getSupportChannel : supportChannelAPI.getAll,
  setSupportChannelState,
};


function setSupportChannelState(requestBody : any, method : "PUT" | "POST") {
  const id = method === "PUT" ? `/${requestBody.id}` : "";
  const requestOptions = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue?.token,
    },
    body: JSON.stringify(requestBody),
  };

  return fetch(`/api/v1/orch/supportChannel${id}`, requestOptions);
}
