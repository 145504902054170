/** 
 Copyright Highway9 Networks Inc. 
 */ 
export const Types = {
  all: "all",
  // edge
  edge: "edge",
  edgePool: "edgePool",
  // radio
  radio: "radio",
  radioGroup: "radioGroup",
  site: "radioSite",
  
  // subscriber
  deviceGroup: "deviceGroup",
  device: "device",
  endUserDevice: "eud",

  dnn: "dnn",

  // admin
  controller: "controller",
  monitoringSystem: "monitoringSystem",
  aaaServerProfile: "aaaServerProfile",
  syncProfile: "synchronizationProfile",
  supportChannel: "supportChannel",
  mobilityProfile: "mobilityProfile",

  // zone
  virtualMobilityZone: "zone",
  application: "application",
  qosProfile: "qosProfile",
  zoneGroup : "zoneGroup",

  // simulation
  simulatorAppliance: "simulator",
  radioSimulator: "radio",
  ueSimulator: "diagnosticsUe",

  // admin
  network: "network", // ns
  settings: "systemSettings",

  // others
  alarm: "alarm", // /alarm/query
  operation: "operation", // /operation/query
  log: "log", // auditLogs/query

  hardware: "vmcHardware",
  vmzGroup: "zoneGroup",
} as const;

export const UngroupedRadioID = "radioGroup-ungrouped";
