/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { mobilityProfileAttributeServices, mobilityService } from '../../services/mobility-service';
import { MobilityProfile } from "../../types/mobilityProfile";
import { MobilityProfileAttributes } from "~/types/mobilityProfileAttributes";
import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../hooks";

type initState = {
  open: boolean;
  current: MobilityProfile | null;
  attributesCurrent: MobilityProfileAttributes | null;
  data: MobilityProfile[];
  attributesData: MobilityProfileAttributes[]
};

const initialState: initState = {
  open: false,
  current: null,
  attributesCurrent: null,
  data: [],
  attributesData: []
};

export const useMobilityProfileAttributes = () => {
  const attributesData = useAppSelector(mobilityProfileAttributesData);
  
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  async function getAttributesData() {
    try {
      await dispatch(fetchMobilityProfileAttributes());
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return {
    attributesData,
    isLoading,
    setIsLoading,
    getAttributesData,
    dispatch,
  };
};

export const useMobilityProfiles = () => {
  const MobilityData = useAppSelector(mobilityProfileData);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  async function getMobilityProfilesData() {
    try {
      await dispatch(fetchMobilityProfiles());
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return {
    MobilityData,
    isLoading,
    setIsLoading,
    getMobilityProfilesData,
    dispatch,
  };
};


export const fetchMobilityProfiles = createAsyncThunk("mobilityProfile/fetchMobilityProfiles", async () => {
  const data = await mobilityService.getMobilityProfiles();
  return data;
});

export const fetchMobilityProfileAttributes = createAsyncThunk("attribute/fetchMobilityProfileAttributes", async () => {
  const data = await mobilityProfileAttributeServices.getMobilityProfilesAttributes()

  return data;
});

const mobilityProfileSlice = createSlice({
  name: "mobilityProfile",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setValues: (state, action: PayloadAction<MobilityProfile>) => {
      state.current = action.payload;
    },
    updateData: (state, action: PayloadAction<MobilityProfile>) => {
      const index = state.data.findIndex((item) => item.id === action.payload.id);
      state.data[index] = action.payload;
    },
    delete: (state, action: PayloadAction<string>) => {
      const index = state.data.findIndex((item) => item.id === action.payload);
      state.data = state.data.filter((_, i) => i !== index);
    },
    setData: (state, action: PayloadAction<MobilityProfile[]>) => {
      state.data = action.payload;

      const current = action.payload.find((item) => item.id === state.current?.id);
      if (current) {
        state.current = current;
      }
    },
    setAttributesData: (state, action: PayloadAction<MobilityProfileAttributes[]>) => {
      state.attributesData = action.payload;
    },
    
    addData: (state, action: PayloadAction<MobilityProfile>) => {
      state.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMobilityProfiles.fulfilled, (state, action) => {
      state.data = action.payload;

      // Sync current with latest data
      if (state.current && !state.open) {
        const current = action.payload.find((item) => item.id === state.current?.id);
        if (current) {
          state.current = current;
        }
      }
    });
    builder.addCase(fetchMobilityProfileAttributes.fulfilled, (state, action) => {
      state.attributesData = action.payload;
  
    });


  },
});

export const mobilityProfileActions = mobilityProfileSlice.actions;
export default mobilityProfileSlice.reducer;
export const mobilityProfileState = (state: RootState) => state.mobilityProfile.current;
export const mobilityProfileData = (state: RootState) => state.mobilityProfile.data;
export const mobilityProfileOpen = (state: RootState) => state.mobilityProfile.open;
export const mobilityProfileAttributesData = (state: RootState) => state.mobilityProfile.attributesData;